// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Txt$Thin = require("../../text/Txt.bs.js");
var Date$Util = require("util/src/Date.bs.js");
var Js_string = require("rescript/lib/js/js_string.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Merge$Util = require("util/src/Merge.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var SysCtx$Thin = require("../../utils/SysCtx.bs.js");
var Menu = require("@mui/joy/Menu").default;
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var Table = require("@mui/joy/Table").default;
var Tooltip = require("@mui/joy/Tooltip").default;
var Dropdown = require("@mui/joy/Dropdown").default;
var MenuItem = require("@mui/joy/MenuItem").default;
var JsxRuntime = require("react/jsx-runtime");
var AddPassDrawer$Thin = require("./add-passenger/AddPassDrawer.bs.js");
var CustomScalars$Thin = require("../../utils/CustomScalars.bs.js");
var IconButton = require("@mui/joy/IconButton").default;
var MenuButton = require("@mui/joy/MenuButton").default;
var Typography = require("@mui/joy/Typography").default;
var AddBerthSubmit$Thin = require("./mutations/AddBerthSubmit.bs.js");
var BDContentBlock$Thin = require("./molecules/BDContentBlock.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var Add = require("@mui/icons-material/Add").default;
var ConfirmActionDialog$Thin = require("../common/molecules/ConfirmActionDialog.bs.js");
var ListItemDecorator = require("@mui/joy/ListItemDecorator").default;
var Warning = require("@mui/icons-material/Warning").default;
var ModeEdit = require("@mui/icons-material/ModeEdit").default;
var MoreHoriz = require("@mui/icons-material/MoreHoriz").default;
var BedOutlined = require("@mui/icons-material/BedOutlined").default;
var ErrorOutline = require("@mui/icons-material/ErrorOutline").default;
var InfoOutlined = require("@mui/icons-material/InfoOutlined").default;
var DoNotDisturbAlt = require("@mui/icons-material/DoNotDisturbAlt").default;
var BDPassengerList_detail_graphql$Thin = require("../../__generated__/BDPassengerList_detail_graphql.bs.js");
var ModeEditOutlined = require("@mui/icons-material/ModeEditOutlined").default;
var CheckCircleOutline = require("@mui/icons-material/CheckCircleOutline").default;
var BDPassengerList_passenger_graphql$Thin = require("../../__generated__/BDPassengerList_passenger_graphql.bs.js");
var ContentCopyOutlined = require("@mui/icons-material/ContentCopyOutlined").default;
var PersonRemoveOutlined = require("@mui/icons-material/PersonRemoveOutlined").default;
var ScheduleSendOutlined = require("@mui/icons-material/ScheduleSendOutlined").default;
var SendAndArchiveOutlined = require("@mui/icons-material/SendAndArchiveOutlined").default;
var BDPassengerList_addberthdetail_graphql$Thin = require("../../__generated__/BDPassengerList_addberthdetail_graphql.bs.js");
var PersonAddDisabledOutlined = require("@mui/icons-material/PersonAddDisabledOutlined").default;

var convertFragment = BDPassengerList_detail_graphql$Thin.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(BDPassengerList_detail_graphql$Thin.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BDPassengerList_detail_graphql$Thin.node, convertFragment);
}

var DetailFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

var convertFragment$1 = BDPassengerList_passenger_graphql$Thin.Internal.convertFragment;

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(BDPassengerList_passenger_graphql$Thin.node, convertFragment$1, fRef);
}

function useOpt$1(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BDPassengerList_passenger_graphql$Thin.node, convertFragment$1);
}

var PassengerFragment_paxDataStatus_decode = BDPassengerList_passenger_graphql$Thin.Utils.paxDataStatus_decode;

var PassengerFragment_paxDataStatus_fromString = BDPassengerList_passenger_graphql$Thin.Utils.paxDataStatus_fromString;

var PassengerFragment = {
  paxDataStatus_decode: PassengerFragment_paxDataStatus_decode,
  paxDataStatus_fromString: PassengerFragment_paxDataStatus_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$1,
  use: use$1,
  useOpt: useOpt$1
};

function BDPassengerList$PassengerMenu(props) {
  var onReplacePax = props.onReplacePax;
  var onRemovePax = props.onRemovePax;
  var onEditDetails = props.onEditDetails;
  var isActive = props.isActive;
  var isBc = props.isBc;
  var paxEmail = props.paxEmail;
  var currentUserEmail = props.currentUserEmail;
  var onEditDetails$1 = onEditDetails !== undefined ? onEditDetails : (function (param) {
        
      });
  var onRemovePax$1 = onRemovePax !== undefined ? onRemovePax : (function (param) {
        
      });
  var onReplacePax$1 = onReplacePax !== undefined ? onReplacePax : (function (param) {
        
      });
  var isCurrentUser = currentUserEmail !== undefined && paxEmail !== undefined ? currentUserEmail === paxEmail : false;
  var selfOptions = [
    JsxRuntime.jsxs(MenuItem, {
          children: [
            JsxRuntime.jsx(ListItemDecorator, {
                  children: Caml_option.some(JsxRuntime.jsx(ModeEdit, {}))
                }),
            Txt$Thin.BookingDetails.editDetails.value
          ],
          disabled: true,
          onClick: onEditDetails$1
        }, Txt$Thin.BookingDetails.editDetails.value),
    JsxRuntime.jsxs(MenuItem, {
          children: [
            JsxRuntime.jsx(ListItemDecorator, {
                  children: Caml_option.some(JsxRuntime.jsx(DoNotDisturbAlt, {
                            color: "danger"
                          }))
                }),
            JsxRuntime.jsx(Typography, {
                  color: "danger",
                  level: "inherit",
                  children: Caml_option.some(Txt$Thin.BookingDetails.removeMyself.value)
                })
          ],
          disabled: true,
          onClick: onRemovePax$1
        }, Txt$Thin.BookingDetails.editDetails.value)
  ];
  var bcOptions = [
    JsxRuntime.jsxs(MenuItem, {
          children: [
            JsxRuntime.jsx(ListItemDecorator, {
                  children: Caml_option.some(JsxRuntime.jsx(PersonRemoveOutlined, {}))
                }),
            Txt$Thin.BookingDetails.replacePassenger.value
          ],
          disabled: true,
          onClick: onReplacePax$1
        }, Txt$Thin.BookingDetails.replacePassenger.value),
    JsxRuntime.jsxs(MenuItem, {
          children: [
            JsxRuntime.jsx(ListItemDecorator, {
                  children: Caml_option.some(JsxRuntime.jsx(BedOutlined, {}))
                }),
            Txt$Thin.BookingDetails.dropBerth.value
          ],
          disabled: true,
          onClick: onRemovePax$1
        }, Txt$Thin.BookingDetails.dropBerth.value)
  ];
  var invited = JsxRuntime.jsxs(Stack, {
        direction: "row",
        alignItems: "center",
        spacing: 1,
        children: [
          JsxRuntime.jsx(Tooltip, {
                children: JsxRuntime.jsx(IconButton, {
                      "aria-label": Txt$Thin.BookingDetails.copyInvitationLink.value,
                      disabled: true,
                      children: Caml_option.some(JsxRuntime.jsx(ContentCopyOutlined, {}))
                    }),
                arrow: true,
                size: "sm",
                title: Txt$Thin.BookingDetails.copyInvitationLink.value
              }),
          JsxRuntime.jsx(Tooltip, {
                children: JsxRuntime.jsx(IconButton, {
                      "aria-label": Txt$Thin.BookingDetails.resendInvitation.value,
                      disabled: true,
                      children: Caml_option.some(JsxRuntime.jsx(ScheduleSendOutlined, {}))
                    }),
                arrow: true,
                size: "sm",
                title: Txt$Thin.BookingDetails.resendInvitation.value
              }),
          JsxRuntime.jsx(Tooltip, {
                children: JsxRuntime.jsx(IconButton, {
                      "aria-label": Txt$Thin.BookingDetails.revokeInvitation.value,
                      disabled: true,
                      children: Caml_option.some(JsxRuntime.jsx(PersonAddDisabledOutlined, {
                                color: "danger"
                              }))
                    }),
                arrow: true,
                size: "sm",
                title: Txt$Thin.BookingDetails.revokeInvitation.value
              })
        ]
      });
  var options = isCurrentUser ? (
      isBc ? Belt_Array.concat(Belt_Array.slice(selfOptions, 0, 1), bcOptions) : selfOptions
    ) : (
      isBc ? bcOptions : []
    );
  var match = options.length === 0;
  if (props.status === "INVITED" && isBc) {
    if (isActive) {
      return invited;
    } else {
      return JsxRuntime.jsx(JsxRuntime.Fragment, {});
    }
  }
  if (match || !isActive) {
    return JsxRuntime.jsx(JsxRuntime.Fragment, {});
  } else {
    return JsxRuntime.jsxs(Dropdown, {
                children: [
                  JsxRuntime.jsx(MenuButton, {
                        children: Caml_option.some(JsxRuntime.jsx(MoreHoriz, {})),
                        size: "md",
                        slotProps: {
                          root: {
                            color: "neutral"
                          }
                        },
                        slots: {
                          root: IconButton
                        }
                      }),
                  JsxRuntime.jsx(Menu, {
                        children: Caml_option.some(options)
                      })
                ]
              });
  }
}

var PassengerMenu = {
  make: BDPassengerList$PassengerMenu
};

function BDPassengerList$BDPassengerTableRow(props) {
  var query = RescriptRelay_Fragment.useFragment(BDPassengerList_passenger_graphql$Thin.node, convertFragment$1, props.fragmentRefs);
  var match = React.useState(function () {
        return false;
      });
  var setNameCopied = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setEmailCopied = match$1[1];
  var date = query.docBirthdate;
  var birthdate = date !== undefined ? Date$Util.format(Caml_option.valFromOption(date), SysCtx$Thin.vars.dateFormat) : undefined;
  var name = query.docFirstName;
  var docFName = name !== undefined ? CustomScalars$Thin.NonemptyString.serialize(Caml_option.valFromOption(name)) : undefined;
  var name$1 = query.docLastName;
  var docLName = name$1 !== undefined ? CustomScalars$Thin.NonemptyString.serialize(Caml_option.valFromOption(name$1)) : undefined;
  var name$2 = query.preferredFirstName;
  var preferredFName = name$2 !== undefined ? CustomScalars$Thin.NonemptyString.serialize(Caml_option.valFromOption(name$2)) : undefined;
  var name$3 = query.preferredLastName;
  var preferredLName = name$3 !== undefined ? CustomScalars$Thin.NonemptyString.serialize(Caml_option.valFromOption(name$3)) : undefined;
  var name$4 = query.preferredFullName;
  var preferredFullName = name$4 !== undefined ? name$4 : undefined;
  var email = query.email;
  var email$1 = email !== undefined ? CustomScalars$Thin.Email.serialize(Caml_option.valFromOption(email)) : undefined;
  var match$2 = query.paxDataStatus;
  var match$3 = match$2 === "INVITED" ? [
      Txt$Thin.BookingDetails.plInvited.value,
      "secondary",
      JsxRuntime.jsx(SendAndArchiveOutlined, {
            color: "secondary"
          })
    ] : (
      match$2 === "CONFIRMED" ? [
          Txt$Thin.BookingDetails.plConfirmed.value,
          "success",
          JsxRuntime.jsx(CheckCircleOutline, {
                color: "success"
              })
        ] : (
          match$2 === "INCOMPLETE" ? [
              Txt$Thin.BookingDetails.plIncomplete.value,
              "neutral",
              JsxRuntime.jsx(InfoOutlined, {
                    color: "info"
                  })
            ] : [
              Txt$Thin.BookingDetails.plUnknown.value,
              "danger",
              JsxRuntime.jsx(ErrorOutline, {
                    color: "danger"
                  })
            ]
        )
    );
  var match$4;
  if (docFName !== undefined && docLName !== undefined && preferredFName !== undefined && preferredLName !== undefined && (docFName !== preferredFName || docLName !== preferredLName)) {
    var legalName = docFName + " " + docLName;
    match$4 = [
      true,
      legalName
    ];
  } else {
    match$4 = [
      false,
      ""
    ];
  }
  var copyToClipboard = async function (s, f) {
    try {
      await window.navigator.clipboard.writeText(s);
      var setCopied = f === "NAME" ? setNameCopied : setEmailCopied;
      Curry._1(setCopied, (function (param) {
              return true;
            }));
      setTimeout((function (param) {
              Curry._1(setCopied, (function (param) {
                      return false;
                    }));
            }), 1500);
      return ;
    }
    catch (exn){
      console.log(Txt$Thin.Clipboard.copyError.value);
      return ;
    }
  };
  return JsxRuntime.jsxs("tr", {
              children: [
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsxs(Stack, {
                            direction: "row",
                            alignItems: "center",
                            spacing: 1,
                            children: [
                              match$3[2],
                              JsxRuntime.jsx(Typography, {
                                    color: match$3[1],
                                    level: "inherit",
                                    fontWeight: "inherit",
                                    children: Caml_option.some(match$3[0])
                                  })
                            ]
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: preferredFullName !== undefined ? JsxRuntime.jsxs(Stack, {
                              direction: "row",
                              sx: {
                                display: "flex",
                                width: "100%"
                              },
                              children: [
                                JsxRuntime.jsx(Tooltip, {
                                      children: JsxRuntime.jsx(Typography, {
                                            level: "inherit",
                                            noWrap: true,
                                            sx: {
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              "&:hover": {
                                                cursor: "pointer"
                                              }
                                            },
                                            textColor: "inherit",
                                            fontWeight: "inherit",
                                            children: Caml_option.some(preferredFullName),
                                            onClick: (function (param) {
                                                return copyToClipboard(preferredFullName, "NAME");
                                              })
                                          }),
                                      arrow: true,
                                      size: "sm",
                                      title: match[0] ? Txt$Thin.Clipboard.copied.value : preferredFullName
                                    }),
                                match$4[0] ? JsxRuntime.jsx(Stack, {
                                        direction: "row",
                                        sx: {
                                          flex: 1
                                        },
                                        children: Caml_option.some(JsxRuntime.jsx(Tooltip, {
                                                  children: JsxRuntime.jsx(Typography, {
                                                        level: "body-md",
                                                        children: Caml_option.some(JsxRuntime.jsx("sup", {
                                                                  children: JsxRuntime.jsx(InfoOutlined, {
                                                                        color: "info",
                                                                        fontSize: "inherit"
                                                                      })
                                                                }))
                                                      }),
                                                  arrow: true,
                                                  placement: "top",
                                                  size: "sm",
                                                  sx: {
                                                    position: "relative",
                                                    left: 2,
                                                    justifySelf: "flex-start"
                                                  },
                                                  title: "Traveling as " + match$4[1] + ""
                                                }))
                                      }) : null
                              ]
                            }) : JsxRuntime.jsx(Typography, {
                              level: "inherit",
                              textColor: "primary.solidDisabledColor",
                              fontWeight: "inherit",
                              children: Caml_option.some(Txt$Thin.BookingDetails.plName.value)
                            })
                    }),
                JsxRuntime.jsx("td", {
                      children: birthdate !== undefined ? JsxRuntime.jsx(Typography, {
                              level: "inherit",
                              noWrap: true,
                              sx: {
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                maxWidth: "fit-content"
                              },
                              textColor: "inherit",
                              fontWeight: "inherit",
                              children: Caml_option.some(birthdate)
                            }) : JsxRuntime.jsx(Typography, {
                              level: "inherit",
                              textColor: "primary.solidDisabledColor",
                              fontWeight: "inherit",
                              children: Caml_option.some(Txt$Thin.BookingDetails.plBirthdate.value)
                            })
                    }),
                JsxRuntime.jsx("td", {
                      children: email$1 !== undefined ? JsxRuntime.jsx(Tooltip, {
                              children: JsxRuntime.jsx(Typography, {
                                    level: "inherit",
                                    noWrap: true,
                                    sx: {
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      maxWidth: "fit-content",
                                      "&:hover": {
                                        cursor: "pointer"
                                      }
                                    },
                                    textColor: "inherit",
                                    fontWeight: "inherit",
                                    children: Caml_option.some(email$1),
                                    onClick: (function (param) {
                                        return copyToClipboard(email$1, "EMAIL");
                                      })
                                  }),
                              arrow: true,
                              size: "sm",
                              sx: {
                                width: "fit-content"
                              },
                              title: match$1[0] ? Txt$Thin.Clipboard.copied.value : email$1
                            }) : JsxRuntime.jsx(Typography, {
                              level: "inherit",
                              textColor: "primary.solidDisabledColor",
                              fontWeight: "inherit",
                              children: Caml_option.some(Txt$Thin.BookingDetails.plEmail.value)
                            })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Stack, {
                            direction: "row",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            spacing: 1,
                            children: Caml_option.some(JsxRuntime.jsx(BDPassengerList$PassengerMenu, {
                                      status: query.paxDataStatus,
                                      currentUserEmail: props.currentUserEmail,
                                      paxEmail: email$1,
                                      isBc: props.isBc,
                                      isActive: props.isActive
                                    }))
                          })
                    })
              ]
            });
}

var BDPassengerTableRow = {
  make: BDPassengerList$BDPassengerTableRow
};

function BDPassengerList$BDTBA(props) {
  var match = React.useState(function () {
        return false;
      });
  var setPaxDetailsOpen = match[1];
  return JsxRuntime.jsxs("tr", {
              children: [
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsxs(Stack, {
                            direction: "row",
                            alignItems: "center",
                            spacing: 1,
                            children: [
                              JsxRuntime.jsx(Warning, {
                                    color: "warningSecondary"
                                  }),
                              JsxRuntime.jsx(Typography, {
                                    color: "warningSecondary",
                                    level: "inherit",
                                    fontWeight: "inherit",
                                    children: Caml_option.some(Txt$Thin.BookingDetails.plTBA.value)
                                  })
                            ]
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Typography, {
                            level: "inherit",
                            textColor: "primary.solidDisabledColor",
                            fontWeight: "inherit",
                            children: Caml_option.some(Txt$Thin.BookingDetails.plName.value)
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Typography, {
                            level: "inherit",
                            textColor: "primary.solidDisabledColor",
                            fontWeight: "inherit",
                            children: Caml_option.some(Txt$Thin.BookingDetails.plBirthdate.value)
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Typography, {
                            level: "inherit",
                            textColor: "primary.solidDisabledColor",
                            fontWeight: "inherit",
                            children: Caml_option.some(Txt$Thin.BookingDetails.plEmail.value)
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: props.isActive && props.isBc ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                              children: [
                                JsxRuntime.jsx(Stack, {
                                      direction: "row",
                                      justifyContent: "flex-end",
                                      alignItems: "center",
                                      spacing: 1,
                                      children: Caml_option.some(JsxRuntime.jsx(IconButton, {
                                                onClick: (function (param) {
                                                    Curry._1(setPaxDetailsOpen, (function (param) {
                                                            return true;
                                                          }));
                                                  }),
                                                children: Caml_option.some(JsxRuntime.jsx(ModeEditOutlined, {}))
                                              }))
                                    }),
                                JsxRuntime.jsx(AddPassDrawer$Thin.make, {
                                      open_: match[0],
                                      onClose: (function (param) {
                                          Curry._1(setPaxDetailsOpen, (function (param) {
                                                  return false;
                                                }));
                                        }),
                                      areConnectedAccts: props.areConnectedAccts,
                                      queryFrag: props.queryFrag
                                    })
                              ]
                            }) : null
                    })
              ]
            });
}

var BDTBA = {
  make: BDPassengerList$BDTBA
};

var convertFragment$2 = BDPassengerList_addberthdetail_graphql$Thin.Internal.convertFragment;

function use$2(fRef) {
  return RescriptRelay_Fragment.useFragment(BDPassengerList_addberthdetail_graphql$Thin.node, convertFragment$2, fRef);
}

function useOpt$2(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BDPassengerList_addberthdetail_graphql$Thin.node, convertFragment$2);
}

var AddBerthFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$2,
  use: use$2,
  useOpt: useOpt$2
};

function BDPassengerList$AddBerth(props) {
  var data = RescriptRelay_Fragment.useFragment(BDPassengerList_addberthdetail_graphql$Thin.node, convertFragment$2, props.fragmentRefs);
  var match = React.useState(function () {
        return false;
      });
  var setModalOpen = match[1];
  var match$1 = AddBerthSubmit$Thin.useMutation(data.bookingId, data.bookingVersionNumber, (function (param) {
          Curry._1(setModalOpen, (function (param) {
                  return false;
                }));
        }), props.refetch);
  var maxedOut = data.numBerths >= data.salesMaxBerth;
  var bookingContext = {
    bookingId: CustomScalars$Thin.BookingId.serialize(data.bookingId)
  };
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(Tooltip, {
                      children: JsxRuntime.jsxs(Stack, {
                            direction: "row",
                            alignItems: "center",
                            sx: {
                              "&:hover": {
                                cursor: "pointer"
                              }
                            },
                            onClick: (function (param) {
                                if (maxedOut) {
                                  return ;
                                } else {
                                  return Curry._1(setModalOpen, (function (param) {
                                                return true;
                                              }));
                                }
                              }),
                            children: [
                              JsxRuntime.jsx(Typography, {
                                    size: "sm",
                                    sx: {
                                      color: maxedOut ? "neutral.400" : "primary.500"
                                    },
                                    children: Caml_option.some(Txt$Thin.BookingDetails.addBerth.value)
                                  }),
                              JsxRuntime.jsx(Add, {
                                    fontSize: "lg",
                                    sx: {
                                      color: maxedOut ? "neutral.400" : "primary.500"
                                    }
                                  })
                            ]
                          }),
                      arrow: true,
                      disableHoverListener: !maxedOut,
                      size: "sm",
                      sx: {
                        maxWidth: "12rem"
                      },
                      title: Txt$Thin.BookingDetails.maxOccReached.value
                    }),
                maxedOut ? null : JsxRuntime.jsx(ConfirmActionDialog$Thin.make, {
                        open_: match[0],
                        onClose: (function (param) {
                            Curry._1(setModalOpen, (function (param) {
                                    return false;
                                  }));
                          }),
                        action: Txt$Thin.BookingDetails.addBerth.value,
                        actionSlug: Js_string.split(Txt$Thin.BookingDetails.addBerth.value.toLowerCase(), " ").join("-"),
                        description: Merge$Util.render(Txt$Thin.BookingDetails.addBerthConfirmText, bookingContext),
                        onConfirm: match$1.onSubmit,
                        confirmText: Txt$Thin.BookingDetails.confirmAddBerth.value,
                        onCancel: (function (param) {
                            Curry._1(setModalOpen, (function (param) {
                                    return false;
                                  }));
                          }),
                        cancelText: Txt$Thin.BookingDetails.cancelAddBerth.value,
                        error: match$1.error,
                        loading: match$1.isMutating,
                        disabled: maxedOut
                      })
              ]
            });
}

var AddBerth = {
  make: BDPassengerList$AddBerth
};

function BDPassengerList(props) {
  var areConnectedAccts = props.areConnectedAccts;
  var queryFrag = props.queryFrag;
  var isActive = props.isActive;
  var isBc = props.isBc;
  var currentUserEmail = props.currentUserEmail;
  var fragmentRefs = props.fragmentRefs;
  var query = RescriptRelay_Fragment.useFragment(BDPassengerList_detail_graphql$Thin.node, convertFragment, fragmentRefs);
  var passengers = query.passengers.nodes;
  var loop = function (_i, _acc) {
    while(true) {
      var acc = _acc;
      var i = _i;
      if (i >= query.numBerths) {
        return acc;
      }
      _acc = Belt_Array.concat(acc, [JsxRuntime.jsx(BDPassengerList$BDTBA, {
                  isActive: isActive,
                  isBc: isBc,
                  queryFrag: queryFrag,
                  areConnectedAccts: areConnectedAccts
                }, String(i))]);
      _i = i + 1 | 0;
      continue ;
    };
  };
  var tbas = loop(passengers.length, []);
  var titleComponent = isActive && isBc ? JsxRuntime.jsx(BDPassengerList$AddBerth, {
          fragmentRefs: fragmentRefs,
          refetch: props.refetch
        }) : null;
  return JsxRuntime.jsx(BDContentBlock$Thin.make, {
              title: "" + Txt$Thin.BookingDetails.passengerListHeading.value + " (" + String(query.numBerths) + ")",
              titleComponent: Caml_option.some(titleComponent),
              children: JsxRuntime.jsx(Sheet, {
                    sx: {
                      overflow: "auto"
                    },
                    children: Caml_option.some(JsxRuntime.jsxs(Table, {
                              sx: {
                                width: {
                                  xs: "100%"
                                }
                              },
                              children: [
                                JsxRuntime.jsx("thead", {
                                      children: JsxRuntime.jsxs("tr", {
                                            children: [
                                              JsxRuntime.jsx("th", {
                                                    children: JsxRuntime.jsx(Typography, {
                                                          level: "body-sm",
                                                          textColor: "neutral.500",
                                                          textTransform: "uppercase",
                                                          children: Caml_option.some(Txt$Thin.BookingDetails.plStatus.value)
                                                        }),
                                                    style: {
                                                      width: "10rem"
                                                    }
                                                  }),
                                              JsxRuntime.jsx("th", {
                                                    children: JsxRuntime.jsx(Typography, {
                                                          level: "body-sm",
                                                          textColor: "neutral.500",
                                                          textTransform: "uppercase",
                                                          children: Caml_option.some(Txt$Thin.BookingDetails.plName.value)
                                                        }),
                                                    style: {
                                                      width: "12rem"
                                                    }
                                                  }),
                                              JsxRuntime.jsx("th", {
                                                    children: JsxRuntime.jsx(Typography, {
                                                          level: "body-sm",
                                                          textColor: "neutral.500",
                                                          textTransform: "uppercase",
                                                          children: Caml_option.some(Txt$Thin.BookingDetails.plBirthdate.value)
                                                        }),
                                                    style: {
                                                      width: "10rem"
                                                    }
                                                  }),
                                              JsxRuntime.jsx("th", {
                                                    children: JsxRuntime.jsx(Typography, {
                                                          level: "body-sm",
                                                          textColor: "neutral.500",
                                                          textTransform: "uppercase",
                                                          children: Caml_option.some(Txt$Thin.BookingDetails.plEmail.value)
                                                        }),
                                                    style: {
                                                      width: "12rem"
                                                    }
                                                  }),
                                              JsxRuntime.jsx("th", {
                                                    style: {
                                                      width: "10rem"
                                                    }
                                                  })
                                            ]
                                          })
                                    }),
                                JsxRuntime.jsxs("tbody", {
                                      children: [
                                        Belt_Array.mapWithIndex(passengers, (function (i, p) {
                                                return JsxRuntime.jsx(BDPassengerList$BDPassengerTableRow, {
                                                            fragmentRefs: p.fragmentRefs,
                                                            currentUserEmail: currentUserEmail,
                                                            isBc: isBc,
                                                            isActive: isActive
                                                          }, String(i));
                                              })),
                                        tbas
                                      ]
                                    })
                              ]
                            }))
                  })
            });
}

var make = BDPassengerList;

exports.DetailFragment = DetailFragment;
exports.PassengerFragment = PassengerFragment;
exports.PassengerMenu = PassengerMenu;
exports.BDPassengerTableRow = BDPassengerTableRow;
exports.BDTBA = BDTBA;
exports.AddBerthFragment = AddBerthFragment;
exports.AddBerth = AddBerth;
exports.make = make;
/* react Not a pure module */
