// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var Date$Util = require("util/src/Date.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CustomScalars$Thin = require("../utils/CustomScalars.bs.js");

var Types = {};

var fragmentConverter = {"__root":{"legalDocumentLastName":{"c":"CustomScalars.NonemptyString"},"legalDocumentFirstName":{"c":"CustomScalars.NonemptyString"},"legalDocumentBirthdate":{"c":"Util.Date.SQLDate.Exn"},"lastName":{"c":"CustomScalars.NonemptyString"},"firstName":{"c":"CustomScalars.NonemptyString"}}};

var fragmentConverterMap = {
  "CustomScalars.NonemptyString": CustomScalars$Thin.NonemptyString.parse,
  "Util.Date.SQLDate.Exn": Date$Util.SQLDate.Exn.parse
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

function docGender_decode($$enum) {
  if ($$enum === "X" || $$enum === "M" || $$enum === "F") {
    return $$enum;
  }
  
}

var docGender_fromString = docGender_decode;

var Utils = {
  docGender_decode: docGender_decode,
  docGender_fromString: docGender_fromString
};

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PersonalInformation_profile",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "legalDocumentFirstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "legalDocumentLastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "legalDocumentGender",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "legalDocumentBirthdate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "legalDocumentCitizenship",
      "storageKey": null
    }
  ],
  "type": "CurrentUserProfile",
  "abstractKey": null
};

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* Date-Util Not a pure module */
