// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Button$Thin = require("../../common/atoms/Button.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var List = require("@mui/joy/List").default;
var Stack = require("@mui/joy/Stack").default;
var $$Option = require("@mui/joy/Option").default;
var Select = require("@mui/joy/Select").default;
var ListItem = require("@mui/joy/ListItem").default;
var JsxRuntime = require("react/jsx-runtime");
var FormLabel = require("@mui/joy/FormLabel").default;
var CustomScalars$Thin = require("../../../utils/CustomScalars.bs.js");
var Typography = require("@mui/joy/Typography").default;
var FormControl = require("@mui/joy/FormControl").default;
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var FormHelperText = require("@mui/joy/FormHelperText").default;
var ListItemButton = require("@mui/joy/ListItemButton").default;
var ListItemContent = require("@mui/joy/ListItemContent").default;
var ListItemDecorator = require("@mui/joy/ListItemDecorator").default;
var AddConnectedAccount_query_graphql$Thin = require("../../../__generated__/AddConnectedAccount_query_graphql.bs.js");
var AccountCircleOutlined = require("@mui/icons-material/AccountCircleOutlined").default;

var convertFragment = AddConnectedAccount_query_graphql$Thin.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(AddConnectedAccount_query_graphql$Thin.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, AddConnectedAccount_query_graphql$Thin.node, convertFragment);
}

var QueryFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

function AddConnectedAccount(props) {
  var mobile = props.mobile;
  var setConnectedAcct = props.setConnectedAcct;
  var connectedAcct = props.connectedAcct;
  var mobile$1 = mobile !== undefined ? mobile : false;
  var query = RescriptRelay_Fragment.useFragment(AddConnectedAccount_query_graphql$Thin.node, convertFragment, props.connectedAcctFrag);
  var match = query.currentUserConnectedAccounts;
  var connectedTuples = match !== undefined ? Belt_Array.map(match.nodes, (function (c) {
            return [
                    CustomScalars$Thin.AccountId.serialize(c.accountId),
                    c.fullDisplayName
                  ];
          })) : [];
  if (mobile$1) {
    return JsxRuntime.jsxs(Stack, {
                sx: {
                  p: 2
                },
                children: [
                  JsxRuntime.jsx(Typography, {
                        level: "body-md",
                        sx: {
                          mb: 1,
                          color: "text.tertiary"
                        },
                        children: "Add a passenger from one of your connected accounts."
                      }),
                  JsxRuntime.jsx(Stack, {
                        sx: {
                          py: 1
                        },
                        children: Caml_option.some(JsxRuntime.jsx(List, {
                                  children: Caml_option.some(Belt_Array.map(connectedTuples, (function (param) {
                                              var accountId = param[0];
                                              return JsxRuntime.jsx(ListItem, {
                                                          children: Caml_option.some(JsxRuntime.jsxs(ListItemButton, {
                                                                    children: [
                                                                      JsxRuntime.jsx(ListItemDecorator, {
                                                                            children: Caml_option.some(JsxRuntime.jsx(AccountCircleOutlined, {})),
                                                                            sx: {
                                                                              "--ListItemDecorator-size": "1.75rem"
                                                                            }
                                                                          }),
                                                                      JsxRuntime.jsx(ListItemContent, {
                                                                            children: Caml_option.some(param[1])
                                                                          })
                                                                    ],
                                                                    onClick: (function (param) {
                                                                        Curry._1(setConnectedAcct, (function (param) {
                                                                                return accountId;
                                                                              }));
                                                                      }),
                                                                    selected: (connectedAcct == null) ? false : connectedAcct === accountId
                                                                  }))
                                                        }, accountId);
                                            })))
                                }))
                      })
                ]
              });
  } else {
    return JsxRuntime.jsxs(Stack, {
                component: "form",
                spacing: 2,
                sx: {
                  py: 1
                },
                children: [
                  JsxRuntime.jsxs(FormControl, {
                        children: [
                          JsxRuntime.jsx(FormLabel, {
                                children: "Passenger"
                              }),
                          JsxRuntime.jsx(Select, {
                                children: Caml_option.some(Belt_Array.map(connectedTuples, (function (param) {
                                            var accountId = param[0];
                                            return JsxRuntime.jsxs($$Option, {
                                                        value: accountId,
                                                        children: [
                                                          JsxRuntime.jsx(ListItemDecorator, {
                                                                children: Caml_option.some(JsxRuntime.jsx(AccountCircleOutlined, {})),
                                                                sx: {
                                                                  "--ListItemDecorator-size": "1.75rem"
                                                                }
                                                              }),
                                                          param[1]
                                                        ]
                                                      }, accountId);
                                          }))),
                                onChange: (function (param, v) {
                                    Curry._1(setConnectedAcct, (function (param) {
                                            return v;
                                          }));
                                  }),
                                placeholder: "Select connected account",
                                slotProps: {
                                  listbox: {
                                    sx: {
                                      zIndex: "modal"
                                    }
                                  }
                                },
                                value: Caml_option.some(connectedAcct),
                                variant: "outlined"
                              }),
                          JsxRuntime.jsx(FormHelperText, {
                                children: "Choose a passenger from the list."
                              })
                        ]
                      }),
                  JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx(Button$Thin.make, {
                              fullWidth: false,
                              type_: "submit",
                              onClick: (function (param) {
                                  
                                }),
                              size: "lg",
                              disabled: true,
                              children: "Add Passenger"
                            })
                      })
                ]
              });
  }
}

var make = AddConnectedAccount;

exports.QueryFragment = QueryFragment;
exports.make = make;
/* Button-Thin Not a pure module */
