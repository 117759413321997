// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Input = require("@mui/joy/Input").default;
var JsxRuntime = require("react/jsx-runtime");
var FormLabel = require("@mui/joy/FormLabel").default;
var FormControl = require("@mui/joy/FormControl").default;
var FormHelperText = require("@mui/joy/FormHelperText").default;

function TextInput(props) {
  var autoComplete = props.autoComplete;
  var width = props.width;
  var slotProps = props.slotProps;
  var helperModule = props.helperModule;
  var label = props.label;
  var onBlur = props.onBlur;
  var onFocus = props.onFocus;
  var onChange = props.onChange;
  var value = props.value;
  var role = props.role;
  var error = props.error;
  var variant = props.variant;
  var required = props.required;
  var disabled = props.disabled;
  var placeholder = props.placeholder;
  var color = props.color;
  var size = props.size;
  var fullWidth = props.fullWidth;
  var className = props.className;
  var type_ = props.type_;
  var fullWidth$1 = fullWidth !== undefined ? fullWidth : false;
  var size$1 = size !== undefined ? size : "md";
  var color$1 = color !== undefined ? color : "neutral";
  var disabled$1 = disabled !== undefined ? disabled : false;
  var required$1 = required !== undefined ? required : false;
  var variant$1 = variant !== undefined ? variant : "outlined";
  var error$1 = error !== undefined ? error : false;
  var onChange$1 = onChange !== undefined ? onChange : (function (param) {
        
      });
  var onFocus$1 = onFocus !== undefined ? onFocus : (function (param) {
        
      });
  var onBlur$1 = onBlur !== undefined ? onBlur : (function (param) {
        
      });
  var autoComplete$1 = autoComplete !== undefined ? autoComplete : "on";
  if (helperModule !== undefined) {
    return JsxRuntime.jsxs(FormControl, {
                sx: {
                  width: Belt_Option.getWithDefault(width, "auto"),
                  pb: 1
                },
                children: [
                  label !== undefined ? JsxRuntime.jsx(FormLabel, {
                          children: Caml_option.some(label)
                        }) : null,
                  JsxRuntime.jsx(Input, {
                        className: Belt_Option.getWithDefault(className, ""),
                        onFocus: onFocus$1,
                        onBlur: onBlur$1,
                        fullWidth: fullWidth$1,
                        size: size$1,
                        color: color$1,
                        placeholder: Belt_Option.getWithDefault(placeholder, ""),
                        disabled: disabled$1,
                        required: required$1,
                        variant: variant$1,
                        error: error$1,
                        sx: {
                          width: Belt_Option.getWithDefault(width, "auto")
                        },
                        role: Belt_Option.getWithDefault(role, ""),
                        type: type_,
                        value: value,
                        onChange: onChange$1,
                        slotProps: Caml_option.some(slotProps),
                        autoComplete: autoComplete$1
                      }),
                  JsxRuntime.jsx(FormHelperText, {
                        children: Caml_option.some(Caml_option.valFromOption(helperModule))
                      })
                ]
              });
  } else {
    return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                children: [
                  label !== undefined ? JsxRuntime.jsx(FormLabel, {
                          children: Caml_option.some(label)
                        }) : null,
                  JsxRuntime.jsx(Input, {
                        className: Belt_Option.getWithDefault(className, ""),
                        onFocus: onFocus$1,
                        onBlur: onBlur$1,
                        fullWidth: fullWidth$1,
                        size: size$1,
                        color: color$1,
                        placeholder: Belt_Option.getWithDefault(placeholder, ""),
                        disabled: disabled$1,
                        required: required$1,
                        variant: variant$1,
                        error: error$1,
                        role: Belt_Option.getWithDefault(role, ""),
                        type: type_,
                        value: value,
                        onChange: onChange$1,
                        slotProps: Caml_option.some(slotProps),
                        autoComplete: autoComplete$1
                      })
                ]
              });
  }
}

var make = TextInput;

exports.make = make;
/* @mui/joy/Input Not a pure module */
