// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Txt$Thin = require("../../../text/Txt.bs.js");
var SysCtx$Thin = require("../../../utils/SysCtx.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var VerifySubmit_VerifyEmailWithTokenMutation_graphql$Thin = require("../../../__generated__/VerifySubmit_VerifyEmailWithTokenMutation_graphql.bs.js");

var convertVariables = VerifySubmit_VerifyEmailWithTokenMutation_graphql$Thin.Internal.convertVariables;

var convertResponse = VerifySubmit_VerifyEmailWithTokenMutation_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = VerifySubmit_VerifyEmailWithTokenMutation_graphql$Thin.Internal.convertWrapRawResponse;

function commitMutation(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8) {
  return RescriptRelay_Mutation.commitMutation(convertVariables, VerifySubmit_VerifyEmailWithTokenMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse, param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8);
}

function use(param) {
  return RescriptRelay_Mutation.useMutation(convertVariables, VerifySubmit_VerifyEmailWithTokenMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse, param);
}

var VerifyEmailWithTokenMutation = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation(param) {
  var match = use(undefined);
  var mutate = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setError = match$1[1];
  var match$2 = React.useState(function () {
        return false;
      });
  var setVerified = match$2[1];
  var verify = function (allegedToken) {
    Curry._1(setError, (function (param) {
            
          }));
    Curry._8(mutate, {
          input: {
            allegedToken: allegedToken
          }
        }, undefined, undefined, undefined, (function (resp, err) {
            if (err === undefined) {
              var match = resp.verifyEmailWithToken;
              if (match !== undefined) {
                var match$1 = match.boolean;
                if (match$1 !== undefined && match$1) {
                  Curry._1(setVerified, (function (param) {
                          return true;
                        }));
                  setTimeout((function (param) {
                          RescriptReactRouter.push(SysCtx$Thin.vars.accountLink);
                        }), 3000);
                  return ;
                }
                
              }
              Curry._1(setError, (function (param) {
                      return Txt$Thin.Verify.verifyError.value;
                    }));
              return ;
            }
            Curry._1(setError, (function (param) {
                    return Txt$Thin.Verify.verifyError.value;
                  }));
          }), (function (param) {
            Curry._1(setError, (function (param) {
                    return Txt$Thin.$$Error.genericFormSubmit.value;
                  }));
          }), undefined, undefined);
  };
  return {
          error: match$1[0],
          verified: match$2[0],
          verify: verify
        };
}

exports.VerifyEmailWithTokenMutation = VerifyEmailWithTokenMutation;
exports.useMutation = useMutation;
/* react Not a pure module */
