// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Txt$Thin = require("../../text/Txt.bs.js");
var Alert$Thin = require("../common/atoms/Alert.bs.js");
var Merge$Util = require("util/src/Merge.bs.js");
var Button$Thin = require("../common/atoms/Button.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thin = require("../../utils/Common.bs.js");
var Dialog$Thin = require("../common/molecules/Dialog.bs.js");
var Stack = require("@mui/joy/Stack").default;
var TextInput$Thin = require("../common/molecules/TextInput.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var ChangeEmailSubmit$Thin = require("./mutations/ChangeEmailSubmit.bs.js");

function ChangeEmailForm(props) {
  var onClose = props.onClose;
  var match = ChangeEmailSubmit$Thin.useMutation(props.refresh);
  var setError = match.setError;
  var error = match.error;
  var setNewEmail = match.setNewEmail;
  var newEmail = match.newEmail;
  return JsxRuntime.jsxs(Dialog$Thin.make, {
              open_: props.open_,
              title: Txt$Thin.Account.emailModalHeading.value,
              description: Caml_option.some(Txt$Thin.Account.emailModalDescription.value),
              onClose: (function (param) {
                  Curry._1(onClose, undefined);
                  Curry._1(setNewEmail, (function (param) {
                          return "";
                        }));
                  Curry._1(setError, (function (param) {
                          
                        }));
                }),
              titleId: "email-dialog-title",
              titleDescId: "email-dialog-description",
              children: [
                JsxRuntime.jsx(Stack, {
                      spacing: 2,
                      children: Caml_option.some(match.success ? JsxRuntime.jsx(Alert$Thin.make, {
                                  message: Merge$Util.render(Txt$Thin.Account.emailVerify, props.userContext),
                                  type_: "warning"
                                }) : JsxRuntime.jsxs(Stack, {
                                  direction: "column",
                                  component: "form",
                                  spacing: 2,
                                  sx: {
                                    width: "100%"
                                  },
                                  onSubmit: match.onSubmit,
                                  disabled: newEmail === "" || !Common$Thin.Email.isValid(newEmail),
                                  children: [
                                    JsxRuntime.jsx(TextInput$Thin.make, {
                                          type_: "email",
                                          placeholder: Txt$Thin.Account.emailModalEmailPlaceholder.value,
                                          error: newEmail === "" || !Common$Thin.Email.isValid(newEmail),
                                          value: newEmail,
                                          onChange: (function (e) {
                                              var val = e.currentTarget.value;
                                              Curry._1(setNewEmail, (function (param) {
                                                      return val;
                                                    }));
                                            })
                                        }),
                                    JsxRuntime.jsx(Button$Thin.make, {
                                          fullWidth: true,
                                          loading: match.isMutating,
                                          type_: "submit",
                                          disabled: newEmail === "" || !Common$Thin.Email.isValid(newEmail),
                                          children: Caml_option.some(Txt$Thin.Account.emailModalChangeButton.value)
                                        })
                                  ]
                                }))
                    }),
                error !== undefined ? JsxRuntime.jsx(Alert$Thin.make, {
                        message: error,
                        type_: "error"
                      }) : JsxRuntime.jsx(JsxRuntime.Fragment, {})
              ]
            });
}

var make = ChangeEmailForm;

exports.make = make;
/* Txt-Thin Not a pure module */
