// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Txt$Thin = require("../../text/Txt.bs.js");
var Merge$Util = require("util/src/Merge.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thin = require("../../utils/Common.bs.js");
var Chip = require("@mui/joy/Chip").default;
var List = require("@mui/joy/List").default;
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var Tooltip = require("@mui/joy/Tooltip").default;
var ListItem = require("@mui/joy/ListItem").default;
var JsxRuntime = require("react/jsx-runtime");
var CustomScalars$Thin = require("../../utils/CustomScalars.bs.js");
var Typography = require("@mui/joy/Typography").default;
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var ListItemButton = require("@mui/joy/ListItemButton").default;
var BDManageResDropdown$Thin = require("./molecules/BDManageResDropdown.bs.js");
var ListItemDecorator = require("@mui/joy/ListItemDecorator").default;
var MailOutline = require("@mui/icons-material/MailOutline").default;
var LayersOutlined = require("@mui/icons-material/LayersOutlined").default;
var Person2Outlined = require("@mui/icons-material/Person2Outlined").default;
var BDStateroomDetails_detail_graphql$Thin = require("../../__generated__/BDStateroomDetails_detail_graphql.bs.js");
var BedroomParentOutlined = require("@mui/icons-material/BedroomParentOutlined").default;
var BDStateroomDetails_stateroom_graphql$Thin = require("../../__generated__/BDStateroomDetails_stateroom_graphql.bs.js");

var convertFragment = BDStateroomDetails_detail_graphql$Thin.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(BDStateroomDetails_detail_graphql$Thin.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BDStateroomDetails_detail_graphql$Thin.node, convertFragment);
}

var DetailFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

var convertFragment$1 = BDStateroomDetails_stateroom_graphql$Thin.Internal.convertFragment;

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(BDStateroomDetails_stateroom_graphql$Thin.node, convertFragment$1, fRef);
}

function useOpt$1(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BDStateroomDetails_stateroom_graphql$Thin.node, convertFragment$1);
}

var StateroomInfoFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$1,
  use: use$1,
  useOpt: useOpt$1
};

function BDStateroomDetails$StateroomInfo(props) {
  var data = RescriptRelay_Fragment.useFragment(BDStateroomDetails_stateroom_graphql$Thin.node, convertFragment$1, props.fragmentRefs);
  var $$class = CustomScalars$Thin.CabinClassSlug.serialize(data.cabinClass);
  var category = CustomScalars$Thin.CabinCategorySlug.serialize(data.cabinCategory);
  var numBerths = data.numBerths;
  var cabinNumber = CustomScalars$Thin.CabinNumber.serialize(data.cabinNumber);
  var cabinDeckNumber = data.cabinDeckNumber;
  var userContext_numBerths = String(numBerths);
  var userContext_berthsPlural = numBerths !== 1;
  var userContext = {
    cabinDeckNumber: cabinDeckNumber,
    cabinNumber: cabinNumber,
    numBerths: userContext_numBerths,
    berthsPlural: userContext_berthsPlural
  };
  return JsxRuntime.jsxs(Stack, {
              direction: {
                xs: "column",
                md: "row"
              },
              spacing: 2,
              sx: {
                width: "100%"
              },
              children: [
                JsxRuntime.jsx(Stack, {
                      sx: {
                        backgroundImage: "url(https://i.ibb.co/kGcdwMS/stateroomtest.jpg)",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        width: {
                          xs: "100%",
                          md: "12rem",
                          lg: "14rem"
                        },
                        minWidth: "8rem",
                        height: "10rem",
                        borderRadius: "md"
                      }
                    }),
                JsxRuntime.jsxs(Stack, {
                      direction: "column",
                      spacing: {
                        xs: 1,
                        lg: 2
                      },
                      children: [
                        JsxRuntime.jsx(Typography, {
                              level: "h3",
                              component: "h3",
                              children: Caml_option.some($$class)
                            }),
                        JsxRuntime.jsx(Typography, {
                              level: "title-lg",
                              children: Caml_option.some(category)
                            }),
                        JsxRuntime.jsxs(Stack, {
                              direction: "row",
                              spacing: 1,
                              useFlexGap: true,
                              flexWrap: "wrap",
                              children: [
                                JsxRuntime.jsx(Chip, {
                                      children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                level: "body-xs",
                                                fontWeight: "sm",
                                                children: Caml_option.some(Merge$Util.render(Txt$Thin.BookingDetails.cabinDeckChipText, userContext))
                                              })),
                                      size: "md",
                                      startDecorator: Caml_option.some(JsxRuntime.jsx(LayersOutlined, {})),
                                      sx: {
                                        backgroundColor: "neutral.100",
                                        color: "text.primary",
                                        borderRadius: "xs"
                                      }
                                    }),
                                JsxRuntime.jsx(Chip, {
                                      children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                level: "body-xs",
                                                fontWeight: "sm",
                                                children: Caml_option.some(Merge$Util.render(Txt$Thin.BookingDetails.cabinNumberChipText, userContext))
                                              })),
                                      size: "md",
                                      sx: {
                                        backgroundColor: "neutral.100",
                                        color: "text.primary",
                                        borderRadius: "xs"
                                      }
                                    }),
                                JsxRuntime.jsx(Chip, {
                                      children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                level: "body-xs",
                                                fontWeight: "sm",
                                                children: Caml_option.some(Merge$Util.render(Txt$Thin.BookingDetails.berthsChipText, userContext))
                                              })),
                                      size: "md",
                                      startDecorator: Caml_option.some(JsxRuntime.jsx(BedroomParentOutlined, {})),
                                      sx: {
                                        backgroundColor: "neutral.100",
                                        color: "text.primary",
                                        borderRadius: "xs"
                                      }
                                    })
                              ]
                            })
                      ]
                    })
              ]
            });
}

var StateroomInfo = {
  make: BDStateroomDetails$StateroomInfo
};

function BDStateroomDetails$BillingContactInfo(props) {
  var billingContactEmail = props.billingContactEmail;
  var match = React.useState(function () {
        return false;
      });
  var setCopied = match[1];
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(Typography, {
                      level: "h4",
                      component: "h3",
                      textColor: "text.tertiary",
                      children: Caml_option.some(Txt$Thin.BookingDetails.billingContactHeading.value)
                    }),
                JsxRuntime.jsxs(Stack, {
                      direction: {
                        xs: "column",
                        md: "row"
                      },
                      justifyContent: "space-between",
                      spacing: {
                        xs: 2,
                        md: 0
                      },
                      children: [
                        JsxRuntime.jsxs(List, {
                              children: [
                                JsxRuntime.jsxs(ListItem, {
                                      children: [
                                        JsxRuntime.jsx(ListItemDecorator, {
                                              children: Caml_option.some(JsxRuntime.jsx(Person2Outlined, {}))
                                            }),
                                        JsxRuntime.jsx(Typography, {
                                              level: "body-sm",
                                              noWrap: true,
                                              sx: {
                                                overflow: "hidden",
                                                textOverflow: "ellipsis"
                                              },
                                              textColor: "inherit",
                                              children: Caml_option.some(props.billingContactPreferredName)
                                            })
                                      ]
                                    }),
                                JsxRuntime.jsx(Tooltip, {
                                      children: JsxRuntime.jsx(ListItem, {
                                            children: Caml_option.some(JsxRuntime.jsxs(ListItemButton, {
                                                      children: [
                                                        JsxRuntime.jsx(ListItemDecorator, {
                                                              children: Caml_option.some(JsxRuntime.jsx(MailOutline, {}))
                                                            }),
                                                        JsxRuntime.jsx(Typography, {
                                                              level: "body-sm",
                                                              sx: {
                                                                overflow: "hidden",
                                                                textOverflow: "ellipsis"
                                                              },
                                                              textColor: "inherit",
                                                              children: Caml_option.some(billingContactEmail)
                                                            })
                                                      ],
                                                      onClick: (function (param) {
                                                          return Common$Thin.copyToClipboard(billingContactEmail, setCopied);
                                                        })
                                                    }))
                                          }),
                                      arrow: true,
                                      disableHoverListener: true,
                                      open: match[0],
                                      size: "sm",
                                      title: Txt$Thin.Clipboard.copied.value
                                    })
                              ],
                              size: "sm",
                              sx: {
                                maxWidth: "fit-content",
                                minWidth: 0
                              }
                            }),
                        JsxRuntime.jsx(Stack, {
                              sx: {
                                display: "flex",
                                minWidth: "fit-content",
                                justifyContent: {
                                  xs: "flex-start",
                                  md: "flex-end"
                                },
                                alignItems: {
                                  xs: "flex-start",
                                  md: "flex-end"
                                }
                              },
                              children: Caml_option.some(props.isBc && props.isActive ? JsxRuntime.jsx(BDManageResDropdown$Thin.make, {
                                          bookingId: props.bookingId
                                        }) : null)
                            })
                      ]
                    })
              ]
            });
}

var BillingContactInfo = {
  make: BDStateroomDetails$BillingContactInfo
};

function BDStateroomDetails(props) {
  var data = RescriptRelay_Fragment.useFragment(BDStateroomDetails_detail_graphql$Thin.node, convertFragment, props.fragmentRefs);
  var bcEmail = CustomScalars$Thin.Email.serialize(data.billingContactEmail);
  var bcName = data.billingContactPreferredName;
  return JsxRuntime.jsx(Sheet, {
              sx: {
                p: 2,
                borderRadius: "md",
                border: 1,
                borderColor: "neutral.300",
                height: "100%"
              },
              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                        direction: "column",
                        spacing: 2,
                        children: [
                          JsxRuntime.jsx(Typography, {
                                level: "h3",
                                component: "h2",
                                fontWeight: "md",
                                children: Caml_option.some(Txt$Thin.BookingDetails.stateroomDetailsHeading.value)
                              }),
                          JsxRuntime.jsx(BDStateroomDetails$StateroomInfo, {
                                fragmentRefs: data.fragmentRefs
                              }),
                          JsxRuntime.jsx(BDStateroomDetails$BillingContactInfo, {
                                billingContactEmail: bcEmail,
                                billingContactPreferredName: bcName,
                                isBc: props.isBc,
                                bookingId: props.bookingId,
                                isActive: props.isActive
                              })
                        ]
                      }))
            });
}

var make = BDStateroomDetails;

exports.DetailFragment = DetailFragment;
exports.StateroomInfoFragment = StateroomInfoFragment;
exports.StateroomInfo = StateroomInfo;
exports.BillingContactInfo = BillingContactInfo;
exports.make = make;
/* react Not a pure module */
