// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Txt$Thin = require("../../text/Txt.bs.js");
var Alert$Thin = require("../common/atoms/Alert.bs.js");
var Merge$Util = require("util/src/Merge.bs.js");
var Button$Thin = require("../common/atoms/Button.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Dialog$Thin = require("../common/molecules/Dialog.bs.js");
var SysCtx$Thin = require("../../utils/SysCtx.bs.js");
var Stack = require("@mui/joy/Stack").default;
var JsxRuntime = require("react/jsx-runtime");
var PasswordInput$Thin = require("../common/molecules/PasswordInput.bs.js");
var ChangePasswordSubmit$Thin = require("./mutations/ChangePasswordSubmit.bs.js");

function ChangePasswordForm(props) {
  var onClose = props.onClose;
  var match = ChangePasswordSubmit$Thin.useMutation(undefined);
  var setError = match.setError;
  var error = match.error;
  var setConfirmPassword = match.setConfirmPassword;
  var confirmPassword = match.confirmPassword;
  var setNewPassword = match.setNewPassword;
  var newPassword = match.newPassword;
  var setCurrentPassword = match.setCurrentPassword;
  var currentPassword = match.currentPassword;
  return JsxRuntime.jsxs(Dialog$Thin.make, {
              open_: props.open_,
              title: Txt$Thin.Account.passwordModalHeading.value,
              description: Caml_option.some(Txt$Thin.Account.passwordModalDescription.value),
              onClose: (function (param) {
                  Curry._1(onClose, undefined);
                  Curry._1(setCurrentPassword, (function (param) {
                          return "";
                        }));
                  Curry._1(setNewPassword, (function (param) {
                          return "";
                        }));
                  Curry._1(setConfirmPassword, (function (param) {
                          return "";
                        }));
                  Curry._1(setError, (function (param) {
                          
                        }));
                }),
              titleId: "password-dialog-title",
              titleDescId: "password-dialog-description",
              children: [
                JsxRuntime.jsx(Stack, {
                      spacing: 2,
                      children: Caml_option.some(match.success ? JsxRuntime.jsx(Alert$Thin.make, {
                                  message: Merge$Util.render(Txt$Thin.Account.passwordModalSuccess, SysCtx$Thin.vars),
                                  type_: "success"
                                }) : JsxRuntime.jsxs(Stack, {
                                  direction: "column",
                                  component: "form",
                                  spacing: 2,
                                  sx: {
                                    width: "100%"
                                  },
                                  onSubmit: match.onSubmit,
                                  disabled: currentPassword === "" || newPassword === "" || confirmPassword === "" || newPassword !== confirmPassword,
                                  children: [
                                    JsxRuntime.jsx(PasswordInput$Thin.make, {
                                          placeholder: Txt$Thin.Account.passwordModalCurrentPasswordPlaceholder.value,
                                          value: currentPassword,
                                          onChange: (function (e) {
                                              var val = e.currentTarget.value;
                                              Curry._1(setCurrentPassword, (function (param) {
                                                      return val;
                                                    }));
                                            }),
                                          autoComplete: "current-password"
                                        }),
                                    JsxRuntime.jsx(PasswordInput$Thin.make, {
                                          placeholder: Txt$Thin.Account.passwordModalNewPasswordPlaceholder.value,
                                          value: newPassword,
                                          onChange: (function (e) {
                                              var val = e.currentTarget.value;
                                              Curry._1(setNewPassword, (function (param) {
                                                      return val;
                                                    }));
                                            }),
                                          autoComplete: "new-password"
                                        }),
                                    JsxRuntime.jsx(PasswordInput$Thin.make, {
                                          placeholder: Txt$Thin.Account.passwordModalConfirmPasswordPlaceholder.value,
                                          error: newPassword !== confirmPassword,
                                          value: confirmPassword,
                                          onChange: (function (e) {
                                              var val = e.currentTarget.value;
                                              Curry._1(setConfirmPassword, (function (param) {
                                                      return val;
                                                    }));
                                            }),
                                          autoComplete: "new-password"
                                        }),
                                    JsxRuntime.jsx(Button$Thin.make, {
                                          fullWidth: true,
                                          loading: match.isMutating,
                                          type_: "submit",
                                          disabled: currentPassword === "" || newPassword === "" || confirmPassword === "" || newPassword !== confirmPassword,
                                          children: Caml_option.some(Txt$Thin.Account.passwordModalChangeButton.value)
                                        })
                                  ]
                                }))
                    }),
                error !== undefined ? JsxRuntime.jsx(Alert$Thin.make, {
                        message: error,
                        type_: "error"
                      }) : JsxRuntime.jsx(JsxRuntime.Fragment, {})
              ]
            });
}

var make = ChangePasswordForm;

exports.make = make;
/* Txt-Thin Not a pure module */
