// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Txt$Thin = require("../../../text/Txt.bs.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var ResetPasswordSubmit_ResetPasswordWithTokenMutation_graphql$Thin = require("../../../__generated__/ResetPasswordSubmit_ResetPasswordWithTokenMutation_graphql.bs.js");

var convertVariables = ResetPasswordSubmit_ResetPasswordWithTokenMutation_graphql$Thin.Internal.convertVariables;

var convertResponse = ResetPasswordSubmit_ResetPasswordWithTokenMutation_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = ResetPasswordSubmit_ResetPasswordWithTokenMutation_graphql$Thin.Internal.convertWrapRawResponse;

function commitMutation(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8) {
  return RescriptRelay_Mutation.commitMutation(convertVariables, ResetPasswordSubmit_ResetPasswordWithTokenMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse, param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8);
}

function use(param) {
  return RescriptRelay_Mutation.useMutation(convertVariables, ResetPasswordSubmit_ResetPasswordWithTokenMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse, param);
}

var ResetPasswordWithTokenMutation = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation(param) {
  var match = use(undefined);
  var mutate = match[0];
  var match$1 = React.useState(function () {
        return "";
      });
  var setPassword = match$1[1];
  var password = match$1[0];
  var match$2 = React.useState(function () {
        return "";
      });
  var setConfirmPassword = match$2[1];
  var confirmPassword = match$2[0];
  var match$3 = React.useState(function () {
        return false;
      });
  var setPassUpdateSuccess = match$3[1];
  var match$4 = React.useState(function () {
        
      });
  var setResetError = match$4[1];
  var onSubmit = function (e, token) {
    e.preventDefault();
    if (password === "" || confirmPassword === "" || password !== confirmPassword) {
      return ;
    } else {
      Curry._1(setResetError, (function (param) {
              
            }));
      Curry._8(mutate, {
            input: {
              allegedToken: token,
              newPasswd: password
            }
          }, undefined, undefined, undefined, (function (resp, err) {
              if (err !== undefined) {
                return Curry._1(setResetError, (function (param) {
                              return Txt$Thin.ResetPassword.resetError.value;
                            }));
              }
              var match = resp.resetPasswordWithToken;
              if (match !== undefined) {
                var match$1 = match.boolean;
                if (match$1 !== undefined && match$1) {
                  return Curry._1(setPassUpdateSuccess, (function (param) {
                                return true;
                              }));
                }
                
              }
              Curry._1(setResetError, (function (param) {
                      return Txt$Thin.ResetPassword.heading.value;
                    }));
            }), (function (err) {
              Curry._1(setResetError, (function (param) {
                      return Txt$Thin.$$Error.genericFormSubmit.value;
                    }));
            }), undefined, undefined);
      Curry._1(setPassword, (function (param) {
              return "";
            }));
      return Curry._1(setConfirmPassword, (function (param) {
                    return "";
                  }));
    }
  };
  return {
          isMutating: match[1],
          password: password,
          setPassword: setPassword,
          confirmPassword: confirmPassword,
          setConfirmPassword: setConfirmPassword,
          onSubmit: onSubmit,
          passUpdateSuccess: match$3[0],
          resetError: match$4[0]
        };
}

exports.ResetPasswordWithTokenMutation = ResetPasswordWithTokenMutation;
exports.useMutation = useMutation;
/* react Not a pure module */
