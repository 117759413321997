// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var Date$Util = require("util/src/Date.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CustomScalars$Thin = require("../utils/CustomScalars.bs.js");

function makeRefetchVariables(brandFamily, currentDate, param) {
  return {
          brandFamily: brandFamily,
          currentDate: currentDate
        };
}

var Types = {
  makeRefetchVariables: makeRefetchVariables
};

var variablesConverter = {"__root":{"currentDate":{"c":"Util.Date.SQLDate.Exn"},"brandFamily":{"c":"CustomScalars.BrandFamilySlug"}}};

var variablesConverterMap = {
  "CustomScalars.BrandFamilySlug": CustomScalars$Thin.BrandFamilySlug.serialize,
  "Util.Date.SQLDate.Exn": Date$Util.SQLDate.Exn.serialize
};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, variablesConverterMap, undefined);
}

var wrapResponseConverter = {"__root":{"":{"f":""}}};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, undefined, null);
}

var responseConverter = {"__root":{"":{"f":""}}};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, undefined, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: variablesConverterMap,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: undefined,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: undefined,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse
};

var Utils = {};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "brandFamily"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "currentDate"
  }
],
v1 = {
  "kind": "Variable",
  "name": "brandFamily",
  "variableName": "brandFamily"
},
v2 = [
  (v1/*: any*/),
  {
    "kind": "Variable",
    "name": "currentDate",
    "variableName": "currentDate"
  }
],
v3 = {
  "kind": "Variable",
  "name": "brandFamilySlug",
  "variableName": "brandFamily"
},
v4 = {
  "fields": [
    (v3/*: any*/)
  ],
  "kind": "ObjectValue",
  "name": "condition"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "bookingId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voyageSlug",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voyStartDate",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "voyEndDate",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numBerths",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BookingsRouteQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "IsLoggedIn_query"
      },
      {
        "args": [
          (v1/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "StandardLayout_query"
      },
      {
        "args": (v2/*: any*/),
        "kind": "FragmentSpread",
        "name": "UpcomingBookingsSection_query"
      },
      {
        "args": (v2/*: any*/),
        "kind": "FragmentSpread",
        "name": "BookingHistorySection_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BookingsRouteQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currentUserSignonStatus",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currentUserEmail",
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          (v4/*: any*/)
        ],
        "concreteType": "CurrentUserProfilesConnection",
        "kind": "LinkedField",
        "name": "currentUserProfiles",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CurrentUserProfile",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "upcoming",
        "args": [
          {
            "fields": [
              (v3/*: any*/),
              {
                "kind": "Literal",
                "name": "lifecycleStatus",
                "value": "ACTIVE"
              }
            ],
            "kind": "ObjectValue",
            "name": "condition"
          },
          {
            "fields": [
              {
                "fields": [
                  {
                    "kind": "Variable",
                    "name": "greaterThanOrEqualTo",
                    "variableName": "currentDate"
                  }
                ],
                "kind": "ObjectValue",
                "name": "voyStartDate"
              }
            ],
            "kind": "ObjectValue",
            "name": "filter"
          }
        ],
        "concreteType": "BookingListitemsConnection",
        "kind": "LinkedField",
        "name": "bookingListitems",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BookingListitem",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "history",
        "args": [
          (v4/*: any*/),
          {
            "fields": [
              {
                "items": [
                  {
                    "kind": "Literal",
                    "name": "or.0",
                    "value": {
                      "lifecycleStatus": {
                        "notEqualTo": "ACTIVE"
                      }
                    }
                  },
                  {
                    "fields": [
                      {
                        "fields": [
                          {
                            "kind": "Variable",
                            "name": "lessThan",
                            "variableName": "currentDate"
                          }
                        ],
                        "kind": "ObjectValue",
                        "name": "voyEndDate"
                      }
                    ],
                    "kind": "ObjectValue",
                    "name": "or.1"
                  }
                ],
                "kind": "ListValue",
                "name": "or"
              }
            ],
            "kind": "ObjectValue",
            "name": "filter"
          }
        ],
        "concreteType": "BookingListitemsConnection",
        "kind": "LinkedField",
        "name": "bookingListitems",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BookingListitem",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lifecycleStatus",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a80a586bcab618aafc2b41f094ea50ec",
    "id": null,
    "metadata": {},
    "name": "BookingsRouteQuery",
    "operationKind": "query",
    "text": "query BookingsRouteQuery(\n  $brandFamily: BrandFamilySlug!\n  $currentDate: Date!\n) {\n  ...IsLoggedIn_query\n  ...StandardLayout_query_2PeJLC\n  ...UpcomingBookingsSection_query_2nHwSE\n  ...BookingHistorySection_query_2nHwSE\n}\n\nfragment BookingHistorySection_listitem on BookingListitem {\n  bookingId\n  voyageSlug\n  voyStartDate\n  voyEndDate\n  numBerths\n  lifecycleStatus\n}\n\nfragment BookingHistorySection_query_2nHwSE on Query {\n  history: bookingListitems(condition: {brandFamilySlug: $brandFamily}, filter: {or: [{lifecycleStatus: {notEqualTo: ACTIVE}}, {voyEndDate: {lessThan: $currentDate}}]}) {\n    nodes {\n      bookingId\n      ...BookingHistorySection_listitem\n    }\n  }\n}\n\nfragment IsLoggedIn_query on Query {\n  currentUserSignonStatus\n}\n\nfragment Navigation_query_2PeJLC on Query {\n  currentUserEmail\n  currentUserSignonStatus\n  currentUserProfiles(condition: {brandFamilySlug: $brandFamily}) {\n    nodes {\n      firstName\n      lastName\n    }\n  }\n}\n\nfragment StandardLayout_query_2PeJLC on Query {\n  currentUserSignonStatus\n  currentUserEmail\n  ...Navigation_query_2PeJLC\n}\n\nfragment UpcomingBookingsSection_listitem on BookingListitem {\n  bookingId\n  voyageSlug\n  voyStartDate\n  voyEndDate\n  numBerths\n}\n\nfragment UpcomingBookingsSection_query_2nHwSE on Query {\n  upcoming: bookingListitems(condition: {brandFamilySlug: $brandFamily, lifecycleStatus: ACTIVE}, filter: {voyStartDate: {greaterThanOrEqualTo: $currentDate}}) {\n    nodes {\n      bookingId\n      ...UpcomingBookingsSection_listitem\n    }\n  }\n}\n"
  }
};
})());

var include = RescriptRelay.MakeLoadQuery({
      query: node,
      convertVariables: convertVariables
    });

var load = include.load;

var queryRefToObservable = include.queryRefToObservable;

var queryRefToPromise = include.queryRefToPromise;

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
exports.load = load;
exports.queryRefToObservable = queryRefToObservable;
exports.queryRefToPromise = queryRefToPromise;
/* node Not a pure module */
