// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var Avatar = require("@mui/joy/Avatar").default;
var JsxRuntime = require("react/jsx-runtime");

function Avatar$1(props) {
  var variant = props.variant;
  var srcSet = props.srcSet;
  var src = props.src;
  var alt = props.alt;
  var size = props.size;
  var color = props.color;
  var type_ = props.type_;
  var onClick = props.onClick;
  var type_$1 = type_ !== undefined ? type_ : "circle";
  var color$1 = color !== undefined ? color : "primary";
  var size$1 = size !== undefined ? size : "md";
  var variant$1 = variant !== undefined ? variant : "solid";
  return JsxRuntime.jsx(Avatar, {
              children: Caml_option.some(props.children),
              onClick: onClick !== undefined ? onClick : (function (param) {
                    
                  }),
              color: color$1,
              size: size$1,
              alt: alt !== undefined ? alt : "",
              src: src !== undefined ? src : "",
              srcSet: srcSet !== undefined ? srcSet : "",
              sx: {
                borderRadius: type_$1 === "square" ? "sm" : ""
              },
              variant: variant$1
            });
}

var make = Avatar$1;

exports.make = make;
/* @mui/joy/Avatar Not a pure module */
