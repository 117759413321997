// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Fetch = require("bs-fetch/src/Fetch.bs.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Js_promise = require("rescript/lib/js/js_promise.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var RelayRuntime = require("relay-runtime");
var Caml_exceptions = require("rescript/lib/js/caml_exceptions.js");
var InsecureJWTStorage$Util = require("util/src/InsecureJWTStorage.bs.js");

var Graphql_error = /* @__PURE__ */Caml_exceptions.create("RelayEnv-Thin.Graphql_error");

function fetchQuery(operation, variables, _cacheConfig, _uploadables) {
  return Js_promise.then_((function (resp) {
                if (resp.ok) {
                  return Fetch.$$Response.json(resp);
                } else {
                  return Promise.reject({
                              RE_EXN_ID: Graphql_error,
                              _1: "Request failed: " + resp.statusText
                            });
                }
              }), fetch(process.env.GRAPHILE_THIN_URL, Fetch.RequestInit.make(/* Post */2, Caml_option.some(Belt_Array.keep([
                                [
                                  "content-type",
                                  "application/json"
                                ],
                                [
                                  "accept",
                                  "application/json"
                                ],
                                [
                                  "authorization",
                                  Belt_Option.mapWithDefault(InsecureJWTStorage$Util.get(undefined), "", (function (jwt) {
                                          return "Bearer " + jwt + "";
                                        }))
                                ]
                              ], (function (param) {
                                  return param[1].trim() !== "";
                                }))), Caml_option.some(JSON.stringify(Js_dict.fromList({
                                    hd: [
                                      "query",
                                      operation.text
                                    ],
                                    tl: {
                                      hd: [
                                        "variables",
                                        variables
                                      ],
                                      tl: /* [] */0
                                    }
                                  }))), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined)));
}

var network = RelayRuntime.Network.create(fetchQuery, undefined);

var environment = RescriptRelay.Environment.make(network, RescriptRelay.Store.make(new RelayRuntime.RecordSource(undefined), 10, undefined, undefined), undefined, undefined, undefined, undefined, undefined, undefined);

exports.Graphql_error = Graphql_error;
exports.fetchQuery = fetchQuery;
exports.network = network;
exports.environment = environment;
/* network Not a pure module */
