// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var ClaimPage$Thin = require("../pages/ClaimPage.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var StandardLayout$Thin = require("../layouts/StandardLayout.bs.js");
var ClaimRouteQuery_graphql$Thin = require("../__generated__/ClaimRouteQuery_graphql.bs.js");

var convertVariables = ClaimRouteQuery_graphql$Thin.Internal.convertVariables;

var convertResponse = ClaimRouteQuery_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = ClaimRouteQuery_graphql$Thin.Internal.convertWrapRawResponse;

function use(param, param$1, param$2, param$3, param$4) {
  return RescriptRelay_Query.useQuery(convertVariables, ClaimRouteQuery_graphql$Thin.node, convertResponse, param, param$1, param$2, param$3, param$4);
}

function useLoader(param) {
  return RescriptRelay_Query.useLoader(convertVariables, ClaimRouteQuery_graphql$Thin.node, (function (prim) {
                return prim;
              }), param);
}

function usePreloaded(param) {
  return RescriptRelay_Query.usePreloaded(ClaimRouteQuery_graphql$Thin.node, convertResponse, (function (prim) {
                return prim;
              }), param);
}

function $$fetch(param, param$1, param$2, param$3, param$4, param$5) {
  return RescriptRelay_Query.$$fetch(ClaimRouteQuery_graphql$Thin.node, convertResponse, convertVariables, param, param$1, param$2, param$3, param$4, param$5);
}

function fetchPromised(param, param$1, param$2, param$3, param$4) {
  return RescriptRelay_Query.fetchPromised(ClaimRouteQuery_graphql$Thin.node, convertResponse, convertVariables, param, param$1, param$2, param$3, param$4);
}

function retain(param, param$1) {
  return RescriptRelay_Query.retain(ClaimRouteQuery_graphql$Thin.node, convertVariables, param, param$1);
}

var Query = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function ClaimRoute(props) {
  var data = use({
        brandFamily: props.brandFamily
      }, undefined, undefined, undefined, undefined);
  return JsxRuntime.jsx(StandardLayout$Thin.make, {
              fragmentRefs: data.fragmentRefs,
              dash: false,
              children: JsxRuntime.jsx(ClaimPage$Thin.make, {})
            });
}

var make = ClaimRoute;

exports.Query = Query;
exports.make = make;
/* ClaimPage-Thin Not a pure module */
