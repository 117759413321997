// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Sidebar$Thin = require("../components/common/molecules/Sidebar.bs.js");
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var Navigation$Thin = require("../components/common/molecules/Navigation.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var InsecureJWTStorage$Util = require("util/src/InsecureJWTStorage.bs.js");
var StandardLayout_query_graphql$Thin = require("../__generated__/StandardLayout_query_graphql.bs.js");

var convertFragment = StandardLayout_query_graphql$Thin.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(StandardLayout_query_graphql$Thin.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, StandardLayout_query_graphql$Thin.node, convertFragment);
}

var QueryFragment_custSignonStatus_decode = StandardLayout_query_graphql$Thin.Utils.custSignonStatus_decode;

var QueryFragment_custSignonStatus_fromString = StandardLayout_query_graphql$Thin.Utils.custSignonStatus_fromString;

var QueryFragment = {
  custSignonStatus_decode: QueryFragment_custSignonStatus_decode,
  custSignonStatus_fromString: QueryFragment_custSignonStatus_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

function StandardLayout(props) {
  var children = props.children;
  var dash = props.dash;
  var navigation = props.navigation;
  var navigation$1 = navigation !== undefined ? navigation : true;
  var dash$1 = dash !== undefined ? dash : true;
  var query = RescriptRelay_Fragment.useFragment(StandardLayout_query_graphql$Thin.node, convertFragment, props.fragmentRefs);
  React.useEffect((function () {
          var match = query.currentUserSignonStatus;
          if (match === "CLEAR_JWT") {
            InsecureJWTStorage$Util.$$delete(undefined);
            ((location.reload()));
          }
          
        }), [query.currentUserSignonStatus]);
  return JsxRuntime.jsx(React.Suspense, {
              children: Caml_option.some(JsxRuntime.jsxs(Sheet, {
                        sx: {
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          backgroundColor: "background.body"
                        },
                        children: [
                          navigation$1 ? JsxRuntime.jsx(Navigation$Thin.make, {
                                  fragmentRefs: query.fragmentRefs
                                }) : null,
                          dash$1 ? JsxRuntime.jsxs(Stack, {
                                  direction: "row",
                                  sx: {
                                    flex: 1
                                  },
                                  children: [
                                    JsxRuntime.jsx(Sidebar$Thin.make, {}),
                                    JsxRuntime.jsx(Stack, {
                                          sx: {
                                            flexGrow: 1,
                                            flexShrink: 1,
                                            overflowY: "auto"
                                          },
                                          children: Caml_option.some(children)
                                        })
                                  ]
                                }) : children
                        ]
                      })),
              fallback: "Loading..."
            });
}

var make = StandardLayout;

exports.QueryFragment = QueryFragment;
exports.make = make;
/* react Not a pure module */
