// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Mustache = require("mustache");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var JsxRuntime = require("react/jsx-runtime");

var Mustache$1 = {};

function render(param, ctx) {
  return Belt_Array.mapWithIndex(Belt_Array.map(Belt_Array.reduce(param.parseSteps, [{
                        TAG: /* Unprocessed */0,
                        _0: param.value
                      }], (function (components, step) {
                        return Curry._1(step, components);
                      })), (function (component) {
                    switch (component.TAG | 0) {
                      case /* Hydrateable */1 :
                          return Curry._1(component._0, ctx);
                      case /* Unprocessed */0 :
                      case /* Finalized */2 :
                          return component._0;
                      
                    }
                  })), (function (idx, el) {
                return JsxRuntime.jsx(React.Fragment, {
                            children: el
                          }, String(idx));
              }));
}

function mustache(components) {
  return Belt_Array.map(components, (function (component) {
                switch (component.TAG | 0) {
                  case /* Unprocessed */0 :
                      var str = component._0;
                      return {
                              TAG: /* Hydrateable */1,
                              _0: (function (ctx) {
                                  return Mustache.render(str, ctx);
                                })
                            };
                  case /* Hydrateable */1 :
                  case /* Finalized */2 :
                      return component;
                  
                }
              }));
}

function simpleTag(mergeTag, transform, components) {
  return Belt_Array.concatMany(Belt_Array.map(components, (function (component) {
                    switch (component.TAG | 0) {
                      case /* Unprocessed */0 :
                          var parse = function (str) {
                            var foundIdx = str.indexOf(mergeTag);
                            if (foundIdx !== -1) {
                              return Belt_Array.concat([
                                          {
                                            TAG: /* Unprocessed */0,
                                            _0: str.slice(0, foundIdx)
                                          },
                                          Curry._1(transform, (function (prim0, prim1) {
                                                  return Mustache.render(prim0, prim1);
                                                }))
                                        ], parse(str.substring(foundIdx + mergeTag.length | 0)));
                            } else {
                              return [{
                                        TAG: /* Unprocessed */0,
                                        _0: str
                                      }];
                            }
                          };
                          return Belt_Array.keep(parse(component._0), (function (v) {
                                        return Caml_obj.notequal(v, {
                                                    TAG: /* Unprocessed */0,
                                                    _0: ""
                                                  });
                                      }));
                      case /* Hydrateable */1 :
                      case /* Finalized */2 :
                          return [component];
                      
                    }
                  })));
}

function htmlStyleTag(tagName, transform, components) {
  var startTag = "<" + tagName + ">";
  var endTag = "</" + tagName + ">";
  return Belt_Array.concatMany(Belt_Array.map(components, (function (component) {
                    switch (component.TAG | 0) {
                      case /* Unprocessed */0 :
                          var parse = function (str) {
                            var match = str.indexOf(startTag);
                            var match$1 = str.indexOf(endTag);
                            if (!((match + startTag.length | 0) <= match$1 && match > -1)) {
                              return [{
                                        TAG: /* Unprocessed */0,
                                        _0: str
                                      }];
                            }
                            var value = str.slice(match + startTag.length | 0, match$1);
                            return Belt_Array.concat([
                                        {
                                          TAG: /* Unprocessed */0,
                                          _0: str.slice(0, match)
                                        },
                                        Curry._2(transform, value, (function (prim0, prim1) {
                                                return Mustache.render(prim0, prim1);
                                              }))
                                      ], parse(str.substring(match$1 + endTag.length | 0)));
                          };
                          return Belt_Array.keep(parse(component._0), (function (v) {
                                        return Caml_obj.notequal(v, {
                                                    TAG: /* Unprocessed */0,
                                                    _0: ""
                                                  });
                                      }));
                      case /* Hydrateable */1 :
                      case /* Finalized */2 :
                          return [component];
                      
                    }
                  })));
}

var Parse = {
  mustache: mustache,
  simpleTag: simpleTag,
  htmlStyleTag: htmlStyleTag
};

exports.Mustache = Mustache$1;
exports.render = render;
exports.Parse = Parse;
/* react Not a pure module */
