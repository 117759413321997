// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var ThinApp$Thin = require("./ThinApp.bs.js");
var RelayEnv$Thin = require("./RelayEnv.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var Client = require("react-dom/client");
var JsxRuntime = require("react/jsx-runtime");

var rootElement = document.querySelector("#root");

if (!(rootElement == null)) {
  var root = Client.createRoot(rootElement);
  root.render(JsxRuntime.jsx(RescriptRelay.Context.Provider.make, {
            environment: RelayEnv$Thin.environment,
            children: JsxRuntime.jsx(ThinApp$Thin.make, {})
          }));
}

/* rootElement Not a pure module */
