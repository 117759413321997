// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var Grid = require("@mui/joy/Grid").default;
var Modal = require("@mui/joy/Modal").default;
var Divider = require("@mui/joy/Divider").default;
var JsxRuntime = require("react/jsx-runtime");
var IconButton = require("@mui/joy/IconButton").default;
var Typography = require("@mui/joy/Typography").default;
var ModalDialog = require("@mui/joy/ModalDialog").default;
var Close = require("@mui/icons-material/Close").default;

function Dialog(props) {
  var titleDescId = props.titleDescId;
  var titleId = props.titleId;
  var onClose = props.onClose;
  var role = props.role;
  var size = props.size;
  var titleStartDecorator = props.titleStartDecorator;
  var description = props.description;
  var title = props.title;
  var title$1 = title !== undefined ? title : "";
  var description$1 = description !== undefined ? Caml_option.valFromOption(description) : JsxRuntime.jsx("div", {});
  var titleStartDecorator$1 = titleStartDecorator !== undefined ? Caml_option.valFromOption(titleStartDecorator) : JsxRuntime.jsx("div", {});
  var size$1 = size !== undefined ? size : "md";
  var role$1 = role !== undefined ? role : "dialog";
  var titleId$1 = titleId !== undefined ? titleId : "modal-title";
  var titleDescId$1 = titleDescId !== undefined ? titleDescId : "modal-description";
  return JsxRuntime.jsx(Modal, {
              open: props.open_,
              onClose: onClose,
              "aria-labelledby": titleId$1,
              "aria-describedby": titleDescId$1,
              slotProps: {
                backdrop: {
                  sx: {
                    backdropFilter: "none",
                    backgroundColor: "rgba(16, 13, 12, 0.7)"
                  }
                }
              },
              children: Caml_option.some(JsxRuntime.jsxs(ModalDialog, {
                        size: size$1,
                        variant: "outlined",
                        sx: {
                          width: "100%",
                          maxWidth: "30rem"
                        },
                        role: role$1,
                        children: [
                          JsxRuntime.jsxs(Grid, {
                                children: [
                                  JsxRuntime.jsx(Grid, {
                                        children: Caml_option.some(titleStartDecorator$1),
                                        xs: 2
                                      }),
                                  JsxRuntime.jsx(Grid, {
                                        children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                  level: "title-lg",
                                                  id: titleId$1,
                                                  children: Caml_option.some(title$1)
                                                })),
                                        sx: {
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center"
                                        },
                                        xs: 8
                                      }),
                                  JsxRuntime.jsx(Grid, {
                                        children: Caml_option.some(JsxRuntime.jsx(IconButton, {
                                                  onClick: onClose,
                                                  size: "sm",
                                                  children: Caml_option.some(JsxRuntime.jsx(Close, {}))
                                                })),
                                        sx: {
                                          display: "flex",
                                          justifyContent: "flex-end",
                                          alignItems: "center"
                                        },
                                        xs: 2
                                      })
                                ],
                                container: true,
                                sx: {
                                  flexGrow: 1
                                }
                              }),
                          JsxRuntime.jsx(Divider, {}),
                          JsxRuntime.jsx(Typography, {
                                level: "body-md",
                                textColor: "text.tertiary",
                                id: titleDescId$1,
                                children: Caml_option.some(description$1)
                              }),
                          props.children
                        ]
                      }))
            });
}

var make = Dialog;

exports.make = make;
/* @mui/joy/Grid Not a pure module */
