// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Txt$Thin = require("../../../text/Txt.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Stack = require("@mui/joy/Stack").default;
var Drawer = require("@mui/joy/Drawer").default;
var Divider = require("@mui/joy/Divider").default;
var JsxRuntime = require("react/jsx-runtime");
var AddPassMobile$Thin = require("./AddPassMobile.bs.js");
var ModalClose = require("@mui/joy/ModalClose").default;
var AddPassDesktop$Thin = require("./AddPassDesktop.bs.js");
var DialogTitle = require("@mui/joy/DialogTitle").default;

function AddPassDrawer(props) {
  var queryFrag = props.queryFrag;
  var areConnectedAccts = props.areConnectedAccts;
  var desktopAnchor = props.desktopAnchor;
  var onClose = props.onClose;
  var title = props.title;
  var open_ = props.open_;
  var title$1 = title !== undefined ? title : "Add Passenger";
  var desktopAnchor$1 = desktopAnchor !== undefined ? desktopAnchor : "right";
  var match = React.useState(function () {
        return "all";
      });
  var setTab = match[1];
  var tab = match[0];
  var match$1 = React.useState(function () {
        return "";
      });
  var setFirstName = match$1[1];
  var firstName = match$1[0];
  var match$2 = React.useState(function () {
        return "";
      });
  var setLastName = match$2[1];
  var lastName = match$2[0];
  var match$3 = React.useState(function () {
        return "";
      });
  var setEmail = match$3[1];
  var email = match$3[0];
  var match$4 = React.useState(function () {
        return "";
      });
  var setCell = match$4[1];
  var cell = match$4[0];
  var match$5 = React.useState(function () {
        return null;
      });
  var setGender = match$5[1];
  var gender = match$5[0];
  var match$6 = React.useState(function () {
        return null;
      });
  var setCountry = match$6[1];
  var country = match$6[0];
  var match$7 = React.useState(function () {
        return "";
      });
  var setDob = match$7[1];
  var dob = match$7[0];
  var match$8 = React.useState(function () {
        return "";
      });
  var setSecPhone = match$8[1];
  var secPhone = match$8[0];
  var match$9 = React.useState(function () {
        return null;
      });
  var setResidence = match$9[1];
  var residence = match$9[0];
  var match$10 = React.useState(function () {
        return "";
      });
  var setPostalCode = match$10[1];
  var postalCode = match$10[0];
  var match$11 = React.useState(function () {
        return "";
      });
  var setLoyalty = match$11[1];
  var loyalty = match$11[0];
  var match$12 = React.useState(function () {
        return null;
      });
  var setConnectedAcct = match$12[1];
  var connectedAcct = match$12[0];
  var getTitle = function (tab) {
    if (tab === "opt2") {
      return Txt$Thin.BookingDetails.invitePassenger.value;
    } else if (tab === "opt3") {
      return Txt$Thin.BookingDetails.selectFromConnectedAccounts.value;
    } else if (tab === "all") {
      return title$1;
    } else {
      return Txt$Thin.BookingDetails.providePassengerDetails.value;
    }
  };
  var getDescription = function (tab) {
    if (tab === "opt1") {
      return Txt$Thin.BookingDetails.providePassengerDetailsDesc.value;
    } else if (tab === "opt2") {
      return Txt$Thin.BookingDetails.invitePassengerDesc.value;
    } else if (tab === "opt3") {
      return Txt$Thin.BookingDetails.selectFromConnectedAccountsDesc.value;
    } else {
      return "";
    }
  };
  var clearAll = function (param) {
    Curry._1(setFirstName, (function (param) {
            return "";
          }));
    Curry._1(setLastName, (function (param) {
            return "";
          }));
    Curry._1(setEmail, (function (param) {
            return "";
          }));
    Curry._1(setCell, (function (param) {
            return "";
          }));
    Curry._1(setGender, (function (param) {
            return null;
          }));
    Curry._1(setCountry, (function (param) {
            return null;
          }));
    Curry._1(setDob, (function (param) {
            return "";
          }));
    Curry._1(setSecPhone, (function (param) {
            return "";
          }));
    Curry._1(setResidence, (function (param) {
            return null;
          }));
    Curry._1(setPostalCode, (function (param) {
            return "";
          }));
    Curry._1(setLoyalty, (function (param) {
            return "";
          }));
    Curry._1(setConnectedAcct, (function (param) {
            return null;
          }));
  };
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(Stack, {
                      sx: {
                        display: {
                          xs: "none",
                          md: "flex"
                        }
                      },
                      children: Caml_option.some(JsxRuntime.jsxs(Drawer, {
                                children: [
                                  JsxRuntime.jsx(ModalClose, {}),
                                  JsxRuntime.jsx(DialogTitle, {
                                        children: Caml_option.some(title$1)
                                      }),
                                  JsxRuntime.jsx(Divider, {}),
                                  JsxRuntime.jsx(AddPassDesktop$Thin.make, {
                                        tab: tab,
                                        setTab: setTab,
                                        getTitle: getTitle,
                                        getDescription: getDescription,
                                        clearAll: clearAll,
                                        onClose: (function (param) {
                                            clearAll(undefined);
                                            Curry._1(setTab, (function (param) {
                                                    return "all";
                                                  }));
                                            Curry._1(onClose, undefined);
                                          }),
                                        firstName: firstName,
                                        setFirstName: setFirstName,
                                        lastName: lastName,
                                        setLastName: setLastName,
                                        email: email,
                                        setEmail: setEmail,
                                        cell: cell,
                                        setCell: setCell,
                                        secPhone: secPhone,
                                        setSecPhone: setSecPhone,
                                        dob: dob,
                                        setDob: setDob,
                                        country: country,
                                        setCountry: setCountry,
                                        gender: gender,
                                        setGender: setGender,
                                        postalCode: postalCode,
                                        setPostalCode: setPostalCode,
                                        residence: residence,
                                        setResidence: setResidence,
                                        loyalty: loyalty,
                                        setLoyalty: setLoyalty,
                                        connectedAcct: connectedAcct,
                                        setConnectedAcct: setConnectedAcct,
                                        areConnectedAccts: areConnectedAccts,
                                        queryFrag: queryFrag
                                      })
                                ],
                                open: open_,
                                anchor: desktopAnchor$1,
                                disablePortal: true,
                                onClose: (function (param) {
                                    clearAll(undefined);
                                    Curry._1(setTab, (function (param) {
                                            return "all";
                                          }));
                                    Curry._1(onClose, undefined);
                                  }),
                                slotProps: {
                                  backdrop: {
                                    sx: {
                                      backdropFilter: "none",
                                      backgroundColor: "rgba(16, 13, 12, 0.7)"
                                    }
                                  },
                                  content: {
                                    sx: {
                                      "--Drawer-horizontalSize": "clamp(500px, 45%, 75%)",
                                      borderRadius: "4px",
                                      margin: "8px",
                                      height: "calc(100% - 16px)",
                                      overflow: "auto"
                                    }
                                  }
                                }
                              }))
                    }),
                JsxRuntime.jsx(Stack, {
                      sx: {
                        display: {
                          xs: "flex",
                          md: "none"
                        }
                      },
                      children: Caml_option.some(JsxRuntime.jsx(AddPassMobile$Thin.make, {
                                tab: tab,
                                setTab: setTab,
                                title: getTitle(tab),
                                open_: open_,
                                onClose: (function (param) {
                                    clearAll(undefined);
                                    Curry._1(setTab, (function (param) {
                                            return "all";
                                          }));
                                    Curry._1(onClose, undefined);
                                  }),
                                clearAll: clearAll,
                                getTitle: getTitle,
                                firstName: firstName,
                                setFirstName: setFirstName,
                                lastName: lastName,
                                setLastName: setLastName,
                                email: email,
                                setEmail: setEmail,
                                cell: cell,
                                setCell: setCell,
                                secPhone: secPhone,
                                setSecPhone: setSecPhone,
                                dob: dob,
                                setDob: setDob,
                                country: country,
                                setCountry: setCountry,
                                gender: gender,
                                setGender: setGender,
                                postalCode: postalCode,
                                setPostalCode: setPostalCode,
                                residence: residence,
                                setResidence: setResidence,
                                loyalty: loyalty,
                                setLoyalty: setLoyalty,
                                connectedAcct: connectedAcct,
                                setConnectedAcct: setConnectedAcct,
                                areConnectedAccts: areConnectedAccts,
                                queryFrag: queryFrag
                              }))
                    })
              ]
            });
}

var make = AddPassDrawer;

exports.make = make;
/* react Not a pure module */
