// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Txt$Thin = require("../text/Txt.bs.js");
var Js_string = require("rescript/lib/js/js_string.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Avatar$Thin = require("../components/common/atoms/Avatar.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var PageFrame$Thin = require("../components/wrappers/PageFrame.bs.js");
var Divider = require("@mui/joy/Divider").default;
var JsxRuntime = require("react/jsx-runtime");
var CustomScalars$Thin = require("../utils/CustomScalars.bs.js");
var Typography = require("@mui/joy/Typography").default;
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var PersonalInformation$Thin = require("../components/account/PersonalInformation.bs.js");
var AccountAccessSection$Thin = require("../components/account/AccountAccessSection.bs.js");
var AccountPage_query_graphql$Thin = require("../__generated__/AccountPage_query_graphql.bs.js");

var convertFragment = AccountPage_query_graphql$Thin.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(AccountPage_query_graphql$Thin.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, AccountPage_query_graphql$Thin.node, convertFragment);
}

var QueryFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

function AccountPage(props) {
  var data = RescriptRelay_Fragment.useFragment(AccountPage_query_graphql$Thin.node, convertFragment, props.fragmentRefs);
  var match = data.currentUserProfiles;
  var displayName;
  if (match !== undefined) {
    var profile = Belt_Array.getExn(match.nodes, 0);
    var firstName = profile.firstName;
    if (firstName !== undefined) {
      var lastName = profile.lastName;
      var firstName$1 = Caml_option.valFromOption(firstName);
      displayName = lastName !== undefined ? CustomScalars$Thin.NonemptyString.serialize(firstName$1) + " " + CustomScalars$Thin.NonemptyString.serialize(Caml_option.valFromOption(lastName)) : CustomScalars$Thin.NonemptyString.serialize(firstName$1);
    } else {
      var lastName$1 = profile.lastName;
      displayName = lastName$1 !== undefined ? CustomScalars$Thin.NonemptyString.serialize(Caml_option.valFromOption(lastName$1)) : "Sample Name";
    }
  } else {
    displayName = "Sample Name";
  }
  var initial = Js_string.charAt(0, displayName).toUpperCase();
  var match$1 = data.currentUserProfiles;
  var tmp;
  if (match$1 !== undefined) {
    var profile$1 = Belt_Array.getExn(match$1.nodes, 0);
    tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsx(AccountAccessSection$Thin.make, {
                  fragmentRefs: data.fragmentRefs,
                  userContext: props.userContext
                }),
            JsxRuntime.jsx(Divider, {}),
            JsxRuntime.jsx(PersonalInformation$Thin.make, {
                  fragmentRefs: profile$1.fragmentRefs,
                  countryFrag: data.fragmentRefs
                })
          ]
        });
  } else {
    tmp = JsxRuntime.jsx("div", {
          children: Txt$Thin.Account.noProfileFound.value
        });
  }
  return JsxRuntime.jsx(PageFrame$Thin.make, {
              children: JsxRuntime.jsx(Sheet, {
                    sx: {
                      backgroundColor: "background.surface",
                      borderRadius: "sm",
                      flex: 1,
                      width: "100%",
                      border: 1,
                      borderColor: "neutral.200"
                    },
                    children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                              direction: "column",
                              sx: {
                                py: 2,
                                px: 3
                              },
                              children: [
                                JsxRuntime.jsxs(Stack, {
                                      direction: "row",
                                      justifyContent: "space-between",
                                      sx: {
                                        mb: 2
                                      },
                                      children: [
                                        JsxRuntime.jsxs(Stack, {
                                              direction: "column",
                                              justifyContent: "center",
                                              alignItems: "flex-start",
                                              sx: {
                                                gap: 1,
                                                display: {
                                                  xs: "none",
                                                  md: "flex"
                                                }
                                              },
                                              children: [
                                                JsxRuntime.jsx(Typography, {
                                                      level: "h3",
                                                      component: "h1",
                                                      children: Caml_option.some(Txt$Thin.Account.heading.value)
                                                    }),
                                                JsxRuntime.jsx(Typography, {
                                                      level: "body-sm",
                                                      component: "p",
                                                      textColor: "text.tertiary",
                                                      children: Caml_option.some(Txt$Thin.Account.subheading.value)
                                                    })
                                              ]
                                            }),
                                        JsxRuntime.jsxs(Stack, {
                                              direction: "row",
                                              justifyContent: "flex=start",
                                              alignItems: "center",
                                              spacing: 2,
                                              children: [
                                                JsxRuntime.jsx(Stack, {
                                                      direction: "column",
                                                      children: Caml_option.some(JsxRuntime.jsx(Avatar$Thin.make, {
                                                                children: initial,
                                                                color: "primary",
                                                                size: "lg"
                                                              }))
                                                    }),
                                                JsxRuntime.jsx(Typography, {
                                                      level: "h3",
                                                      component: "h1",
                                                      sx: {
                                                        display: {
                                                          xs: "flex",
                                                          md: "none"
                                                        }
                                                      },
                                                      children: Caml_option.some(displayName)
                                                    })
                                              ]
                                            })
                                      ]
                                    }),
                                JsxRuntime.jsx(Divider, {}),
                                tmp
                              ]
                            }))
                  }),
              backgroundColor: "background.level1"
            });
}

var make = AccountPage;

exports.QueryFragment = QueryFragment;
exports.make = make;
/* Txt-Thin Not a pure module */
