// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Button$Thin = require("../../common/atoms/Button.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thin = require("../../../utils/Common.bs.js");
var List = require("@mui/joy/List").default;
var Stack = require("@mui/joy/Stack").default;
var Drawer = require("@mui/joy/Drawer").default;
var InvitePass$Thin = require("./InvitePass.bs.js");
var Divider = require("@mui/joy/Divider").default;
var ListItem = require("@mui/joy/ListItem").default;
var JsxRuntime = require("react/jsx-runtime");
var IconButton = require("@mui/joy/IconButton").default;
var ModalClose = require("@mui/joy/ModalClose").default;
var AddPassDetails$Thin = require("./AddPassDetails.bs.js");
var DialogTitle = require("@mui/joy/DialogTitle").default;
var DialogContent = require("@mui/joy/DialogContent").default;
var ListItemButton = require("@mui/joy/ListItemButton").default;
var ListItemContent = require("@mui/joy/ListItemContent").default;
var AddConnectedAccount$Thin = require("./AddConnectedAccount.bs.js");
var ListItemDecorator = require("@mui/joy/ListItemDecorator").default;
var SendOutlined = require("@mui/icons-material/SendOutlined").default;
var ArrowBackIosNew = require("@mui/icons-material/ArrowBackIosNew").default;
var ArrowForwardIos = require("@mui/icons-material/ArrowForwardIos").default;
var ModeEditOutlined = require("@mui/icons-material/ModeEditOutlined").default;
var ContentCopyOutlined = require("@mui/icons-material/ContentCopyOutlined").default;
var SupervisorAccountOutlined = require("@mui/icons-material/SupervisorAccountOutlined").default;

function AddPassMobile(props) {
  var queryFrag = props.queryFrag;
  var areConnectedAccts = props.areConnectedAccts;
  var connectedAcct = props.connectedAcct;
  var residence = props.residence;
  var postalCode = props.postalCode;
  var gender = props.gender;
  var country = props.country;
  var dob = props.dob;
  var cell = props.cell;
  var setEmail = props.setEmail;
  var email = props.email;
  var lastName = props.lastName;
  var firstName = props.firstName;
  var getTitle = props.getTitle;
  var clearAll = props.clearAll;
  var setTab = props.setTab;
  var tab = props.tab;
  var getMenuContent = function (param) {
    return JsxRuntime.jsxs(List, {
                children: [
                  JsxRuntime.jsx(ListItem, {
                        children: Caml_option.some(JsxRuntime.jsxs(ListItemButton, {
                                  children: [
                                    JsxRuntime.jsx(ListItemDecorator, {
                                          children: Caml_option.some(JsxRuntime.jsx(ModeEditOutlined, {
                                                    fontSize: "md"
                                                  })),
                                          sx: {
                                            "--ListItemDecorator-size": "1.75rem"
                                          }
                                        }),
                                    JsxRuntime.jsx(ListItemContent, {
                                          children: Caml_option.some(Curry._1(getTitle, "opt1"))
                                        }),
                                    JsxRuntime.jsx(ArrowForwardIos, {
                                          fontSize: "md"
                                        })
                                  ],
                                  onClick: (function (param) {
                                      Curry._1(setTab, (function (param) {
                                              return "opt1";
                                            }));
                                      Curry._1(clearAll, undefined);
                                    })
                                }))
                      }),
                  JsxRuntime.jsx(ListItem, {
                        children: Caml_option.some(JsxRuntime.jsxs(ListItemButton, {
                                  children: [
                                    JsxRuntime.jsx(ListItemDecorator, {
                                          children: Caml_option.some(JsxRuntime.jsx(SendOutlined, {
                                                    fontSize: "md"
                                                  })),
                                          sx: {
                                            "--ListItemDecorator-size": "1.75rem"
                                          }
                                        }),
                                    JsxRuntime.jsx(ListItemContent, {
                                          children: Caml_option.some(Curry._1(getTitle, "opt2"))
                                        }),
                                    JsxRuntime.jsx(ArrowForwardIos, {
                                          fontSize: "md"
                                        })
                                  ],
                                  onClick: (function (param) {
                                      Curry._1(setTab, (function (param) {
                                              return "opt2";
                                            }));
                                      Curry._1(clearAll, undefined);
                                    })
                                }))
                      }),
                  areConnectedAccts ? JsxRuntime.jsx(ListItem, {
                          children: Caml_option.some(JsxRuntime.jsxs(ListItemButton, {
                                    children: [
                                      JsxRuntime.jsx(ListItemDecorator, {
                                            children: Caml_option.some(JsxRuntime.jsx(SupervisorAccountOutlined, {
                                                      fontSize: "md"
                                                    })),
                                            sx: {
                                              "--ListItemDecorator-size": "1.75rem"
                                            }
                                          }),
                                      JsxRuntime.jsx(ListItemContent, {
                                            children: Caml_option.some(Curry._1(getTitle, "opt3"))
                                          }),
                                      JsxRuntime.jsx(ArrowForwardIos, {
                                            fontSize: "md"
                                          })
                                    ],
                                    onClick: (function (param) {
                                        Curry._1(setTab, (function (param) {
                                                return "opt3";
                                              }));
                                        Curry._1(clearAll, undefined);
                                      })
                                  }))
                        }) : null
                ],
                sx: {
                  p: 1
                }
              });
  };
  var opt1Tab = JsxRuntime.jsx(AddPassDetails$Thin.make, {
        firstName: firstName,
        setFirstName: props.setFirstName,
        lastName: lastName,
        setLastName: props.setLastName,
        email: email,
        setEmail: setEmail,
        cell: cell,
        setCell: props.setCell,
        secPhone: props.secPhone,
        setSecPhone: props.setSecPhone,
        dob: dob,
        setDob: props.setDob,
        country: country,
        setCountry: props.setCountry,
        gender: gender,
        setGender: props.setGender,
        postalCode: postalCode,
        setPostalCode: props.setPostalCode,
        residence: residence,
        setResidence: props.setResidence,
        loyalty: props.loyalty,
        setLoyalty: props.setLoyalty,
        countryFrag: queryFrag,
        mobile: true
      });
  var opt2Tab = JsxRuntime.jsx(InvitePass$Thin.make, {
        email: email,
        setEmail: setEmail
      });
  var opt3Tab = JsxRuntime.jsx(AddConnectedAccount$Thin.make, {
        connectedAcctFrag: queryFrag,
        connectedAcct: connectedAcct,
        setConnectedAcct: props.setConnectedAcct,
        mobile: true
      });
  var getContent = function (tab) {
    if (tab === "opt2") {
      return opt2Tab;
    } else if (tab === "opt3") {
      return opt3Tab;
    } else if (tab === "all") {
      return null;
    } else {
      return opt1Tab;
    }
  };
  var transformStyle = tab === "all" ? "translateX(0%)" : "translateX(-50%)";
  return JsxRuntime.jsxs(Drawer, {
              children: [
                JsxRuntime.jsx(ModalClose, {}),
                JsxRuntime.jsx(DialogTitle, {
                      children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                direction: "row",
                                alignItems: "center",
                                spacing: 1,
                                children: [
                                  tab === "all" ? null : JsxRuntime.jsx(IconButton, {
                                          onClick: (function (param) {
                                              Curry._1(setTab, (function (param) {
                                                      return "all";
                                                    }));
                                            }),
                                          children: Caml_option.some(JsxRuntime.jsx(ArrowBackIosNew, {
                                                    fontSize: "sm"
                                                  }))
                                        }),
                                  JsxRuntime.jsx(Stack, {
                                        children: Caml_option.some(props.title)
                                      })
                                ]
                              })),
                      level: "title-md"
                    }),
                JsxRuntime.jsx(Divider, {}),
                JsxRuntime.jsx(DialogContent, {
                      children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                direction: "row",
                                sx: {
                                  width: "200%",
                                  transition: "transform " + (
                                    tab !== "all" ? "0.3s" : "0.2s"
                                  ) + " ease-in-out",
                                  transform: transformStyle
                                },
                                children: [
                                  JsxRuntime.jsx(Stack, {
                                        sx: {
                                          width: "100%"
                                        },
                                        children: Caml_option.some(getMenuContent(undefined))
                                      }),
                                  JsxRuntime.jsx(Stack, {
                                        sx: {
                                          width: "100%"
                                        },
                                        children: Caml_option.some(getContent(tab))
                                      })
                                ]
                              })),
                      sx: {
                        overflowX: "hidden"
                      }
                    }),
                JsxRuntime.jsx(Stack, {
                      direction: "row",
                      justifyContent: "center",
                      sx: {
                        display: "flex",
                        gap: 1,
                        p: 1.5,
                        pb: 2,
                        borderTop: "1px solid",
                        borderColor: "divider",
                        display: tab === "all" ? "none" : "block"
                      },
                      children: Caml_option.some(JsxRuntime.jsx(Button$Thin.make, {
                                fullWidth: true,
                                type_: "submit",
                                onClick: (function (param) {
                                    
                                  }),
                                startDecorator: Caml_option.some(tab === "opt2" ? JsxRuntime.jsx(ContentCopyOutlined, {
                                            fontSize: "lg"
                                          }) : null),
                                variant: tab === "opt2" ? "text" : "solid",
                                size: "lg",
                                disabled: tab === "opt2" ? email === "" || !Common$Thin.Email.isValid(email) || true : (
                                    tab === "opt3" ? (
                                        (connectedAcct == null) ? undefined : Caml_option.some(connectedAcct)
                                      ) === undefined || true : (
                                        tab === "all" ? true : firstName === "" || lastName === "" || email === "" || !Common$Thin.Email.isValid(email) || cell === "" || (
                                            (gender == null) ? undefined : Caml_option.some(gender)
                                          ) === undefined || dob === "" || (
                                            (country == null) ? undefined : Caml_option.some(country)
                                          ) === undefined || (
                                            (residence == null) ? undefined : Caml_option.some(residence)
                                          ) === undefined || postalCode === "" || true
                                      )
                                  ),
                                children: Caml_option.some(tab === "opt2" ? "Copy Link" : "Add Passenger")
                              }))
                    })
              ],
              open: props.open_,
              anchor: "bottom",
              disablePortal: true,
              onClose: props.onClose,
              size: "md",
              slotProps: {
                backdrop: {
                  sx: {
                    backdropFilter: "none",
                    backgroundColor: "rgba(16, 13, 12, 0.7)"
                  }
                },
                content: {
                  sx: {
                    "--Drawer-verticalSize": "clamp(fit-content, 50%, 50%)",
                    maxHeight: "60%",
                    transition: "height 0.3s ease-in-out",
                    borderTopRightRadius: "4px",
                    borderTopLeftRadius: "4px",
                    ml: "8px",
                    mr: "8px",
                    width: "calc(100% - 16px)",
                    overflowX: "hidden"
                  }
                }
              }
            });
}

var make = AddPassMobile;

exports.make = make;
/* Button-Thin Not a pure module */
