// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Txt$Thin = require("../../text/Txt.bs.js");
var Date$Util = require("util/src/Date.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var SysCtx$Thin = require("../../utils/SysCtx.bs.js");
var Chip = require("@mui/joy/Chip").default;
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var JsxRuntime = require("react/jsx-runtime");
var CustomScalars$Thin = require("../../utils/CustomScalars.bs.js");
var Typography = require("@mui/joy/Typography").default;
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var AspectRatio = require("@mui/joy/AspectRatio").default;
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var Cancel = require("@mui/icons-material/Cancel").default;
var $$History = require("@mui/icons-material/History").default;
var ModeEdit = require("@mui/icons-material/ModeEdit").default;
var Person2Outlined = require("@mui/icons-material/Person2Outlined").default;
var SailingOutlined = require("@mui/icons-material/SailingOutlined").default;
var BookingHistorySection_query_graphql$Thin = require("../../__generated__/BookingHistorySection_query_graphql.bs.js");
var BookingHistorySection_listitem_graphql$Thin = require("../../__generated__/BookingHistorySection_listitem_graphql.bs.js");

var convertFragment = BookingHistorySection_query_graphql$Thin.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(BookingHistorySection_query_graphql$Thin.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BookingHistorySection_query_graphql$Thin.node, convertFragment);
}

var QueryFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

var convertFragment$1 = BookingHistorySection_listitem_graphql$Thin.Internal.convertFragment;

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(BookingHistorySection_listitem_graphql$Thin.node, convertFragment$1, fRef);
}

function useOpt$1(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BookingHistorySection_listitem_graphql$Thin.node, convertFragment$1);
}

var ListitemFragment_lifecycleStatus_decode = BookingHistorySection_listitem_graphql$Thin.Utils.lifecycleStatus_decode;

var ListitemFragment_lifecycleStatus_fromString = BookingHistorySection_listitem_graphql$Thin.Utils.lifecycleStatus_fromString;

var ListitemFragment = {
  lifecycleStatus_decode: ListitemFragment_lifecycleStatus_decode,
  lifecycleStatus_fromString: ListitemFragment_lifecycleStatus_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$1,
  use: use$1,
  useOpt: useOpt$1
};

function BookingHistorySection$HistoricalVoyage(props) {
  var query = RescriptRelay_Fragment.useFragment(BookingHistorySection_listitem_graphql$Thin.node, convertFragment$1, props.fragmentRefs);
  var voyageSlug = CustomScalars$Thin.VoyageSlug.serialize(query.voyageSlug);
  var bookingId = query.bookingId;
  var numBerths = query.numBerths;
  var match = query.lifecycleStatus;
  var lifecycleChip = match === "ACTIVE" ? JsxRuntime.jsx(Chip, {
          children: Caml_option.some(JsxRuntime.jsx(Typography, {
                    level: "body-xs",
                    textColor: "inherit",
                    children: "Active"
                  })),
          color: "success",
          size: "md",
          startDecorator: Caml_option.some(JsxRuntime.jsx(SailingOutlined, {})),
          sx: {
            borderRadius: "xs"
          }
        }) : (
      match === "DRAFT" ? JsxRuntime.jsx(Chip, {
              children: Caml_option.some(JsxRuntime.jsx(Typography, {
                        level: "body-xs",
                        textColor: "inherit",
                        children: "Draft"
                      })),
              color: "neutral",
              size: "md",
              startDecorator: Caml_option.some(JsxRuntime.jsx(ModeEdit, {})),
              sx: {
                borderRadius: "xs"
              }
            }) : (
          match === "HISTORY" ? JsxRuntime.jsx(Chip, {
                  children: Caml_option.some(JsxRuntime.jsx(Typography, {
                            level: "body-xs",
                            textColor: "inherit",
                            children: "History"
                          })),
                  color: "neutral",
                  size: "md",
                  startDecorator: Caml_option.some(JsxRuntime.jsx($$History, {})),
                  sx: {
                    borderRadius: "xs"
                  }
                }) : (
              match === "CANCELED" ? JsxRuntime.jsx(Chip, {
                      children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                level: "body-xs",
                                textColor: "inherit",
                                children: "Canceled"
                              })),
                      color: "danger",
                      size: "md",
                      startDecorator: Caml_option.some(JsxRuntime.jsx(Cancel, {})),
                      sx: {
                        borderRadius: "xs"
                      }
                    }) : null
            )
        )
    );
  var headline = bookingId !== undefined ? JsxRuntime.jsx(Typography, {
          level: "h4",
          component: "h4",
          children: Caml_option.some("" + Caml_option.valFromOption(bookingId) + "")
        }) : JsxRuntime.jsx(Typography, {
          level: "h4",
          component: "h4",
          children: Caml_option.some("" + voyageSlug + "")
        });
  return JsxRuntime.jsx(Sheet, {
              onClick: (function (param) {
                  if (bookingId !== undefined) {
                    return RescriptReactRouter.push(SysCtx$Thin.vars.bookingsLink + "/" + Caml_option.valFromOption(bookingId));
                  }
                  
                }),
              sx: {
                backgroundColor: "background.surface",
                alignSelf: {
                  xs: "center",
                  md: "flex-start"
                },
                borderRadius: "md",
                border: 1,
                borderColor: "neutral.200",
                p: 2,
                width: "100%",
                boxSizing: "border-box",
                "&:hover": {
                  boxShadow: bookingId !== undefined ? "md" : "sm",
                  cursor: bookingId !== undefined ? "pointer" : "default"
                }
              },
              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                        direction: "row",
                        spacing: {
                          xs: 2,
                          md: 3
                        },
                        children: [
                          JsxRuntime.jsxs(AspectRatio, {
                                children: [
                                  JsxRuntime.jsx("img", {
                                        draggable: false,
                                        alt: voyageSlug,
                                        loading: "lazy",
                                        src: "https://i.ibb.co/6WW5vVC/shiptest.jpg"
                                      }),
                                  JsxRuntime.jsx(Stack, {
                                        justifyContent: "center",
                                        alignItems: "center",
                                        sx: {
                                          position: "absolute",
                                          top: 0,
                                          left: 0,
                                          width: "100%",
                                          height: "100%",
                                          zIndex: 1,
                                          backgroundColor: "rgba(0,0,0,0.4)"
                                        },
                                        children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                  level: "h3",
                                                  component: "h3",
                                                  sx: {
                                                    color: "common.white",
                                                    fontWeight: "bold",
                                                    zIndex: 2
                                                  },
                                                  children: Caml_option.some(voyageSlug)
                                                }))
                                      })
                                ],
                                ratio: "1.5",
                                sx: {
                                  borderRadius: "sm",
                                  width: {
                                    xs: "16rem",
                                    md: "10rem"
                                  },
                                  height: "100%",
                                  position: "relative"
                                }
                              }),
                          JsxRuntime.jsxs(Stack, {
                                sx: {
                                  width: "100%",
                                  justifyContent: {
                                    xs: "flex-start",
                                    md: "space-between"
                                  },
                                  flexDirection: {
                                    xs: "column",
                                    md: "row"
                                  }
                                },
                                children: [
                                  JsxRuntime.jsxs(Stack, {
                                        direction: "column",
                                        spacing: {
                                          xs: 0,
                                          md: 1
                                        },
                                        children: [
                                          JsxRuntime.jsx(Typography, {
                                                level: "body-xs",
                                                sx: {
                                                  display: {
                                                    xs: "flex",
                                                    md: "none"
                                                  }
                                                },
                                                textColor: "text.tertiary",
                                                children: Caml_option.some(Date$Util.prettyFormatDateRange(query.voyStartDate, query.voyEndDate))
                                              }),
                                          headline,
                                          JsxRuntime.jsx(Chip, {
                                                children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                          level: "body-xs",
                                                          fontWeight: "sm",
                                                          children: Caml_option.some("" + String(numBerths) + " Guest" + (
                                                                numBerths === 1 ? "" : "s"
                                                              ) + "")
                                                        })),
                                                size: "sm",
                                                startDecorator: Caml_option.some(JsxRuntime.jsx(Person2Outlined, {})),
                                                sx: {
                                                  backgroundColor: "neutral.100",
                                                  color: "text.primary",
                                                  borderRadius: "xs"
                                                }
                                              })
                                        ]
                                      }),
                                  JsxRuntime.jsxs(Stack, {
                                        direction: "column",
                                        spacing: 1,
                                        sx: {
                                          alignItems: {
                                            xs: "flex-start",
                                            md: "flex-end"
                                          }
                                        },
                                        children: [
                                          JsxRuntime.jsx(Typography, {
                                                level: "body-xs",
                                                sx: {
                                                  display: {
                                                    xs: "none",
                                                    md: "flex"
                                                  }
                                                },
                                                textColor: "text.tertiary",
                                                children: Caml_option.some(Date$Util.prettyFormatDateRange(query.voyStartDate, query.voyEndDate))
                                              }),
                                          lifecycleChip
                                        ]
                                      })
                                ]
                              })
                        ]
                      }))
            });
}

var HistoricalVoyage = {
  make: BookingHistorySection$HistoricalVoyage
};

function BookingHistorySection(props) {
  var query = RescriptRelay_Fragment.useFragment(BookingHistorySection_query_graphql$Thin.node, convertFragment, props.fragmentRefs);
  var match = query.history;
  var tmp;
  if (match !== undefined) {
    var nodes = match.nodes;
    tmp = nodes.length !== 0 ? JsxRuntime.jsxs(Stack, {
            spacing: 1,
            sx: {
              width: "100%"
            },
            children: [
              JsxRuntime.jsx(Typography, {
                    level: "h3",
                    component: "h1",
                    children: Caml_option.some(Txt$Thin.Bookings.pastHeading.value)
                  }),
              Belt_Array.map(nodes, (function (node) {
                      return JsxRuntime.jsx(BookingHistorySection$HistoricalVoyage, {
                                  fragmentRefs: node.fragmentRefs
                                }, node.bookingId);
                    }))
            ]
          }) : null;
  } else {
    tmp = null;
  }
  return JsxRuntime.jsx(JsxRuntime.Fragment, {
              children: Caml_option.some(tmp)
            });
}

var make = BookingHistorySection;

exports.QueryFragment = QueryFragment;
exports.ListitemFragment = ListitemFragment;
exports.HistoricalVoyage = HistoricalVoyage;
exports.make = make;
/* Txt-Thin Not a pure module */
