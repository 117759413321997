// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Txt$Thin = require("../../text/Txt.bs.js");
var Alert$Thin = require("../common/atoms/Alert.bs.js");
var Merge$Util = require("util/src/Merge.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Grid = require("@mui/joy/Grid").default;
var Stack = require("@mui/joy/Stack").default;
var TextInput$Thin = require("../common/molecules/TextInput.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var ChangeEmailForm$Thin = require("./ChangeEmailForm.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var ChangePasswordForm$Thin = require("./ChangePasswordForm.bs.js");
var AccountAccessSectionQuery_graphql$Thin = require("../../__generated__/AccountAccessSectionQuery_graphql.bs.js");
var AccountAccessSection_query_graphql$Thin = require("../../__generated__/AccountAccessSection_query_graphql.bs.js");

var convertFragment = AccountAccessSection_query_graphql$Thin.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(AccountAccessSection_query_graphql$Thin.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, AccountAccessSection_query_graphql$Thin.node, convertFragment);
}

var makeRefetchVariables = AccountAccessSectionQuery_graphql$Thin.Types.makeRefetchVariables;

var convertRefetchVariables = AccountAccessSectionQuery_graphql$Thin.Internal.convertVariables;

function useRefetchable(fRef) {
  return RescriptRelay_Fragment.useRefetchableFragment(AccountAccessSection_query_graphql$Thin.node, convertFragment, convertRefetchVariables, fRef);
}

var QueryFragment_custSignonStatus_decode = AccountAccessSection_query_graphql$Thin.Utils.custSignonStatus_decode;

var QueryFragment_custSignonStatus_fromString = AccountAccessSection_query_graphql$Thin.Utils.custSignonStatus_fromString;

var QueryFragment = {
  custSignonStatus_decode: QueryFragment_custSignonStatus_decode,
  custSignonStatus_fromString: QueryFragment_custSignonStatus_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  makeRefetchVariables: makeRefetchVariables,
  convertRefetchVariables: convertRefetchVariables,
  useRefetchable: useRefetchable
};

function AccountAccessSection(props) {
  var userContext = props.userContext;
  var match = useRefetchable(props.fragmentRefs);
  var refetch = match[1];
  var data = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setEmailFormOpen = match$1[1];
  var match$2 = React.useState(function () {
        return false;
      });
  var setPasswordFormOpen = match$2[1];
  var refresh = function (param) {
    return Curry._4(refetch, Curry._1(makeRefetchVariables, undefined), /* StoreAndNetwork */2, undefined, undefined);
  };
  var match$3 = data.currentUserSignonStatus;
  var emailHelperModule = match$3 === "VERIFY_EMAIL" ? JsxRuntime.jsx(Alert$Thin.make, {
          message: Merge$Util.render(Txt$Thin.Account.emailVerify, userContext),
          type_: "warning",
          size: "sm"
        }) : JsxRuntime.jsx(Typography, {
          level: "body-sm",
          sx: {
            textDecoration: "underline",
            "&:hover": {
              cursor: "pointer"
            }
          },
          children: Caml_option.some(Txt$Thin.Account.changeEmail.value),
          onClick: (function (param) {
              Curry._1(setEmailFormOpen, (function (param) {
                      return true;
                    }));
            })
        });
  var email = data.currentUserEmail;
  return JsxRuntime.jsxs(Stack, {
              direction: "column",
              sx: {
                width: "100%",
                py: 2
              },
              children: [
                JsxRuntime.jsx(Typography, {
                      level: "h4",
                      component: "h2",
                      sx: {
                        mb: 2
                      },
                      children: Caml_option.some(Txt$Thin.Account.accessHeading.value)
                    }),
                JsxRuntime.jsx(Grid, {
                      children: Caml_option.some(email !== undefined ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                  children: [
                                    JsxRuntime.jsx(Grid, {
                                          children: Caml_option.some(JsxRuntime.jsx(TextInput$Thin.make, {
                                                    type_: "email",
                                                    fullWidth: true,
                                                    disabled: true,
                                                    value: email,
                                                    label: Txt$Thin.Account.emailLabel.value,
                                                    helperModule: Caml_option.some(emailHelperModule)
                                                  })),
                                          xs: 12,
                                          md: 6
                                        }),
                                    JsxRuntime.jsx(Grid, {
                                          children: Caml_option.some(JsxRuntime.jsx(TextInput$Thin.make, {
                                                    type_: "password",
                                                    fullWidth: true,
                                                    disabled: true,
                                                    value: "*********",
                                                    label: Txt$Thin.Account.passwordLabel.value,
                                                    helperModule: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                              level: "body-sm",
                                                              sx: {
                                                                textDecoration: "underline",
                                                                "&:hover": {
                                                                  cursor: "pointer"
                                                                }
                                                              },
                                                              children: Caml_option.some(Txt$Thin.Account.changePassword.value),
                                                              onClick: (function (param) {
                                                                  Curry._1(setPasswordFormOpen, (function (param) {
                                                                          return true;
                                                                        }));
                                                                })
                                                            }))
                                                  })),
                                          xs: 12,
                                          md: 6
                                        })
                                  ]
                                }) : JsxRuntime.jsx("div", {
                                  children: Txt$Thin.Account.noEmail.value
                                })),
                      container: true,
                      spacing: 1,
                      sx: {
                        width: "100%"
                      }
                    }),
                JsxRuntime.jsx(ChangeEmailForm$Thin.make, {
                      open_: match$1[0],
                      onClose: (function (param) {
                          Curry._1(setEmailFormOpen, (function (param) {
                                  return false;
                                }));
                        }),
                      refresh: refresh,
                      userContext: userContext
                    }),
                JsxRuntime.jsx(ChangePasswordForm$Thin.make, {
                      open_: match$2[0],
                      onClose: (function (param) {
                          Curry._1(setPasswordFormOpen, (function (param) {
                                  return false;
                                }));
                        })
                    })
              ]
            });
}

var make = AccountAccessSection;

exports.QueryFragment = QueryFragment;
exports.make = make;
/* react Not a pure module */
