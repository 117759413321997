// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Id = require("rescript/lib/js/belt_Id.js");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Txt$Thin = require("../text/Txt.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var SysCtx$Thin = require("./SysCtx.bs.js");

function useStateExtended(useStateArgs) {
  var match = React.useState(function () {
        return Curry._1(useStateArgs, undefined);
      });
  var set = match[1];
  var onChange = function (e) {
    Curry._1(set, e.currentTarget.value);
  };
  return [
          match[0],
          set,
          onChange
        ];
}

function formatCurrency($$float) {
  var options = {
    style: "currency",
    currency: SysCtx$Thin.vars.currency
  };
  var formatter = Intl.NumberFormat(SysCtx$Thin.vars.locale, options);
  return formatter.format($$float);
}

var Clipboard = {};

var $$History = {};

var $$Window = {
  Clipboard: Clipboard,
  $$History: $$History
};

async function copyToClipboard(s, fn) {
  try {
    await window.navigator.clipboard.writeText(s);
    Curry._1(fn, (function (param) {
            return true;
          }));
    setTimeout((function (param) {
            Curry._1(fn, (function (param) {
                    return false;
                  }));
          }), 1500);
    return ;
  }
  catch (exn){
    console.log(Txt$Thin.Clipboard.copyError.value);
    return ;
  }
}

var regex = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$");

function isValid(email) {
  var match = regex.exec(email);
  return match !== null;
}

var Email = {
  regex: regex,
  isValid: isValid
};

function toString(status) {
  if (status === "COMPLETED") {
    return "Completed";
  } else if (status === "ERROR") {
    return "Error";
  } else if (status === "CANCELLED") {
    return "Canceled";
  } else if (status === "PENDING") {
    return "Pending";
  } else {
    return "Unknown";
  }
}

function shouldRenderStatus(status) {
  return status !== "COMPLETED";
}

var PmtLifecycle = {
  toString: toString,
  shouldRenderStatus: shouldRenderStatus
};

var Voyage = {};

function set(key, val) {
  localStorage.setItem(key, val);
}

function get(key) {
  return Caml_option.null_to_opt(localStorage.getItem(key));
}

function $$delete(key) {
  localStorage.removeItem(key);
}

var LS = {
  set: set,
  get: get,
  $$delete: $$delete
};

var cmp = Caml_obj.compare;

var CabinCmp = Belt_Id.MakeComparable({
      cmp: cmp
    });

var cmp$1 = Caml_obj.compare;

var VoyageSlugCmp = Belt_Id.MakeComparable({
      cmp: cmp$1
    });

var cmp$2 = Caml_obj.compare;

var StrCmp = Belt_Id.MakeComparable({
      cmp: cmp$2
    });

function toString$1(g) {
  if (g === "M") {
    return "Male";
  } else if (g === "X") {
    return "Unspecified";
  } else {
    return "Female";
  }
}

var Gender = {
  toString: toString$1
};

exports.useStateExtended = useStateExtended;
exports.formatCurrency = formatCurrency;
exports.$$Window = $$Window;
exports.copyToClipboard = copyToClipboard;
exports.Email = Email;
exports.PmtLifecycle = PmtLifecycle;
exports.Voyage = Voyage;
exports.LS = LS;
exports.CabinCmp = CabinCmp;
exports.VoyageSlugCmp = VoyageSlugCmp;
exports.StrCmp = StrCmp;
exports.Gender = Gender;
/* regex Not a pure module */
