// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Txt$Thin = require("../../text/Txt.bs.js");
var Date$Util = require("util/src/Date.bs.js");
var Link$Thin = require("../common/atoms/Link.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Card = require("@mui/joy/Card").default;
var Chip = require("@mui/joy/Chip").default;
var Grid = require("@mui/joy/Grid").default;
var Stack = require("@mui/joy/Stack").default;
var JsxRuntime = require("react/jsx-runtime");
var CustomScalars$Thin = require("../../utils/CustomScalars.bs.js");
var Typography = require("@mui/joy/Typography").default;
var CardContent = require("@mui/joy/CardContent").default;
var CardOverflow = require("@mui/joy/CardOverflow").default;
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var BDHero_listitem_graphql$Thin = require("../../__generated__/BDHero_listitem_graphql.bs.js");
var OpenInNew = require("@mui/icons-material/OpenInNew").default;
var ChevronRight = require("@mui/icons-material/ChevronRight").default;

var convertFragment = BDHero_listitem_graphql$Thin.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(BDHero_listitem_graphql$Thin.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BDHero_listitem_graphql$Thin.node, convertFragment);
}

var ListitemFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

function BDHero(props) {
  var data = RescriptRelay_Fragment.useFragment(BDHero_listitem_graphql$Thin.node, convertFragment, props.fragmentRefs);
  var match = React.useState(function () {
        return false;
      });
  var setViewMore = match[1];
  var viewMore = match[0];
  var voyageSlug = CustomScalars$Thin.VoyageSlug.serialize(data.voyageSlug);
  var bookingId = CustomScalars$Thin.BookingId.serialize(data.bookingId);
  var links = [
    [
      "Route Map",
      "https://www.roamingaroundtheworld.com/what-is-a-repositioning-cruise-and-where-do-they-go/",
      "Route map is nice isn't it?"
    ],
    [
      "Restaurants",
      "https://www.curbed.com/article/best-cake-shops-nyc.html",
      "Check out these restaurants."
    ],
    [
      "Shore Excursions",
      "https://www.shoreexcursionsgroup.com/",
      "Excurse away."
    ],
    [
      "Diet Pepsi",
      "https://www.pepsi.com/en-us/#!products/diet-pepsi",
      "It's not Diet Coke."
    ],
    [
      "Techno-Optimist Manifesto",
      "https://a16z.com/the-techno-optimist-manifesto/",
      "Totally deranged."
    ],
    [
      "Twitter",
      "https://twitter.com/home",
      "Sorry I meant 'X'..."
    ]
  ];
  var heroImage = "https://i.ibb.co/MMdLpX4/cruisetest2.jpg";
  var displayLinks = React.useMemo((function () {
          if (viewMore) {
            return links;
          } else {
            return Belt_Array.slice(links, 0, 3);
          }
        }), [viewMore]);
  return JsxRuntime.jsx(Stack, {
              direction: "row",
              sx: {
                width: "100%"
              },
              children: Caml_option.some(JsxRuntime.jsxs(Card, {
                        children: [
                          JsxRuntime.jsxs(CardOverflow, {
                                children: [
                                  JsxRuntime.jsxs(Stack, {
                                        direction: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        sx: {
                                          py: 3,
                                          px: 2,
                                          height: "14rem"
                                        },
                                        children: [
                                          JsxRuntime.jsx(Typography, {
                                                level: "h1",
                                                textColor: "common.white",
                                                children: Caml_option.some(voyageSlug)
                                              }),
                                          JsxRuntime.jsx(Typography, {
                                                level: "body-sm",
                                                textColor: "neutral.100",
                                                children: Caml_option.some(Date$Util.prettyFormatDateRange(data.voyStartDate, data.voyEndDate))
                                              })
                                        ]
                                      }),
                                  JsxRuntime.jsx(Chip, {
                                        children: Caml_option.some(bookingId),
                                        size: "md",
                                        variant: "outlined",
                                        sx: {
                                          position: "absolute",
                                          top: {
                                            xs: "10px",
                                            md: "16px"
                                          },
                                          left: {
                                            xs: "10px",
                                            md: "16px"
                                          },
                                          borderRadius: "xs",
                                          zIndex: 5,
                                          backgroundColor: "rgba(255, 255, 255, 0.8)"
                                        }
                                      })
                                ],
                                sx: {
                                  borderRadius: links.length !== 0 ? "" : "md",
                                  backgroundImage: links.length !== 0 ? "linear-gradient(\n                rgba(0, 0, 0, 0.4), \n                rgba(0, 0, 0, 0.4)\n                ), url(" + heroImage + ")" : "",
                                  backgroundSize: "cover",
                                  backgroundPosition: "center",
                                  position: "relative"
                                }
                              }),
                          links.length !== 0 ? JsxRuntime.jsx(CardContent, {
                                  children: Caml_option.some(JsxRuntime.jsxs(Grid, {
                                            children: [
                                              Belt_Array.mapWithIndex(displayLinks, (function (i, param) {
                                                      return JsxRuntime.jsxs(Grid, {
                                                                  children: [
                                                                    JsxRuntime.jsx(Link$Thin.make, {
                                                                          to: param[1],
                                                                          textColor: "neutral.800",
                                                                          children: JsxRuntime.jsx(Stack, {
                                                                                direction: "row",
                                                                                justifyContent: "flex-start",
                                                                                alignItems: "center",
                                                                                spacing: 1,
                                                                                children: Caml_option.some(JsxRuntime.jsxs(Typography, {
                                                                                          level: "body-md",
                                                                                          noWrap: false,
                                                                                          sx: {
                                                                                            display: "inline"
                                                                                          },
                                                                                          textColor: "inherit",
                                                                                          children: [
                                                                                            param[0],
                                                                                            JsxRuntime.jsx(OpenInNew, {
                                                                                                  fontSize: "inherit",
                                                                                                  sx: {
                                                                                                    position: "relative",
                                                                                                    top: 3,
                                                                                                    left: 3
                                                                                                  }
                                                                                                })
                                                                                          ]
                                                                                        }))
                                                                              })
                                                                        }),
                                                                    JsxRuntime.jsx(Typography, {
                                                                          level: "body-xs",
                                                                          textColor: "text.tertiary",
                                                                          children: Caml_option.some(param[2])
                                                                        })
                                                                  ],
                                                                  direction: "column",
                                                                  alignItems: "flex-start",
                                                                  xs: 12,
                                                                  md: 3
                                                                }, String(i));
                                                    })),
                                              links.length > 3 ? JsxRuntime.jsx(Grid, {
                                                      children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                                                direction: "row",
                                                                spacing: 1,
                                                                sx: {
                                                                  "&:hover": {
                                                                    cursor: "pointer"
                                                                  }
                                                                },
                                                                onClick: (function (param) {
                                                                    Curry._1(setViewMore, (function (param) {
                                                                            return !viewMore;
                                                                          }));
                                                                  }),
                                                                children: Caml_option.some(JsxRuntime.jsxs(Typography, {
                                                                          level: "body-sm",
                                                                          noWrap: false,
                                                                          sx: {
                                                                            display: "inline"
                                                                          },
                                                                          textColor: "primary.500",
                                                                          children: [
                                                                            viewMore ? Txt$Thin.BookingDetails.heroLinksViewMore.value : Txt$Thin.BookingDetails.heroLinksViewFewer.value,
                                                                            JsxRuntime.jsx(ChevronRight, {
                                                                                  color: "primary",
                                                                                  fontSize: "md",
                                                                                  sx: {
                                                                                    position: "relative",
                                                                                    top: 3,
                                                                                    left: 3,
                                                                                    transform: viewMore ? "rotate(-90deg)" : "rotate(90deg)"
                                                                                  }
                                                                                })
                                                                          ]
                                                                        }))
                                                              })),
                                                      direction: "column",
                                                      justifyContent: {
                                                        xs: "flex-start",
                                                        md: "flex-end"
                                                      },
                                                      alignItems: "flex-end",
                                                      sx: {
                                                        display: "flex"
                                                      },
                                                      xs: 12,
                                                      md: viewMore ? (
                                                          links.length % 4 === 0 ? 12 : Math.imul(links.length % 4, 3)
                                                        ) : 3
                                                    }) : null
                                            ],
                                            container: true,
                                            rowSpacing: 2,
                                            columnSpacing: 1,
                                            sx: {
                                              flexGrow: 1
                                            }
                                          }))
                                }) : null
                        ],
                        variant: "outlined",
                        sx: {
                          backgroundImage: links.length !== 0 ? "" : "linear-gradient(\n          rgba(0, 0, 0, 0.4), \n          rgba(0, 0, 0, 0.4)\n        ), url(" + heroImage + ")",
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          flex: "1"
                        }
                      }))
            });
}

var make = BDHero;

exports.ListitemFragment = ListitemFragment;
exports.make = make;
/* react Not a pure module */
