// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var JsxRuntime = require("react/jsx-runtime");
var IconButton = require("@mui/joy/IconButton").default;
var Typography = require("@mui/joy/Typography").default;
var ChevronRight = require("@mui/icons-material/ChevronRight").default;

function BDContentBlock(props) {
  var titleComponent = props.titleComponent;
  var titleComponent$1 = titleComponent !== undefined ? Caml_option.valFromOption(titleComponent) : null;
  var match = React.useState(function () {
        return false;
      });
  var setIsOpen = match[1];
  var isOpen = match[0];
  var match$1 = React.useMemo((function () {
          if (isOpen) {
            return [
                    "auto",
                    "16px"
                  ];
          } else {
            return [
                    "0px",
                    "0px"
                  ];
          }
        }), [isOpen]);
  return JsxRuntime.jsx(Sheet, {
              sx: {
                p: 2,
                borderRadius: "md",
                border: 1,
                borderColor: "neutral.300",
                flex: "1",
                overflow: "hidden"
              },
              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                        direction: "column",
                        spacing: 2,
                        children: [
                          JsxRuntime.jsxs(Stack, {
                                direction: "row",
                                justifyContent: "space-between",
                                alignItems: "flex-start",
                                children: [
                                  JsxRuntime.jsxs(Stack, {
                                        direction: "row",
                                        alignItems: "center",
                                        spacing: 1,
                                        useFlexGap: true,
                                        flexWrap: "wrap",
                                        children: [
                                          JsxRuntime.jsx(Typography, {
                                                level: "h3",
                                                component: "h2",
                                                fontWeight: "md",
                                                children: Caml_option.some(props.title)
                                              }),
                                          titleComponent$1
                                        ]
                                      }),
                                  JsxRuntime.jsx(IconButton, {
                                        onClick: (function (param) {
                                            Curry._1(setIsOpen, (function (param) {
                                                    return !isOpen;
                                                  }));
                                          }),
                                        children: Caml_option.some(JsxRuntime.jsx(ChevronRight, {
                                                  sx: {
                                                    transform: isOpen ? "rotate(-90deg)" : "rotate(90deg)"
                                                  }
                                                }))
                                      })
                                ]
                              }),
                          JsxRuntime.jsx("div", {
                                children: props.children,
                                style: {
                                  height: match$1[0],
                                  marginTop: match$1[1],
                                  overflow: "hidden"
                                }
                              })
                        ]
                      }))
            });
}

var make = BDContentBlock;

exports.make = make;
/* react Not a pure module */
