// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var URL$Util = require("util/src/URL.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Link = require("@mui/joy/Link").default;
var JsxRuntime = require("react/jsx-runtime");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");

function Link$1(props) {
  var textColor = props.textColor;
  var underline = props.underline;
  var href = props.to;
  var underline$1 = underline !== undefined ? underline : "hover";
  var textColor$1 = textColor !== undefined ? textColor : "";
  var match = URL$Util.parse(href);
  var isExternal = match !== undefined ? match.host !== "localhost" : false;
  return JsxRuntime.jsx(Link, {
              children: Caml_option.some(props.children),
              textColor: textColor$1,
              underline: underline$1,
              onClick: (function (e) {
                  if (!isExternal) {
                    e.preventDefault();
                    RescriptReactRouter.push(href);
                    return ;
                  }
                  
                }),
              href: href,
              target: isExternal ? "_blank" : ""
            });
}

var make = Link$1;

exports.make = make;
/* @mui/joy/Link Not a pure module */
