// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Button$Thin = require("../../common/atoms/Button.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thin = require("../../../utils/Common.bs.js");
var Grid = require("@mui/joy/Grid").default;
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var TextInput$Thin = require("../../common/molecules/TextInput.bs.js");
var $$Option = require("@mui/joy/Option").default;
var Select = require("@mui/joy/Select").default;
var JsxRuntime = require("react/jsx-runtime");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var AddPassDetails_query_graphql$Thin = require("../../../__generated__/AddPassDetails_query_graphql.bs.js");

var convertFragment = AddPassDetails_query_graphql$Thin.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(AddPassDetails_query_graphql$Thin.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, AddPassDetails_query_graphql$Thin.node, convertFragment);
}

var QueryFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

function AddPassDetails(props) {
  var mobile = props.mobile;
  var setLoyalty = props.setLoyalty;
  var loyalty = props.loyalty;
  var setResidence = props.setResidence;
  var residence = props.residence;
  var setPostalCode = props.setPostalCode;
  var postalCode = props.postalCode;
  var setGender = props.setGender;
  var gender = props.gender;
  var setCountry = props.setCountry;
  var country = props.country;
  var setDob = props.setDob;
  var dob = props.dob;
  var setSecPhone = props.setSecPhone;
  var secPhone = props.secPhone;
  var setCell = props.setCell;
  var cell = props.cell;
  var setEmail = props.setEmail;
  var email = props.email;
  var setLastName = props.setLastName;
  var lastName = props.lastName;
  var setFirstName = props.setFirstName;
  var firstName = props.firstName;
  var mobile$1 = mobile !== undefined ? mobile : false;
  var match = React.useState(function () {
        return false;
      });
  var setDateFocused = match[1];
  var dateFocused = match[0];
  var data = RescriptRelay_Fragment.useFragment(AddPassDetails_query_graphql$Thin.node, convertFragment, props.countryFrag);
  var match$1 = data.countries;
  var countryTuples = match$1 !== undefined ? Belt_Array.concat(Belt_Array.map(match$1.nodes, (function (c) {
                return [
                        c.alpha3,
                        c.englishName
                      ];
              })), [[
            "XX",
            "Unknown"
          ]]) : [];
  if (mobile$1) {
    var match$2 = dob === "";
    return JsxRuntime.jsxs(Stack, {
                component: "form",
                spacing: 2,
                sx: {
                  p: 2
                },
                children: [
                  JsxRuntime.jsx(TextInput$Thin.make, {
                        type_: "text",
                        placeholder: "First Name",
                        variant: "outlined",
                        value: firstName,
                        onChange: (function (e) {
                            var val = e.currentTarget.value;
                            Curry._1(setFirstName, (function (param) {
                                    return val;
                                  }));
                          })
                      }),
                  JsxRuntime.jsx(TextInput$Thin.make, {
                        type_: "text",
                        placeholder: "Last Name",
                        variant: "outlined",
                        value: lastName,
                        onChange: (function (e) {
                            var val = e.currentTarget.value;
                            Curry._1(setLastName, (function (param) {
                                    return val;
                                  }));
                          })
                      }),
                  JsxRuntime.jsx(TextInput$Thin.make, {
                        type_: "email",
                        placeholder: "Email",
                        variant: "outlined",
                        value: email,
                        onChange: (function (e) {
                            var val = e.currentTarget.value;
                            Curry._1(setEmail, (function (param) {
                                    return val;
                                  }));
                          })
                      }),
                  JsxRuntime.jsx(TextInput$Thin.make, {
                        type_: "text",
                        placeholder: "Cell Phone",
                        variant: "outlined",
                        value: cell,
                        onChange: (function (e) {
                            var val = e.currentTarget.value;
                            Curry._1(setCell, (function (param) {
                                    return val;
                                  }));
                          })
                      }),
                  JsxRuntime.jsx(Select, {
                        children: Caml_option.some(Belt_Array.mapWithIndex([
                                  "F",
                                  "M",
                                  "X"
                                ], (function (i, g) {
                                    return JsxRuntime.jsx($$Option, {
                                                value: g,
                                                children: Caml_option.some(Common$Thin.Gender.toString(g)),
                                                sx: {
                                                  maxWidth: "calc(100vw - 32px)"
                                                }
                                              }, String(i));
                                  }))),
                        onChange: (function (param, v) {
                            Curry._1(setGender, (function (param) {
                                    return v;
                                  }));
                          }),
                        placeholder: "Gender",
                        slotProps: {
                          listbox: {
                            sx: {
                              zIndex: "modal",
                              maxWidth: "calc(100vw - 32px)"
                            }
                          }
                        },
                        value: Caml_option.some(gender),
                        variant: "outlined"
                      }),
                  JsxRuntime.jsx(Select, {
                        children: Caml_option.some(Belt_Array.map(countryTuples, (function (param) {
                                    var code = param[0];
                                    return JsxRuntime.jsx($$Option, {
                                                value: code,
                                                children: Caml_option.some(param[1]),
                                                sx: {
                                                  maxWidth: "calc(100vw - 32px)"
                                                }
                                              }, code);
                                  }))),
                        onChange: (function (param, v) {
                            Curry._1(setCountry, (function (param) {
                                    return v;
                                  }));
                          }),
                        placeholder: "Country",
                        slotProps: {
                          listbox: {
                            sx: {
                              zIndex: "modal",
                              maxWidth: "calc(100vw - 32px)"
                            }
                          }
                        },
                        variant: "outlined"
                      }),
                  JsxRuntime.jsx(TextInput$Thin.make, {
                        type_: dateFocused || !match$2 ? "date" : "text",
                        placeholder: "Birthdate",
                        variant: "outlined",
                        value: dob,
                        onChange: (function (e) {
                            var val = e.currentTarget.value;
                            Curry._1(setDob, (function (param) {
                                    return val;
                                  }));
                          }),
                        onFocus: (function (param) {
                            Curry._1(setDateFocused, (function (param) {
                                    return true;
                                  }));
                          }),
                        onBlur: (function (param) {
                            Curry._1(setDateFocused, (function (param) {
                                    return false;
                                  }));
                          })
                      }),
                  JsxRuntime.jsx(TextInput$Thin.make, {
                        type_: "text",
                        placeholder: "Secondary Phone (Optional)",
                        variant: "outlined",
                        value: secPhone,
                        onChange: (function (e) {
                            var val = e.currentTarget.value;
                            Curry._1(setSecPhone, (function (param) {
                                    return val;
                                  }));
                          })
                      }),
                  JsxRuntime.jsx(Select, {
                        children: Caml_option.some(Belt_Array.map(countryTuples, (function (param) {
                                    var code = param[0];
                                    return JsxRuntime.jsx($$Option, {
                                                value: code,
                                                children: Caml_option.some(param[1]),
                                                sx: {
                                                  maxWidth: "calc(100vw - 32px)"
                                                }
                                              }, code);
                                  }))),
                        onChange: (function (param, v) {
                            Curry._1(setResidence, (function (param) {
                                    return v;
                                  }));
                          }),
                        placeholder: "Country of Residence",
                        slotProps: {
                          listbox: {
                            sx: {
                              zIndex: "modal",
                              maxWidth: "calc(100vw - 32px)"
                            }
                          }
                        },
                        variant: "outlined"
                      }),
                  JsxRuntime.jsx(TextInput$Thin.make, {
                        type_: "text",
                        placeholder: "Postal Code",
                        variant: "outlined",
                        value: postalCode,
                        onChange: (function (e) {
                            var val = e.currentTarget.value;
                            Curry._1(setPostalCode, (function (param) {
                                    return val;
                                  }));
                          })
                      }),
                  JsxRuntime.jsx(TextInput$Thin.make, {
                        type_: "text",
                        placeholder: "Loyalty Number (Optional)",
                        variant: "outlined",
                        value: loyalty,
                        onChange: (function (e) {
                            var val = e.currentTarget.value;
                            Curry._1(setLoyalty, (function (param) {
                                    return val;
                                  }));
                          })
                      })
                ]
              });
  }
  var match$3 = dob === "";
  return JsxRuntime.jsxs(Stack, {
              direction: "column",
              component: "form",
              spacing: 2,
              sx: {
                py: 1
              },
              children: [
                JsxRuntime.jsx(Sheet, {
                      sx: {
                        borderRadius: "sm",
                        backgroundColor: "background.level1",
                        p: 2
                      },
                      children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                children: Caml_option.some(JsxRuntime.jsxs(Grid, {
                                          children: [
                                            JsxRuntime.jsx(Grid, {
                                                  children: Caml_option.some(JsxRuntime.jsx(TextInput$Thin.make, {
                                                            type_: "text",
                                                            placeholder: "First Name",
                                                            variant: "plain",
                                                            value: firstName,
                                                            onChange: (function (e) {
                                                                var val = e.currentTarget.value;
                                                                Curry._1(setFirstName, (function (param) {
                                                                        return val;
                                                                      }));
                                                              })
                                                          })),
                                                  xs: 6
                                                }),
                                            JsxRuntime.jsx(Grid, {
                                                  children: Caml_option.some(JsxRuntime.jsx(TextInput$Thin.make, {
                                                            type_: "text",
                                                            placeholder: "Last Name",
                                                            variant: "plain",
                                                            value: lastName,
                                                            onChange: (function (e) {
                                                                var val = e.currentTarget.value;
                                                                Curry._1(setLastName, (function (param) {
                                                                        return val;
                                                                      }));
                                                              })
                                                          })),
                                                  xs: 6
                                                }),
                                            JsxRuntime.jsx(Grid, {
                                                  children: Caml_option.some(JsxRuntime.jsx(TextInput$Thin.make, {
                                                            type_: "email",
                                                            placeholder: "Email",
                                                            variant: "plain",
                                                            value: email,
                                                            onChange: (function (e) {
                                                                var val = e.currentTarget.value;
                                                                Curry._1(setEmail, (function (param) {
                                                                        return val;
                                                                      }));
                                                              })
                                                          })),
                                                  xs: 6
                                                }),
                                            JsxRuntime.jsx(Grid, {
                                                  children: Caml_option.some(JsxRuntime.jsx(TextInput$Thin.make, {
                                                            type_: "text",
                                                            placeholder: "Cell Phone",
                                                            variant: "plain",
                                                            value: cell,
                                                            onChange: (function (e) {
                                                                var val = e.currentTarget.value;
                                                                Curry._1(setCell, (function (param) {
                                                                        return val;
                                                                      }));
                                                              })
                                                          })),
                                                  xs: 6
                                                }),
                                            JsxRuntime.jsx(Grid, {
                                                  children: Caml_option.some(JsxRuntime.jsx(Select, {
                                                            children: Caml_option.some(Belt_Array.mapWithIndex([
                                                                      "F",
                                                                      "M",
                                                                      "X"
                                                                    ], (function (i, g) {
                                                                        return JsxRuntime.jsx($$Option, {
                                                                                    value: g,
                                                                                    children: Caml_option.some(Common$Thin.Gender.toString(g))
                                                                                  }, String(i));
                                                                      }))),
                                                            onChange: (function (param, v) {
                                                                Curry._1(setGender, (function (param) {
                                                                        return v;
                                                                      }));
                                                              }),
                                                            placeholder: "Gender",
                                                            slotProps: {
                                                              listbox: {
                                                                sx: {
                                                                  zIndex: "modal"
                                                                }
                                                              }
                                                            },
                                                            value: Caml_option.some(gender),
                                                            variant: "plain"
                                                          })),
                                                  xs: 6
                                                }),
                                            JsxRuntime.jsx(Grid, {
                                                  children: Caml_option.some(JsxRuntime.jsx(Select, {
                                                            children: Caml_option.some(Belt_Array.map(countryTuples, (function (param) {
                                                                        var code = param[0];
                                                                        return JsxRuntime.jsx($$Option, {
                                                                                    value: code,
                                                                                    children: Caml_option.some(param[1])
                                                                                  }, code);
                                                                      }))),
                                                            onChange: (function (param, v) {
                                                                Curry._1(setCountry, (function (param) {
                                                                        return v;
                                                                      }));
                                                              }),
                                                            placeholder: "Country",
                                                            slotProps: {
                                                              listbox: {
                                                                sx: {
                                                                  zIndex: "modal"
                                                                }
                                                              }
                                                            },
                                                            value: Caml_option.some(country),
                                                            variant: "plain"
                                                          })),
                                                  xs: 6
                                                }),
                                            JsxRuntime.jsx(Grid, {
                                                  children: Caml_option.some(JsxRuntime.jsx(TextInput$Thin.make, {
                                                            type_: dateFocused || !match$3 ? "date" : "text",
                                                            placeholder: "Birthdate",
                                                            variant: "plain",
                                                            value: dob,
                                                            onChange: (function (e) {
                                                                var val = e.currentTarget.value;
                                                                Curry._1(setDob, (function (param) {
                                                                        return val;
                                                                      }));
                                                              }),
                                                            onFocus: (function (param) {
                                                                Curry._1(setDateFocused, (function (param) {
                                                                        return true;
                                                                      }));
                                                              }),
                                                            onBlur: (function (param) {
                                                                Curry._1(setDateFocused, (function (param) {
                                                                        return false;
                                                                      }));
                                                              })
                                                          })),
                                                  xs: 6
                                                }),
                                            JsxRuntime.jsx(Grid, {
                                                  children: Caml_option.some(JsxRuntime.jsx(TextInput$Thin.make, {
                                                            type_: "text",
                                                            placeholder: "Secondary Phone (Optional)",
                                                            variant: "plain",
                                                            value: secPhone,
                                                            onChange: (function (e) {
                                                                var val = e.currentTarget.value;
                                                                Curry._1(setSecPhone, (function (param) {
                                                                        return val;
                                                                      }));
                                                              })
                                                          })),
                                                  xs: 6
                                                }),
                                            JsxRuntime.jsx(Grid, {
                                                  children: Caml_option.some(JsxRuntime.jsx(Select, {
                                                            children: Caml_option.some(Belt_Array.map(countryTuples, (function (param) {
                                                                        var code = param[0];
                                                                        return JsxRuntime.jsx($$Option, {
                                                                                    value: code,
                                                                                    children: Caml_option.some(param[1])
                                                                                  }, code);
                                                                      }))),
                                                            onChange: (function (param, v) {
                                                                Curry._1(setResidence, (function (param) {
                                                                        return v;
                                                                      }));
                                                              }),
                                                            placeholder: "Country of Residence",
                                                            slotProps: {
                                                              listbox: {
                                                                sx: {
                                                                  zIndex: "modal"
                                                                }
                                                              }
                                                            },
                                                            value: Caml_option.some(residence),
                                                            variant: "plain"
                                                          })),
                                                  xs: 6
                                                }),
                                            JsxRuntime.jsx(Grid, {
                                                  children: Caml_option.some(JsxRuntime.jsx(TextInput$Thin.make, {
                                                            type_: "text",
                                                            placeholder: "Postal Code",
                                                            variant: "plain",
                                                            value: postalCode,
                                                            onChange: (function (e) {
                                                                var val = e.currentTarget.value;
                                                                Curry._1(setPostalCode, (function (param) {
                                                                        return val;
                                                                      }));
                                                              })
                                                          })),
                                                  xs: 6
                                                }),
                                            JsxRuntime.jsx(Grid, {
                                                  children: Caml_option.some(JsxRuntime.jsx(TextInput$Thin.make, {
                                                            type_: "text",
                                                            placeholder: "Loyalty Number (Optional)",
                                                            variant: "plain",
                                                            value: loyalty,
                                                            onChange: (function (e) {
                                                                var val = e.currentTarget.value;
                                                                Curry._1(setLoyalty, (function (param) {
                                                                        return val;
                                                                      }));
                                                              })
                                                          })),
                                                  xs: 12
                                                })
                                          ],
                                          container: true,
                                          spacing: 2,
                                          sx: {
                                            p: 1
                                          }
                                        }))
                              }))
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(Button$Thin.make, {
                            fullWidth: false,
                            onClick: (function (param) {
                                
                              }),
                            size: "lg",
                            disabled: firstName === "" || lastName === "" || email === "" || cell === "" || (
                              (gender == null) ? undefined : Caml_option.some(gender)
                            ) === undefined || dob === "" || (
                              (country == null) ? undefined : Caml_option.some(country)
                            ) === undefined || (
                              (residence == null) ? undefined : Caml_option.some(residence)
                            ) === undefined || postalCode === "" || true,
                            children: "Add Passenger"
                          })
                    })
              ]
            });
}

var make = AddPassDetails;

exports.QueryFragment = QueryFragment;
exports.make = make;
/* react Not a pure module */
