// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Alert$Thin = require("../atoms/Alert.bs.js");
var Button$Thin = require("../atoms/Button.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Dialog$Thin = require("./Dialog.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var DialogActions = require("@mui/joy/DialogActions").default;

function ConfirmActionDialog(props) {
  var disabled = props.disabled;
  var error = props.error;
  var actionSlug = props.actionSlug;
  var disabled$1 = disabled !== undefined ? disabled : false;
  return JsxRuntime.jsxs(Dialog$Thin.make, {
              open_: props.open_,
              title: props.action,
              description: Caml_option.some(props.description),
              onClose: props.onClose,
              titleId: "" + actionSlug + "-title",
              titleDescId: "" + actionSlug + "-description",
              children: [
                JsxRuntime.jsxs(DialogActions, {
                      children: [
                        JsxRuntime.jsx(Button$Thin.make, {
                              loading: props.loading,
                              type_: "submit",
                              color: "primary",
                              disabled: disabled$1,
                              children: Caml_option.some(props.confirmText)
                            }),
                        JsxRuntime.jsx(Button$Thin.make, {
                              onClick: props.onCancel,
                              variant: "outlined",
                              color: "neutral",
                              children: Caml_option.some(props.cancelText)
                            })
                      ],
                      component: "form",
                      onSubmit: props.onConfirm
                    }),
                error !== undefined ? JsxRuntime.jsx(Alert$Thin.make, {
                        message: error,
                        type_: "error"
                      }) : JsxRuntime.jsx(JsxRuntime.Fragment, {})
              ]
            });
}

var make = ConfirmActionDialog;

exports.make = make;
/* Alert-Thin Not a pure module */
