// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Txt$Thin = require("../../../text/Txt.bs.js");
var Button$Thin = require("../../common/atoms/Button.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thin = require("../../../utils/Common.bs.js");
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var Divider = require("@mui/joy/Divider").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var BDBillPayDrawer$Thin = require("./BDBillPayDrawer.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var BDBillingSummary_detail_graphql$Thin = require("../../../__generated__/BDBillingSummary_detail_graphql.bs.js");
var VisibilityOutlined = require("@mui/icons-material/VisibilityOutlined").default;

var convertFragment = BDBillingSummary_detail_graphql$Thin.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(BDBillingSummary_detail_graphql$Thin.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BDBillingSummary_detail_graphql$Thin.node, convertFragment);
}

var DetailFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

function BDBillingSummary(props) {
  var isActive = props.isActive;
  var fragmentRefs = props.fragmentRefs;
  var detail = RescriptRelay_Fragment.useFragment(BDBillingSummary_detail_graphql$Thin.node, convertFragment, fragmentRefs);
  var match = React.useState(function () {
        return false;
      });
  var setModalOpen = match[1];
  var viewDetails = JsxRuntime.jsxs(Stack, {
        direction: "row",
        justifyContent: "center",
        alignItems: "center",
        spacing: 1,
        sx: {
          "&:hover": {
            cursor: "pointer"
          }
        },
        onClick: (function (param) {
            Curry._1(setModalOpen, (function (param) {
                    return true;
                  }));
          }),
        children: [
          JsxRuntime.jsx(Stack, {
                justifyContent: "center",
                alignItems: "center",
                children: Caml_option.some(JsxRuntime.jsx(Typography, {
                          color: "primary",
                          level: "body-sm",
                          children: Caml_option.some(Txt$Thin.BookingDetails.viewBillingDetails.value)
                        }))
              }),
          JsxRuntime.jsx(VisibilityOutlined, {
                color: "primary",
                fontSize: "xl",
                sx: {
                  position: "relative",
                  top: 1
                }
              })
        ]
      });
  var match$1 = detail.billing_summary;
  var tmp;
  if (match$1 !== undefined) {
    var pendingPaymentSubtotal = match$1.pendingPaymentSubtotal;
    tmp = JsxRuntime.jsxs(Stack, {
          direction: "column",
          justifyContent: "space-between",
          spacing: 2,
          sx: {
            height: "100%"
          },
          children: [
            JsxRuntime.jsxs(Stack, {
                  spacing: 2,
                  children: [
                    JsxRuntime.jsxs(Stack, {
                          direction: "row",
                          justifyContent: "space-between",
                          children: [
                            JsxRuntime.jsx(Typography, {
                                  level: "body-md",
                                  children: Caml_option.some(Txt$Thin.BookingDetails.totalBilled.value)
                                }),
                            JsxRuntime.jsx(Typography, {
                                  level: "body-md",
                                  children: Caml_option.some(Common$Thin.formatCurrency(match$1.billingLineSubtotal))
                                })
                          ]
                        }),
                    JsxRuntime.jsxs(Stack, {
                          direction: "row",
                          justifyContent: "space-between",
                          children: [
                            JsxRuntime.jsx(Typography, {
                                  level: "body-md",
                                  children: Caml_option.some(Txt$Thin.BookingDetails.totalPaid.value)
                                }),
                            JsxRuntime.jsx(Typography, {
                                  level: "body-md",
                                  children: Caml_option.some(Common$Thin.formatCurrency(match$1.completedPaymentSubtotal))
                                })
                          ]
                        }),
                    JsxRuntime.jsx(Divider, {}),
                    JsxRuntime.jsxs(Stack, {
                          direction: "row",
                          justifyContent: "space-between",
                          children: [
                            JsxRuntime.jsx(Typography, {
                                  level: "body-lg",
                                  fontWeight: "700",
                                  children: Caml_option.some(Txt$Thin.BookingDetails.remainingBalance.value)
                                }),
                            JsxRuntime.jsx(Typography, {
                                  level: "body-lg",
                                  fontWeight: "700",
                                  children: Caml_option.some(Common$Thin.formatCurrency(match$1.totalAmount))
                                })
                          ]
                        }),
                    pendingPaymentSubtotal > 0.0 ? JsxRuntime.jsxs(Stack, {
                            direction: "column",
                            spacing: 1,
                            children: [
                              JsxRuntime.jsxs(Stack, {
                                    direction: "row",
                                    justifyContent: "space-between",
                                    children: [
                                      JsxRuntime.jsx(Typography, {
                                            level: "body-sm",
                                            sx: {
                                              color: "neutral.500",
                                              fontStyle: "italic"
                                            },
                                            fontWeight: "300",
                                            children: Caml_option.some(Txt$Thin.BookingDetails.pendingPayment.value)
                                          }),
                                      JsxRuntime.jsx(Typography, {
                                            level: "body-md",
                                            sx: {
                                              color: "text.primary",
                                              fontStyle: "italic"
                                            },
                                            children: Caml_option.some(Common$Thin.formatCurrency(pendingPaymentSubtotal))
                                          })
                                    ]
                                  }),
                              JsxRuntime.jsxs(Stack, {
                                    direction: "row",
                                    justifyContent: "space-between",
                                    children: [
                                      JsxRuntime.jsx(Typography, {
                                            level: "body-sm",
                                            sx: {
                                              color: "neutral.500",
                                              fontStyle: "italic"
                                            },
                                            fontWeight: "300",
                                            children: Caml_option.some(Txt$Thin.BookingDetails.balanceAfterPending.value)
                                          }),
                                      JsxRuntime.jsx(Typography, {
                                            level: "body-md",
                                            sx: {
                                              color: "text.primary",
                                              fontStyle: "italic"
                                            },
                                            children: Caml_option.some(Common$Thin.formatCurrency(match$1.totalAmountIncludingPending))
                                          })
                                    ]
                                  })
                            ]
                          }) : null,
                    JsxRuntime.jsx(BDBillPayDrawer$Thin.make, {
                          open_: match[0],
                          onClose: (function (param) {
                              Curry._1(setModalOpen, (function (param) {
                                      return false;
                                    }));
                            }),
                          fragmentRefs: fragmentRefs,
                          isActive: isActive
                        })
                  ]
                }),
            isActive ? JsxRuntime.jsxs(Stack, {
                    direction: "row",
                    justifyContent: {
                      xs: "space-between",
                      md: "flex-end"
                    },
                    sx: {
                      width: "100%",
                      marginTop: {
                        xs: 5,
                        md: 0
                      }
                    },
                    children: [
                      JsxRuntime.jsx(Stack, {
                            direction: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            sx: {
                              display: {
                                xs: "flex",
                                md: "none"
                              },
                              height: "100%"
                            },
                            children: Caml_option.some(viewDetails)
                          }),
                      JsxRuntime.jsx(Button$Thin.make, {
                            size: "md",
                            disabled: true,
                            children: Caml_option.some(Txt$Thin.BookingDetails.makeAPayment.value)
                          })
                    ]
                  }) : null
          ]
        });
  } else {
    tmp = JsxRuntime.jsx(Typography, {
          level: "body-md",
          children: Caml_option.some(Txt$Thin.BookingDetails.noBillingSummary.value)
        });
  }
  return JsxRuntime.jsx(Sheet, {
              sx: {
                p: 2,
                borderRadius: "md",
                border: 1,
                borderColor: "neutral.300",
                height: "100%"
              },
              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                        direction: "column",
                        spacing: 2,
                        sx: {
                          height: "100%"
                        },
                        children: [
                          JsxRuntime.jsxs(Stack, {
                                direction: "row",
                                justifyContent: "space-between",
                                children: [
                                  JsxRuntime.jsx(Typography, {
                                        level: "h3",
                                        component: "h2",
                                        fontWeight: "md",
                                        children: Caml_option.some(Txt$Thin.BookingDetails.billingSummaryHeading.value)
                                      }),
                                  JsxRuntime.jsx(Stack, {
                                        direction: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        sx: {
                                          display: {
                                            xs: "none",
                                            md: "flex"
                                          },
                                          height: "100%"
                                        },
                                        children: Caml_option.some(viewDetails)
                                      })
                                ]
                              }),
                          tmp
                        ]
                      }))
            });
}

var make = BDBillingSummary;

exports.DetailFragment = DetailFragment;
exports.make = make;
/* react Not a pure module */
