// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Txt$Thin = require("../../../text/Txt.bs.js");
var Js_string = require("rescript/lib/js/js_string.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Avatar$Thin = require("../atoms/Avatar.bs.js");
var Button$Thin = require("../atoms/Button.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Box = require("@mui/joy/Box").default;
var NavMenu$Thin = require("./NavMenu.bs.js");
var JoCoLoGo$Thin = require("../../../assets/JoCoLoGo.bs.js");
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var JsxRuntime = require("react/jsx-runtime");
var CustomScalars$Thin = require("../../../utils/CustomScalars.bs.js");
var Typography = require("@mui/joy/Typography").default;
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var Navigation_query_graphql$Thin = require("../../../__generated__/Navigation_query_graphql.bs.js");

var convertFragment = Navigation_query_graphql$Thin.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(Navigation_query_graphql$Thin.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, Navigation_query_graphql$Thin.node, convertFragment);
}

var QueryFragment_custSignonStatus_decode = Navigation_query_graphql$Thin.Utils.custSignonStatus_decode;

var QueryFragment_custSignonStatus_fromString = Navigation_query_graphql$Thin.Utils.custSignonStatus_fromString;

var QueryFragment = {
  custSignonStatus_decode: QueryFragment_custSignonStatus_decode,
  custSignonStatus_fromString: QueryFragment_custSignonStatus_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

function Navigation(props) {
  var query = RescriptRelay_Fragment.useFragment(Navigation_query_graphql$Thin.node, convertFragment, props.fragmentRefs);
  var match = query.currentUserProfiles;
  var displayName;
  if (match !== undefined) {
    var match$1 = Belt_Array.get(match.nodes, 0);
    var exit = 0;
    if (match$1 !== undefined) {
      var firstName = match$1.firstName;
      if (firstName !== undefined) {
        var lastName = match$1.lastName;
        var firstName$1 = Caml_option.valFromOption(firstName);
        displayName = lastName !== undefined ? CustomScalars$Thin.NonemptyString.serialize(firstName$1) + " " + CustomScalars$Thin.NonemptyString.serialize(Caml_option.valFromOption(lastName)) : CustomScalars$Thin.NonemptyString.serialize(firstName$1);
      } else {
        var lastName$1 = match$1.lastName;
        if (lastName$1 !== undefined) {
          displayName = CustomScalars$Thin.NonemptyString.serialize(Caml_option.valFromOption(lastName$1));
        } else {
          exit = 1;
        }
      }
    } else {
      exit = 1;
    }
    if (exit === 1) {
      var email = query.currentUserEmail;
      displayName = email !== undefined ? email : "My account";
    }
    
  } else {
    var email$1 = query.currentUserEmail;
    displayName = email$1 !== undefined ? email$1 : "My account";
  }
  var initial = Js_string.charAt(0, displayName).toUpperCase();
  var match$2 = query.currentUserSignonStatus;
  return JsxRuntime.jsxs(Sheet, {
              sx: {
                p: 2,
                borderBottom: 1,
                borderColor: "neutral.200",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
              },
              children: [
                JsxRuntime.jsx(Box, {
                      children: Caml_option.some(JsxRuntime.jsx(JoCoLoGo$Thin.make, {})),
                      sx: {
                        height: "100%",
                        width: "7rem"
                      }
                    }),
                JsxRuntime.jsxs(Stack, {
                      direction: "row",
                      spacing: 1,
                      children: [
                        match$2 === "ANONYMOUS" ? JsxRuntime.jsx(Button$Thin.make, {
                                onClick: (function (param) {
                                    RescriptReactRouter.push("/login");
                                  }),
                                children: Caml_option.some(Txt$Thin.Nav.login.value)
                              }) : JsxRuntime.jsxs(Stack, {
                                direction: "row",
                                alignItems: "center",
                                spacing: 1,
                                sx: {
                                  width: "100%"
                                },
                                children: [
                                  JsxRuntime.jsx(Avatar$Thin.make, {
                                        children: initial,
                                        size: "sm"
                                      }),
                                  JsxRuntime.jsx(Typography, {
                                        level: "body-sm",
                                        noWrap: true,
                                        sx: {
                                          display: {
                                            xs: "none",
                                            sm: "block"
                                          }
                                        },
                                        fontWeight: "bold",
                                        children: Caml_option.some(displayName)
                                      })
                                ]
                              }),
                        JsxRuntime.jsx(NavMenu$Thin.make, {})
                      ]
                    })
              ]
            });
}

var make = Navigation;

exports.QueryFragment = QueryFragment;
exports.make = make;
/* Txt-Thin Not a pure module */
