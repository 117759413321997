// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CustomScalars$Thin = require("../utils/CustomScalars.bs.js");
var BDPageRefetchQuery_graphql$Thin = require("./BDPageRefetchQuery_graphql.bs.js");

var Types = {};

var fragmentConverter = {"__root":{"numConnected_totalCount":{"b":""},"bookingDetails_nodes_bookingListitem_bookingId":{"c":"CustomScalars.BookingId"},"bookingDetails_nodes_bookingListitem":{"f":""},"bookingDetails_nodes":{"f":""}}};

var fragmentConverterMap = {
  "CustomScalars.BookingId": CustomScalars$Thin.BookingId.parse
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

function lifecycleStatus_decode($$enum) {
  if ($$enum === "STAGED" || $$enum === "CANCELED" || $$enum === "HISTORY" || $$enum === "DRAFT" || $$enum === "ACTIVE") {
    return $$enum;
  }
  
}

var lifecycleStatus_fromString = lifecycleStatus_decode;

var Utils = {
  lifecycleStatus_decode: lifecycleStatus_decode,
  lifecycleStatus_fromString: lifecycleStatus_fromString
};

function makeNode(rescript_graphql_node_BDPageRefetchQuery) {
  return ({
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "bookingId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": rescript_graphql_node_BDPageRefetchQuery
    }
  },
  "name": "BookingDetailsPage_query",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "bookingId",
              "variableName": "bookingId"
            }
          ],
          "kind": "ObjectValue",
          "name": "condition"
        }
      ],
      "concreteType": "BookingDetailsConnection",
      "kind": "LinkedField",
      "name": "bookingDetails",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BookingDetail",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "BookingListitem",
              "kind": "LinkedField",
              "name": "bookingListitem",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isBc",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "bookingId",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "BDHero_listitem"
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BDStateroomDetails_detail"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BDPassengerList_detail"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BDBillingSummary_detail"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BDInvoiceDetails_detail"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BDPassengerList_addberthdetail"
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lifecycleStatus",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentUserEmail",
      "storageKey": null
    },
    {
      "alias": "numConnected",
      "args": null,
      "concreteType": "CurrentUserConnectedAccountsConnection",
      "kind": "LinkedField",
      "name": "currentUserConnectedAccounts",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
});
}

var node = makeNode(BDPageRefetchQuery_graphql$Thin.node);

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
