// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var $$String = require("rescript/lib/js/string.js");
var Button$Thin = require("../../common/atoms/Button.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thin = require("../../../utils/Common.bs.js");
var Grid = require("@mui/joy/Grid").default;
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var TextInput$Thin = require("../../common/molecules/TextInput.bs.js");
var Button = require("@mui/joy/Button").default;
var JsxRuntime = require("react/jsx-runtime");
var Send = require("@mui/icons-material/Send").default;
var ContentCopyOutlined = require("@mui/icons-material/ContentCopyOutlined").default;

function InvitePass(props) {
  var setEmail = props.setEmail;
  var email = props.email;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(Stack, {
                      direction: "column",
                      component: "form",
                      spacing: 2,
                      sx: {
                        py: {
                          xs: 2,
                          md: 1
                        },
                        px: {
                          xs: 2,
                          md: 0
                        }
                      },
                      children: Caml_option.some(JsxRuntime.jsx(Sheet, {
                                sx: {
                                  borderRadius: "sm",
                                  backgroundColor: "background.level1",
                                  p: 2
                                },
                                children: Caml_option.some(JsxRuntime.jsxs(Grid, {
                                          children: [
                                            JsxRuntime.jsx(Grid, {
                                                  children: Caml_option.some(JsxRuntime.jsx(TextInput$Thin.make, {
                                                            type_: "email",
                                                            placeholder: "Email",
                                                            variant: "plain",
                                                            error: !Common$Thin.Email.isValid(email),
                                                            value: email,
                                                            onChange: (function (e) {
                                                                var val = e.currentTarget.value;
                                                                Curry._1(setEmail, (function (param) {
                                                                        return val;
                                                                      }));
                                                              })
                                                          })),
                                                  xs: 12,
                                                  md: 9
                                                }),
                                            JsxRuntime.jsx(Grid, {
                                                  children: Caml_option.some(JsxRuntime.jsx(Button$Thin.make, {
                                                            fullWidth: true,
                                                            onClick: (function (param) {
                                                                
                                                              }),
                                                            endDecorator: Caml_option.some(JsxRuntime.jsx(Send, {})),
                                                            color: "primary",
                                                            size: "md",
                                                            disabled: !Common$Thin.Email.isValid(email) || $$String.trim(email).length === 0,
                                                            children: "Send"
                                                          })),
                                                  xs: 12,
                                                  md: 3
                                                })
                                          ],
                                          container: true,
                                          spacing: 2,
                                          sx: {
                                            p: 1
                                          }
                                        }))
                              }))
                    }),
                JsxRuntime.jsx(Stack, {
                      sx: {
                        display: {
                          xs: "none",
                          md: "flex"
                        }
                      },
                      children: Caml_option.some(JsxRuntime.jsx("div", {
                                children: JsxRuntime.jsx(Button, {
                                      fullWidth: false,
                                      startDecorator: Caml_option.some(JsxRuntime.jsx(ContentCopyOutlined, {
                                                fontSize: "sm"
                                              })),
                                      variant: "text",
                                      size: "md",
                                      sx: {
                                        color: "text.secondary",
                                        p: 1,
                                        fontWeight: "normal"
                                      },
                                      children: "Copy Link"
                                    })
                              }))
                    })
              ]
            });
}

var make = InvitePass;

exports.make = make;
/* Button-Thin Not a pure module */
