// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var Date$Util = require("util/src/Date.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CustomScalars$Thin = require("../utils/CustomScalars.bs.js");

var Types = {};

var fragmentConverter = {"__root":{"voyageSlug":{"c":"CustomScalars.VoyageSlug"},"voyStartDate":{"c":"Util.Date.SQLDate.Exn"},"voyEndDate":{"c":"Util.Date.SQLDate.Exn"},"numBerths":{"b":""},"bookingId":{"c":"CustomScalars.BookingId"}}};

var fragmentConverterMap = {
  "CustomScalars.BookingId": CustomScalars$Thin.BookingId.parse,
  "Util.Date.SQLDate.Exn": Date$Util.SQLDate.Exn.parse,
  "CustomScalars.VoyageSlug": CustomScalars$Thin.VoyageSlug.parse
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

var Utils = {};

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpcomingBookingsSection_listitem",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bookingId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "voyageSlug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "voyStartDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "voyEndDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numBerths",
      "storageKey": null
    }
  ],
  "type": "BookingListitem",
  "abstractKey": null
};

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* Date-Util Not a pure module */
