// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CustomScalars$Thin = require("../utils/CustomScalars.bs.js");

var Types = {};

var fragmentConverter = {"__root":{"payment_nodes_processorDescription":{"c":"CustomScalars.NonemptyString"},"payment_nodes_payer":{"c":"CustomScalars.AccountId"},"payment_nodes_netAmount":{"b":""},"payment_nodes_createdAt":{"b":""},"billing_summary_totalAmountIncludingPending":{"b":""},"billing_summary_totalAmount":{"b":""},"billing_summary_pendingPaymentSubtotal":{"b":""},"billing_summary_completedPaymentSubtotal":{"b":""},"billing_summary_billingLineSubtotal":{"b":""},"billing_line_nodes_lineAmount":{"b":""},"billing_line_nodes_billingLineDescription":{"c":"CustomScalars.NonemptyString"}}};

var fragmentConverterMap = {
  "CustomScalars.NonemptyString": CustomScalars$Thin.NonemptyString.parse,
  "CustomScalars.AccountId": CustomScalars$Thin.AccountId.parse
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

function pmtLifecycleStatus_decode($$enum) {
  if ($$enum === "PENDING" || $$enum === "CANCELLED" || $$enum === "ERROR" || $$enum === "COMPLETED") {
    return $$enum;
  }
  
}

var pmtLifecycleStatus_fromString = pmtLifecycleStatus_decode;

var Utils = {
  pmtLifecycleStatus_decode: pmtLifecycleStatus_decode,
  pmtLifecycleStatus_fromString: pmtLifecycleStatus_fromString
};

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BDInvoiceDetails_detail",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "BookingBillinglinesConnection",
      "kind": "LinkedField",
      "name": "billing_line",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BookingBillingline",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "billingLineDescription",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lineAmount",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BookingBillingsummary",
      "kind": "LinkedField",
      "name": "billing_summary",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "billingLineSubtotal",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "completedPaymentSubtotal",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pendingPaymentSubtotal",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalAmountIncludingPending",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalAmount",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BookingPaymentsConnection",
      "kind": "LinkedField",
      "name": "payment",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BookingPayment",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "createdAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "lifecycleStatus",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "payer",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "payerDisplayName",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "netAmount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "processorDescription",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BookingDetail",
  "abstractKey": null
};

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* RescriptRelay Not a pure module */
