// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Txt$Thin = require("../../text/Txt.bs.js");
var Date$Util = require("util/src/Date.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Merge$Util = require("util/src/Merge.bs.js");
var Button$Thin = require("../common/atoms/Button.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var SysCtx$Thin = require("../../utils/SysCtx.bs.js");
var Grid = require("@mui/joy/Grid").default;
var Stack = require("@mui/joy/Stack").default;
var TextInput$Thin = require("../common/molecules/TextInput.bs.js");
var $$Option = require("@mui/joy/Option").default;
var Select = require("@mui/joy/Select").default;
var JsxRuntime = require("react/jsx-runtime");
var FormLabel = require("@mui/joy/FormLabel").default;
var CustomScalars$Thin = require("../../utils/CustomScalars.bs.js");
var Typography = require("@mui/joy/Typography").default;
var FormControl = require("@mui/joy/FormControl").default;
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var FormHelperText = require("@mui/joy/FormHelperText").default;
var PersonalInformation_query_graphql$Thin = require("../../__generated__/PersonalInformation_query_graphql.bs.js");
var PersonalInformation_profile_graphql$Thin = require("../../__generated__/PersonalInformation_profile_graphql.bs.js");

var convertFragment = PersonalInformation_profile_graphql$Thin.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(PersonalInformation_profile_graphql$Thin.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, PersonalInformation_profile_graphql$Thin.node, convertFragment);
}

var ProfileFragment_docGender_decode = PersonalInformation_profile_graphql$Thin.Utils.docGender_decode;

var ProfileFragment_docGender_fromString = PersonalInformation_profile_graphql$Thin.Utils.docGender_fromString;

var ProfileFragment = {
  docGender_decode: ProfileFragment_docGender_decode,
  docGender_fromString: ProfileFragment_docGender_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

var convertFragment$1 = PersonalInformation_query_graphql$Thin.Internal.convertFragment;

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(PersonalInformation_query_graphql$Thin.node, convertFragment$1, fRef);
}

function useOpt$1(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, PersonalInformation_query_graphql$Thin.node, convertFragment$1);
}

var QueryFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$1,
  use: use$1,
  useOpt: useOpt$1
};

function PersonalInformation(props) {
  var profile = RescriptRelay_Fragment.useFragment(PersonalInformation_profile_graphql$Thin.node, convertFragment, props.fragmentRefs);
  var countries = RescriptRelay_Fragment.useFragment(PersonalInformation_query_graphql$Thin.node, convertFragment$1, props.countryFrag);
  var match = React.useState(function () {
        return false;
      });
  var setIsEditing = match[1];
  var isEditing = match[0];
  var firstName = profile.firstName;
  var match$1;
  if (firstName !== undefined) {
    var lastName = profile.lastName;
    var firstName$1 = Caml_option.valFromOption(firstName);
    match$1 = lastName !== undefined ? [
        CustomScalars$Thin.NonemptyString.serialize(firstName$1),
        CustomScalars$Thin.NonemptyString.serialize(Caml_option.valFromOption(lastName))
      ] : [
        CustomScalars$Thin.NonemptyString.serialize(firstName$1),
        ""
      ];
  } else {
    var lastName$1 = profile.lastName;
    match$1 = lastName$1 !== undefined ? [
        "",
        CustomScalars$Thin.NonemptyString.serialize(Caml_option.valFromOption(lastName$1))
      ] : [
        "",
        ""
      ];
  }
  var lName = match$1[1];
  var fName = match$1[0];
  var genderToText = function (g) {
    if (g === "F") {
      return "F";
    } else if (g === "M") {
      return "M";
    } else {
      return "X";
    }
  };
  var g = profile.legalDocumentGender;
  var docGender = g !== undefined ? g : "X";
  var date = profile.legalDocumentCitizenship;
  var citizenship = date !== undefined ? Caml_option.valFromOption(date) : "XX";
  var match$2 = countries.countries;
  var countryTuples = match$2 !== undefined ? Belt_Array.concat(Belt_Array.map(match$2.nodes, (function (c) {
                return [
                        c.alpha3,
                        c.englishName
                      ];
              })), [[
            "XX",
            "Unknown"
          ]]) : [];
  var match$3 = React.useState(function () {
        return docGender;
      });
  var setGender = match$3[1];
  var match$4 = React.useState(function () {
        return fName;
      });
  var setFirstName = match$4[1];
  var match$5 = React.useState(function () {
        return lName;
      });
  var setLastName = match$5[1];
  var match$6 = React.useState(function () {
        return citizenship;
      });
  var setCountry = match$6[1];
  var name = profile.legalDocumentFirstName;
  var legalFName = name !== undefined ? CustomScalars$Thin.NonemptyString.serialize(Caml_option.valFromOption(name)) : "FNU";
  var name$1 = profile.legalDocumentLastName;
  var legalLName = name$1 !== undefined ? CustomScalars$Thin.NonemptyString.serialize(Caml_option.valFromOption(name$1)) : "LNU";
  var date$1 = profile.legalDocumentBirthdate;
  var birthdate = date$1 !== undefined ? Date$Util.format(Caml_option.valFromOption(date$1), SysCtx$Thin.vars.dateFormat) : "NDK";
  return JsxRuntime.jsxs(Stack, {
              direction: "column",
              sx: {
                width: "100%",
                py: 2
              },
              children: [
                JsxRuntime.jsx(Typography, {
                      level: "h4",
                      component: "h2",
                      sx: {
                        mb: 2
                      },
                      children: Caml_option.some(Txt$Thin.Account.personalInformationHeading.value)
                    }),
                JsxRuntime.jsxs(Grid, {
                      children: [
                        JsxRuntime.jsx(Grid, {
                              children: Caml_option.some(JsxRuntime.jsx(TextInput$Thin.make, {
                                        type_: "text",
                                        fullWidth: true,
                                        placeholder: Txt$Thin.Account.preferredFirstNamePlaceholder.value,
                                        disabled: !isEditing,
                                        value: match$4[0],
                                        onChange: (function (e) {
                                            var val = e.currentTarget.value;
                                            Curry._1(setFirstName, (function (param) {
                                                    return val;
                                                  }));
                                          }),
                                        label: Txt$Thin.Account.preferredFirstNameLabel.value,
                                        helperModule: Caml_option.some(Txt$Thin.Account.preferredFirstNameHelpText.value)
                                      })),
                              xs: 12,
                              md: 6
                            }),
                        JsxRuntime.jsx(Grid, {
                              children: Caml_option.some(JsxRuntime.jsx(TextInput$Thin.make, {
                                        type_: "text",
                                        fullWidth: true,
                                        placeholder: Txt$Thin.Account.preferredLastNamePlaceholder.value,
                                        disabled: !isEditing,
                                        value: match$5[0],
                                        onChange: (function (e) {
                                            var val = e.currentTarget.value;
                                            Curry._1(setLastName, (function (param) {
                                                    return val;
                                                  }));
                                          }),
                                        label: Txt$Thin.Account.preferredLastNameLabel.value,
                                        helperModule: Caml_option.some(Txt$Thin.Account.preferredLastNameHelpText.value)
                                      })),
                              xs: 12,
                              md: 6
                            }),
                        JsxRuntime.jsx(Grid, {
                              children: Caml_option.some(JsxRuntime.jsx(TextInput$Thin.make, {
                                        type_: "text",
                                        fullWidth: true,
                                        placeholder: Txt$Thin.Account.legalFirstNamePlaceholder.value,
                                        disabled: true,
                                        variant: "solid",
                                        value: legalFName,
                                        label: Txt$Thin.Account.legalFirstNameLabel.value,
                                        helperModule: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                  level: "body-sm",
                                                  children: Caml_option.some(Merge$Util.render(Txt$Thin.Account.legalFirstNameHelpText, SysCtx$Thin.vars))
                                                }))
                                      })),
                              xs: 12,
                              md: 6
                            }),
                        JsxRuntime.jsx(Grid, {
                              children: Caml_option.some(JsxRuntime.jsx(TextInput$Thin.make, {
                                        type_: "text",
                                        fullWidth: true,
                                        placeholder: Txt$Thin.Account.legalLastNamePlaceholder.value,
                                        disabled: true,
                                        variant: "solid",
                                        value: legalLName,
                                        label: Txt$Thin.Account.legalLastNameLabel.value,
                                        helperModule: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                  level: "body-sm",
                                                  children: Caml_option.some(Merge$Util.render(Txt$Thin.Account.legalLastNameHelpText, SysCtx$Thin.vars))
                                                }))
                                      })),
                              xs: 12,
                              md: 6
                            }),
                        JsxRuntime.jsx(Grid, {
                              children: Caml_option.some(JsxRuntime.jsx(TextInput$Thin.make, {
                                        type_: "text",
                                        fullWidth: true,
                                        disabled: true,
                                        variant: "solid",
                                        value: birthdate,
                                        label: Txt$Thin.Account.birthdateLabel.value,
                                        helperModule: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                  level: "body-sm",
                                                  children: Caml_option.some(Merge$Util.render(Txt$Thin.Account.birthdateHelpText, SysCtx$Thin.vars))
                                                }))
                                      })),
                              xs: 12,
                              md: 4
                            }),
                        JsxRuntime.jsx(Grid, {
                              children: Caml_option.some(JsxRuntime.jsxs(FormControl, {
                                        sx: {
                                          pb: 1
                                        },
                                        children: [
                                          JsxRuntime.jsx(FormLabel, {
                                                children: Caml_option.some(Txt$Thin.Account.citizenshipLabel.value)
                                              }),
                                          JsxRuntime.jsx(Select, {
                                                children: Caml_option.some(Belt_Array.map(countryTuples, (function (param) {
                                                            var code = param[0];
                                                            return JsxRuntime.jsx($$Option, {
                                                                        value: code,
                                                                        children: Caml_option.some(param[1])
                                                                      }, code);
                                                          }))),
                                                disabled: !isEditing,
                                                onChange: (function (param, v) {
                                                    Curry._1(setCountry, (function (param) {
                                                            return v;
                                                          }));
                                                  }),
                                                value: match$6[0]
                                              }),
                                          JsxRuntime.jsx(FormHelperText, {
                                                children: Caml_option.some(Txt$Thin.Account.citizenshipHelpText.value)
                                              })
                                        ]
                                      })),
                              xs: 6,
                              md: 3
                            }),
                        JsxRuntime.jsx(Grid, {
                              children: Caml_option.some(JsxRuntime.jsxs(FormControl, {
                                        sx: {
                                          pb: 1
                                        },
                                        children: [
                                          JsxRuntime.jsx(FormLabel, {
                                                children: Caml_option.some(Txt$Thin.Account.genderLabel.value)
                                              }),
                                          JsxRuntime.jsx(Select, {
                                                children: Caml_option.some(Belt_Array.map([
                                                          "M",
                                                          "F",
                                                          "X"
                                                        ], (function (g) {
                                                            return JsxRuntime.jsx($$Option, {
                                                                        value: g,
                                                                        children: Caml_option.some(genderToText(g))
                                                                      }, g);
                                                          }))),
                                                disabled: !isEditing,
                                                onChange: (function (param, v) {
                                                    Curry._1(setGender, (function (param) {
                                                            return v;
                                                          }));
                                                  }),
                                                value: match$3[0]
                                              }),
                                          JsxRuntime.jsx(FormHelperText, {
                                                children: Caml_option.some(Txt$Thin.Account.genderHelpText.value)
                                              })
                                        ]
                                      })),
                              xs: 6,
                              md: 2
                            })
                      ],
                      container: true,
                      spacing: 1,
                      sx: {
                        width: "100%"
                      }
                    }),
                JsxRuntime.jsx(Stack, {
                      sx: {
                        mt: 2
                      },
                      children: Caml_option.some(isEditing ? JsxRuntime.jsxs(Stack, {
                                  direction: "row",
                                  spacing: 1,
                                  children: [
                                    JsxRuntime.jsx(Button$Thin.make, {
                                          onClick: (function (param) {
                                              Curry._1(setIsEditing, (function (param) {
                                                      return false;
                                                    }));
                                            }),
                                          color: "primary",
                                          disabled: true,
                                          children: Caml_option.some(Txt$Thin.Account.saveButton.value)
                                        }),
                                    JsxRuntime.jsx(Button$Thin.make, {
                                          onClick: (function (param) {
                                              Curry._1(setIsEditing, (function (param) {
                                                      return false;
                                                    }));
                                              Curry._1(setFirstName, (function (param) {
                                                      return fName;
                                                    }));
                                              Curry._1(setLastName, (function (param) {
                                                      return lName;
                                                    }));
                                              Curry._1(setGender, (function (param) {
                                                      return docGender;
                                                    }));
                                              Curry._1(setCountry, (function (param) {
                                                      return citizenship;
                                                    }));
                                            }),
                                          variant: "outlined",
                                          color: "neutral",
                                          children: Caml_option.some(Txt$Thin.Account.cancelButton.value)
                                        })
                                  ]
                                }) : JsxRuntime.jsx(Typography, {
                                  color: "primary",
                                  level: "body-sm",
                                  sx: {
                                    cursor: "pointer",
                                    textDecoration: "underline"
                                  },
                                  children: Caml_option.some(Txt$Thin.Account.modifyProfileButton.value),
                                  onClick: (function (param) {
                                      Curry._1(setIsEditing, (function (param) {
                                              return true;
                                            }));
                                    })
                                }))
                    })
              ]
            });
}

var make = PersonalInformation;

exports.ProfileFragment = ProfileFragment;
exports.QueryFragment = QueryFragment;
exports.make = make;
/* react Not a pure module */
