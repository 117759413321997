// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var Date$Util = require("util/src/Date.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CustomScalars$Thin = require("../utils/CustomScalars.bs.js");

var Types = {};

var fragmentConverter = {"__root":{"preferredLastName":{"c":"CustomScalars.NonemptyString"},"preferredFirstName":{"c":"CustomScalars.NonemptyString"},"email":{"c":"CustomScalars.Email"},"docLastName":{"c":"CustomScalars.NonemptyString"},"docFirstName":{"c":"CustomScalars.NonemptyString"},"docBirthdate":{"c":"Util.Date.SQLDate.Exn"}}};

var fragmentConverterMap = {
  "Util.Date.SQLDate.Exn": Date$Util.SQLDate.Exn.parse,
  "CustomScalars.NonemptyString": CustomScalars$Thin.NonemptyString.parse,
  "CustomScalars.Email": CustomScalars$Thin.Email.parse
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

function paxDataStatus_decode($$enum) {
  if ($$enum === "INCOMPLETE" || $$enum === "CONFIRMED" || $$enum === "INVITED") {
    return $$enum;
  }
  
}

var paxDataStatus_fromString = paxDataStatus_decode;

var Utils = {
  paxDataStatus_decode: paxDataStatus_decode,
  paxDataStatus_fromString: paxDataStatus_fromString
};

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BDPassengerList_passenger",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "docFirstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "docLastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "docBirthdate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "preferredFirstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "preferredLastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "preferredFullName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paxDataStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    }
  ],
  "type": "BookingPassenger",
  "abstractKey": null
};

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* Date-Util Not a pure module */
