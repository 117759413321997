// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var Button = require("@mui/joy/Button").default;
var JsxRuntime = require("react/jsx-runtime");

function Button$1(props) {
  var children = props.children;
  var disabled = props.disabled;
  var size = props.size;
  var href = props.href;
  var color = props.color;
  var variant = props.variant;
  var component = props.component;
  var id = props.id;
  var ariaLabel = props.ariaLabel;
  var endDecorator = props.endDecorator;
  var startDecorator = props.startDecorator;
  var onClick = props.onClick;
  var loadingIndicator = props.loadingIndicator;
  var type_ = props.type_;
  var loading = props.loading;
  var fullWidth = props.fullWidth;
  var fullWidth$1 = fullWidth !== undefined ? fullWidth : false;
  var loading$1 = loading !== undefined ? loading : false;
  var variant$1 = variant !== undefined ? variant : "solid";
  var color$1 = color !== undefined ? color : "primary";
  var size$1 = size !== undefined ? size : "sm";
  var disabled$1 = disabled !== undefined ? disabled : false;
  var handleKeyup = function (e) {
    var key = e.key;
    switch (key) {
      case "Enter" :
          console.log("enter");
          return ;
      case "SPACE" :
          console.log("space");
          return ;
      default:
        return ;
    }
  };
  return JsxRuntime.jsx(Button, {
              fullWidth: fullWidth$1,
              loading: loading$1,
              type: type_ !== undefined ? type_ : "",
              loadingIndicator: Caml_option.some(loadingIndicator !== undefined ? Caml_option.valFromOption(loadingIndicator) : null),
              onClick: onClick !== undefined ? onClick : (function (param) {
                    
                  }),
              onKeyUp: handleKeyup,
              startDecorator: Caml_option.some(startDecorator !== undefined ? Caml_option.valFromOption(startDecorator) : null),
              endDecorator: Caml_option.some(endDecorator !== undefined ? Caml_option.valFromOption(endDecorator) : null),
              "aria-label": ariaLabel !== undefined ? ariaLabel : "",
              id: id !== undefined ? id : "",
              component: component !== undefined ? component : "",
              variant: variant$1,
              color: color$1,
              href: href !== undefined ? href : "",
              size: size$1,
              disabled: disabled$1,
              sx: {
                "&.Mui-disabled": {
                  backgroundColor: "background.level2"
                }
              },
              children: Caml_option.some(children !== undefined ? Caml_option.valFromOption(children) : null)
            });
}

var make = Button$1;

exports.make = make;
/* @mui/joy/Button Not a pure module */
