// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var BDHero$Thin = require("../components/booking-details/BDHero.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var SysCtx$Thin = require("../utils/SysCtx.bs.js");
var Grid = require("@mui/joy/Grid").default;
var Stack = require("@mui/joy/Stack").default;
var PageFrame$Thin = require("../components/wrappers/PageFrame.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var CustomScalars$Thin = require("../utils/CustomScalars.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var BDPassengerList$Thin = require("../components/booking-details/BDPassengerList.bs.js");
var BDBillingSummary$Thin = require("../components/booking-details/billing-payments/BDBillingSummary.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var BDStateroomDetails$Thin = require("../components/booking-details/BDStateroomDetails.bs.js");
var BDPageRefetchQuery_graphql$Thin = require("../__generated__/BDPageRefetchQuery_graphql.bs.js");
var BookingDetailsPage_query_graphql$Thin = require("../__generated__/BookingDetailsPage_query_graphql.bs.js");

var convertFragment = BookingDetailsPage_query_graphql$Thin.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(BookingDetailsPage_query_graphql$Thin.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BookingDetailsPage_query_graphql$Thin.node, convertFragment);
}

var makeRefetchVariables = BDPageRefetchQuery_graphql$Thin.Types.makeRefetchVariables;

var convertRefetchVariables = BDPageRefetchQuery_graphql$Thin.Internal.convertVariables;

function useRefetchable(fRef) {
  return RescriptRelay_Fragment.useRefetchableFragment(BookingDetailsPage_query_graphql$Thin.node, convertFragment, convertRefetchVariables, fRef);
}

var QueryFragment_lifecycleStatus_decode = BookingDetailsPage_query_graphql$Thin.Utils.lifecycleStatus_decode;

var QueryFragment_lifecycleStatus_fromString = BookingDetailsPage_query_graphql$Thin.Utils.lifecycleStatus_fromString;

var QueryFragment = {
  lifecycleStatus_decode: QueryFragment_lifecycleStatus_decode,
  lifecycleStatus_fromString: QueryFragment_lifecycleStatus_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  makeRefetchVariables: makeRefetchVariables,
  convertRefetchVariables: convertRefetchVariables,
  useRefetchable: useRefetchable
};

function BookingDetailsPage(props) {
  var fragmentRefs = props.fragmentRefs;
  var match = useRefetchable(fragmentRefs);
  var refetch = match[1];
  var query = match[0];
  var refetch$1 = function (param) {
    return Curry._4(refetch, Curry._2(makeRefetchVariables, undefined, undefined), /* StoreAndNetwork */2, undefined, undefined);
  };
  var match$1 = query.bookingDetails;
  var booking;
  if (match$1 !== undefined) {
    var node = Belt_Array.get(match$1.nodes, 0);
    booking = node !== undefined ? node : undefined;
  } else {
    booking = undefined;
  }
  var bookingListItem = booking !== undefined ? booking.bookingListitem : undefined;
  var isBc = bookingListItem !== undefined ? bookingListItem.isBc : false;
  var bookingId = bookingListItem !== undefined ? CustomScalars$Thin.BookingId.serialize(bookingListItem.bookingId) : "";
  var isActive = booking !== undefined ? booking.lifecycleStatus === "ACTIVE" : false;
  var match$2 = query.numConnected;
  var areConnectedAccts = match$2 !== undefined ? match$2.totalCount > 0 : false;
  React.useEffect((function () {
          if (booking !== undefined) {
            
          } else {
            RescriptReactRouter.push(SysCtx$Thin.vars.bookingsLink);
          }
        }), [booking]);
  if (booking !== undefined) {
    return JsxRuntime.jsx(PageFrame$Thin.make, {
                children: JsxRuntime.jsxs(Grid, {
                      children: [
                        JsxRuntime.jsx(Grid, {
                              children: Caml_option.some(bookingListItem !== undefined ? JsxRuntime.jsx(BDHero$Thin.make, {
                                          fragmentRefs: bookingListItem.fragmentRefs
                                        }) : null),
                              xs: 12
                            }),
                        JsxRuntime.jsx(Grid, {
                              children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                        sx: {
                                          height: "100%"
                                        },
                                        children: Caml_option.some(JsxRuntime.jsx(BDStateroomDetails$Thin.make, {
                                                  fragmentRefs: booking.fragmentRefs,
                                                  isBc: isBc,
                                                  bookingId: bookingId,
                                                  isActive: isActive
                                                }))
                                      })),
                              xs: 12,
                              md: 6
                            }),
                        JsxRuntime.jsx(Grid, {
                              children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                        sx: {
                                          height: "100%"
                                        },
                                        children: Caml_option.some(JsxRuntime.jsx(BDBillingSummary$Thin.make, {
                                                  fragmentRefs: booking.fragmentRefs,
                                                  isActive: isActive
                                                }))
                                      })),
                              xs: 12,
                              md: 6
                            }),
                        JsxRuntime.jsx(Grid, {
                              children: Caml_option.some(JsxRuntime.jsx(BDPassengerList$Thin.make, {
                                        fragmentRefs: booking.fragmentRefs,
                                        currentUserEmail: query.currentUserEmail,
                                        isBc: isBc,
                                        isActive: isActive,
                                        refetch: refetch$1,
                                        queryFrag: fragmentRefs,
                                        areConnectedAccts: areConnectedAccts
                                      })),
                              xs: 12
                            })
                      ],
                      container: true,
                      rowSpacing: 2,
                      columnSpacing: 2,
                      sx: {
                        width: "100%",
                        flexGrow: 1
                      }
                    }),
                backgroundColor: "background.level1"
              });
  } else {
    return null;
  }
}

var make = BookingDetailsPage;

exports.QueryFragment = QueryFragment;
exports.make = make;
/* react Not a pure module */
