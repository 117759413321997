// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var AccountAccessSectionQuery_graphql$Thin = require("./AccountAccessSectionQuery_graphql.bs.js");

var Types = {};

var fragmentConverter = {};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, undefined, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: undefined,
  convertFragment: convertFragment
};

function custSignonStatus_decode($$enum) {
  if ($$enum === "PENDING_2FA" || $$enum === "VERIFY_EMAIL" || $$enum === "ANONYMOUS" || $$enum === "CLEAR_JWT" || $$enum === "SIGNED_ON" || $$enum === "INVITED") {
    return $$enum;
  }
  
}

var custSignonStatus_fromString = custSignonStatus_decode;

var Utils = {
  custSignonStatus_decode: custSignonStatus_decode,
  custSignonStatus_fromString: custSignonStatus_fromString
};

function makeNode(rescript_graphql_node_AccountAccessSectionQuery) {
  return ({
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": rescript_graphql_node_AccountAccessSectionQuery
    }
  },
  "name": "AccountAccessSection_query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentUserEmail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentUserSignonStatus",
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
});
}

var node = makeNode(AccountAccessSectionQuery_graphql$Thin.node);

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
