// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var Alert = require("@mui/joy/Alert").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var Info = require("@mui/icons-material/Info").default;
var Report = require("@mui/icons-material/Report").default;
var Warning = require("@mui/icons-material/Warning").default;
var CheckCircle = require("@mui/icons-material/CheckCircle").default;

function Alert$1(props) {
  var variant = props.variant;
  var startDecorator = props.startDecorator;
  var size = props.size;
  var role = props.role;
  var invertedColors = props.invertedColors;
  var endDecorator = props.endDecorator;
  var component = props.component;
  var type_ = props.type_;
  var textColor = props.textColor;
  var textColor$1 = textColor !== undefined ? textColor : "inherit";
  var type_$1 = type_ !== undefined ? type_ : "neutral";
  var component$1 = component !== undefined ? component : "div";
  var endDecorator$1 = endDecorator !== undefined ? Caml_option.valFromOption(endDecorator) : null;
  var invertedColors$1 = invertedColors !== undefined ? invertedColors : false;
  var role$1 = role !== undefined ? role : "alert";
  var size$1 = size !== undefined ? size : "md";
  if (startDecorator !== undefined) {
    Caml_option.valFromOption(startDecorator);
  }
  var variant$1 = variant !== undefined ? variant : "soft";
  var tmp;
  switch (type_$1) {
    case "error" :
        tmp = "danger";
        break;
    case "primary" :
        tmp = "primary";
        break;
    case "success" :
        tmp = "success";
        break;
    case "warning" :
        tmp = "warning";
        break;
    default:
      tmp = "neutral";
  }
  var tmp$1;
  switch (type_$1) {
    case "error" :
        tmp$1 = JsxRuntime.jsx(Report, {});
        break;
    case "success" :
        tmp$1 = JsxRuntime.jsx(CheckCircle, {});
        break;
    case "warning" :
        tmp$1 = JsxRuntime.jsx(Warning, {});
        break;
    default:
      tmp$1 = JsxRuntime.jsx(Info, {});
  }
  return JsxRuntime.jsx(Alert, {
              children: Caml_option.some(JsxRuntime.jsx(Typography, {
                        level: "body-sm",
                        component: "p",
                        textColor: textColor$1,
                        children: Caml_option.some(props.message)
                      })),
              color: tmp,
              component: component$1,
              endDecorator: Caml_option.some(endDecorator$1),
              invertedColors: invertedColors$1,
              role: role$1,
              size: size$1,
              startDecorator: Caml_option.some(tmp$1),
              sx: {
                alignItems: "flex-start"
              },
              variant: variant$1
            });
}

var make = Alert$1;

exports.make = make;
/* @mui/joy/Alert Not a pure module */
