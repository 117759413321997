// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Txt$Thin = require("../../../text/Txt.bs.js");
var DateFns = require("date-fns");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thin = require("../../../utils/Common.bs.js");
var SysCtx$Thin = require("../../../utils/SysCtx.bs.js");
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var JsxRuntime = require("react/jsx-runtime");
var CustomScalars$Thin = require("../../../utils/CustomScalars.bs.js");
var Typography = require("@mui/joy/Typography").default;
var BDBillPayTable$Thin = require("./BDBillPayTable.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var InfoOutlined = require("@mui/icons-material/InfoOutlined").default;
var BDInvoiceDetails_detail_graphql$Thin = require("../../../__generated__/BDInvoiceDetails_detail_graphql.bs.js");
var CheckCircleOutline = require("@mui/icons-material/CheckCircleOutline").default;

var convertFragment = BDInvoiceDetails_detail_graphql$Thin.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(BDInvoiceDetails_detail_graphql$Thin.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, BDInvoiceDetails_detail_graphql$Thin.node, convertFragment);
}

var DetailFragment_pmtLifecycleStatus_decode = BDInvoiceDetails_detail_graphql$Thin.Utils.pmtLifecycleStatus_decode;

var DetailFragment_pmtLifecycleStatus_fromString = BDInvoiceDetails_detail_graphql$Thin.Utils.pmtLifecycleStatus_fromString;

var DetailFragment = {
  pmtLifecycleStatus_decode: DetailFragment_pmtLifecycleStatus_decode,
  pmtLifecycleStatus_fromString: DetailFragment_pmtLifecycleStatus_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

function BDInvoiceDetails$TableLine(props) {
  var line = props.line;
  if (line.TAG === /* Billing */0) {
    var bl = line._0;
    return JsxRuntime.jsxs("tr", {
                children: [
                  JsxRuntime.jsx("td", {
                        children: JsxRuntime.jsx(Typography, {
                              level: "inherit",
                              noWrap: true,
                              sx: {
                                fontStyle: bl.lineAmount <= 0.0 ? "italic" : "normal"
                              },
                              textColor: "inherit",
                              fontWeight: "inherit",
                              children: Caml_option.some(bl.billingLineDescription)
                            })
                      }),
                  JsxRuntime.jsx("td", {
                        children: JsxRuntime.jsx(Stack, {
                              direction: "row",
                              justifyContent: "flex-end",
                              sx: {
                                width: "100%"
                              },
                              children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                        level: "inherit",
                                        noWrap: true,
                                        sx: {
                                          fontStyle: bl.lineAmount <= 0.0 ? "italic" : "normal"
                                        },
                                        textColor: "inherit",
                                        fontWeight: "inherit",
                                        children: Caml_option.some(Common$Thin.formatCurrency(bl.lineAmount))
                                      }))
                            })
                      })
                ]
              });
  }
  var pl = line._0;
  var dateRender = pl.shouldRenderStatus ? pl.lifecycleStatus : pl.date;
  return JsxRuntime.jsxs("tr", {
              children: [
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Typography, {
                            level: "inherit",
                            noWrap: true,
                            sx: {
                              fontStyle: pl.amount <= 0.0 ? "italic" : "normal"
                            },
                            textColor: "inherit",
                            fontWeight: "inherit",
                            children: Caml_option.some(dateRender)
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Typography, {
                            level: "inherit",
                            noWrap: true,
                            sx: {
                              fontStyle: pl.amount <= 0.0 ? "italic" : "normal"
                            },
                            textColor: "inherit",
                            fontWeight: "inherit",
                            children: Caml_option.some(pl.payer)
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Typography, {
                            startDecorator: Caml_option.some(null),
                            level: "inherit",
                            noWrap: true,
                            sx: {
                              fontStyle: pl.amount <= 0.0 ? "italic" : "normal"
                            },
                            textColor: "inherit",
                            fontWeight: "inherit",
                            children: Caml_option.some(pl.paymentMethod)
                          })
                    }),
                JsxRuntime.jsx("td", {
                      children: JsxRuntime.jsx(Stack, {
                            direction: "row",
                            justifyContent: "flex-end",
                            sx: {
                              width: "100%"
                            },
                            children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                      level: "inherit",
                                      noWrap: true,
                                      sx: {
                                        fontStyle: pl.amount <= 0.0 ? "italic" : "normal"
                                      },
                                      textColor: "inherit",
                                      fontWeight: "inherit",
                                      children: Caml_option.some(Common$Thin.formatCurrency(pl.amount))
                                    }))
                          })
                    })
              ]
            });
}

var TableLine = {
  make: BDInvoiceDetails$TableLine
};

function BDInvoiceDetails$InvoiceDetails(props) {
  var isActive = props.isActive;
  var content = props.content;
  var billingColumns = [
    {
      heading: Txt$Thin.BookingDetails.descriptionTableHeading.value,
      width: "8rem"
    },
    {
      heading: Txt$Thin.BookingDetails.amountTableHeading.value,
      width: "2rem"
    }
  ];
  var paymentColumns = [
    {
      heading: Txt$Thin.BookingDetails.dateTableHeading.value,
      width: "3rem"
    },
    {
      heading: Txt$Thin.BookingDetails.payerTableHeading.value,
      width: "3rem"
    },
    {
      heading: Txt$Thin.BookingDetails.paymentMethodTableHeading.value,
      width: "4rem"
    },
    {
      heading: Txt$Thin.BookingDetails.amountTableHeading.value,
      width: "3rem"
    }
  ];
  var val = content.totalAmount;
  var val$1 = content.totalAmount;
  var match = content.totalAmount > 0.0;
  var val$2 = content.totalAmount;
  return JsxRuntime.jsxs(Stack, {
              direction: "column",
              spacing: 4,
              children: [
                JsxRuntime.jsxs(Stack, {
                      children: [
                        JsxRuntime.jsx(Stack, {
                              direction: "row",
                              justifyContent: "space-between",
                              children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                        level: "h4",
                                        component: "h4",
                                        children: Caml_option.some(Txt$Thin.BookingDetails.bookingChargesHeading.value)
                                      }))
                            }),
                        content.billing.billingLines.length !== 0 ? JsxRuntime.jsx(BDBillPayTable$Thin.make, {
                                columns: billingColumns,
                                totalDescription: Txt$Thin.BookingDetails.totalBilledTableFooter.value,
                                total: content.billing.billingLineSubtotal,
                                pendingDescription: Txt$Thin.BookingDetails.pendingBilledTableFooter.value,
                                pendingTotal: 0.0,
                                isActive: isActive,
                                children: Belt_Array.mapWithIndex(content.billing.billingLines, (function (i, line) {
                                        return JsxRuntime.jsx(BDInvoiceDetails$TableLine, {
                                                    line: {
                                                      TAG: /* Billing */0,
                                                      _0: line
                                                    }
                                                  }, String(i));
                                      }))
                              }) : JsxRuntime.jsx(Stack, {
                                direction: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                sx: {
                                  height: "7rem"
                                },
                                children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                          level: "title-lg",
                                          component: "p",
                                          textColor: "neutral.500",
                                          children: Caml_option.some(isActive ? Txt$Thin.BookingDetails.noBillingLines.value : Txt$Thin.BookingDetails.noBillingLinesInactive.value)
                                        }))
                              })
                      ]
                    }),
                JsxRuntime.jsxs(Stack, {
                      children: [
                        JsxRuntime.jsx(Stack, {
                              direction: "row",
                              justifyContent: "space-between",
                              children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                        level: "h4",
                                        component: "h4",
                                        children: Caml_option.some(Txt$Thin.BookingDetails.paymentsHeading.value)
                                      }))
                            }),
                        content.payment.paymentLines.length !== 0 ? JsxRuntime.jsx(BDBillPayTable$Thin.make, {
                                columns: paymentColumns,
                                totalDescription: Txt$Thin.BookingDetails.totalPaidTableFooter.value,
                                total: content.payment.completedPaymentSubtotal,
                                pendingDescription: Txt$Thin.BookingDetails.pendingPaidTableFooter.value,
                                pendingTotal: content.payment.pendingPaymentSubtotal,
                                isActive: isActive,
                                children: Belt_Array.mapWithIndex(content.payment.paymentLines, (function (i, line) {
                                        return JsxRuntime.jsx(BDInvoiceDetails$TableLine, {
                                                    line: {
                                                      TAG: /* Payment */1,
                                                      _0: line
                                                    }
                                                  }, String(i));
                                      }))
                              }) : JsxRuntime.jsx(Stack, {
                                direction: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                sx: {
                                  height: "7rem"
                                },
                                children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                          level: "title-lg",
                                          component: "p",
                                          textColor: "neutral.500",
                                          children: Caml_option.some(isActive ? Txt$Thin.BookingDetails.noPaymentLines.value : Txt$Thin.BookingDetails.noPaymentLinesInactive.value)
                                        }))
                              }),
                        JsxRuntime.jsx(Sheet, {
                              sx: {
                                border: "1px solid",
                                borderColor: val > 0.0 ? "divider" : "#037E3A",
                                backgroundColor: val$1 > 0.0 ? "neutral.100" : "rgba(3, 126, 58, 0.11)",
                                borderRadius: "4px",
                                p: 2,
                                my: 2
                              },
                              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                        direction: "column",
                                        spacing: 1,
                                        children: [
                                          JsxRuntime.jsxs(Stack, {
                                                direction: "row",
                                                justifyContent: "space-between",
                                                children: [
                                                  JsxRuntime.jsx(Typography, {
                                                        startDecorator: Caml_option.some(match ? JsxRuntime.jsx(InfoOutlined, {
                                                                    fontSize: "md"
                                                                  }) : JsxRuntime.jsx(CheckCircleOutline, {
                                                                    fontSize: "md",
                                                                    sx: {
                                                                      color: "#037E3A",
                                                                      pr: 1
                                                                    }
                                                                  })),
                                                        level: "title-sm",
                                                        textColor: val$2 > 0.0 ? "inherit" : "#037E3A",
                                                        children: Caml_option.some(Txt$Thin.BookingDetails.remainingBalance.value)
                                                      }),
                                                  JsxRuntime.jsx(Typography, {
                                                        level: "title-sm",
                                                        textColor: "text.secondary",
                                                        children: Caml_option.some(Common$Thin.formatCurrency(content.totalAmount))
                                                      })
                                                ]
                                              }),
                                          content.arePendingPayments ? JsxRuntime.jsxs(Stack, {
                                                  direction: "row",
                                                  justifyContent: "space-between",
                                                  children: [
                                                    JsxRuntime.jsx(Typography, {
                                                          level: "body-xs",
                                                          sx: {
                                                            fontStyle: "italic"
                                                          },
                                                          children: Caml_option.some(Txt$Thin.BookingDetails.balanceAfterPending.value)
                                                        }),
                                                    JsxRuntime.jsx(Typography, {
                                                          level: "body-xs",
                                                          textColor: "text.secondary",
                                                          children: Caml_option.some(Common$Thin.formatCurrency(content.totalAmountIncludingPending))
                                                        })
                                                  ]
                                                }) : null
                                        ]
                                      }))
                            })
                      ]
                    })
              ]
            });
}

var InvoiceDetails = {
  make: BDInvoiceDetails$InvoiceDetails
};

function BDInvoiceDetails(props) {
  var data = RescriptRelay_Fragment.useFragment(BDInvoiceDetails_detail_graphql$Thin.node, convertFragment, props.fragmentRefs);
  var match = data.billing_summary;
  var completedPaymentSubtotal = match !== undefined ? match.completedPaymentSubtotal : 0.0;
  var match$1 = data.billing_summary;
  var pendingPaymentSubtotal = match$1 !== undefined ? match$1.pendingPaymentSubtotal : 0.0;
  var match$2 = data.billing_summary;
  var totalAmountIncludingPending = match$2 !== undefined ? match$2.totalAmountIncludingPending : 0.0;
  var match$3 = data.billing_summary;
  var billingLineSubtotal = match$3 !== undefined ? match$3.billingLineSubtotal : 0.0;
  var match$4 = data.billing_summary;
  var totalAmount = match$4 !== undefined ? match$4.totalAmount : 0.0;
  var billingLines = Belt_Array.map(data.billing_line.nodes, (function (node) {
          return {
                  billingLineDescription: CustomScalars$Thin.NonemptyString.serialize(node.billingLineDescription),
                  lineAmount: node.lineAmount
                };
        }));
  var paymentLines = Belt_Array.map(data.payment.nodes, (function (node) {
          return {
                  date: DateFns.format(DateFns.parseISO(node.createdAt), SysCtx$Thin.vars.dateFormat),
                  payer: node.payerDisplayName,
                  lifecycleStatus: Common$Thin.PmtLifecycle.toString(node.lifecycleStatus),
                  shouldRenderStatus: Common$Thin.PmtLifecycle.shouldRenderStatus(node.lifecycleStatus),
                  paymentMethod: CustomScalars$Thin.NonemptyString.serialize(node.processorDescription),
                  amount: node.netAmount
                };
        }));
  var content_billing = {
    billingLines: billingLines,
    billingLineSubtotal: billingLineSubtotal
  };
  var content_payment = {
    completedPaymentSubtotal: completedPaymentSubtotal,
    pendingPaymentSubtotal: pendingPaymentSubtotal,
    paymentLines: paymentLines
  };
  var content_arePendingPayments = pendingPaymentSubtotal > 0.0;
  var content = {
    billing: content_billing,
    payment: content_payment,
    totalAmountIncludingPending: totalAmountIncludingPending,
    arePendingPayments: content_arePendingPayments,
    totalAmount: totalAmount
  };
  return JsxRuntime.jsx(BDInvoiceDetails$InvoiceDetails, {
              content: content,
              isActive: props.isActive
            });
}

var make = BDInvoiceDetails;

exports.DetailFragment = DetailFragment;
exports.TableLine = TableLine;
exports.InvoiceDetails = InvoiceDetails;
exports.make = make;
/* Txt-Thin Not a pure module */
