// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CustomScalars$Thin = require("../utils/CustomScalars.bs.js");

function makeRefetchVariables(brandFamily, param) {
  return {
          brandFamily: brandFamily
        };
}

var Types = {
  makeRefetchVariables: makeRefetchVariables
};

var variablesConverter = {"__root":{"brandFamily":{"c":"CustomScalars.BrandFamilySlug"}}};

var variablesConverterMap = {
  "CustomScalars.BrandFamilySlug": CustomScalars$Thin.BrandFamilySlug.serialize
};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, variablesConverterMap, undefined);
}

var wrapResponseConverter = {"__root":{"":{"f":""}}};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, undefined, null);
}

var responseConverter = {"__root":{"":{"f":""}}};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, undefined, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: variablesConverterMap,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: undefined,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: undefined,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse
};

var Utils = {};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "brandFamily"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currentUserEmail",
  "storageKey": null
},
v2 = [
  {
    "kind": "Variable",
    "name": "brandFamily",
    "variableName": "brandFamily"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountRouteQuery",
    "selections": [
      (v1/*: any*/),
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "IsLoggedIn_query"
      },
      {
        "args": (v2/*: any*/),
        "kind": "FragmentSpread",
        "name": "StandardLayout_query"
      },
      {
        "args": (v2/*: any*/),
        "kind": "FragmentSpread",
        "name": "AccountPage_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccountRouteQuery",
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "currentUserSignonStatus",
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "brandFamilySlug",
                "variableName": "brandFamily"
              }
            ],
            "kind": "ObjectValue",
            "name": "condition"
          }
        ],
        "concreteType": "CurrentUserProfilesConnection",
        "kind": "LinkedField",
        "name": "currentUserProfiles",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CurrentUserProfile",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "legalDocumentFirstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "legalDocumentLastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "legalDocumentGender",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "legalDocumentBirthdate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "legalDocumentCitizenship",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CountriesConnection",
        "kind": "LinkedField",
        "name": "countries",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Country",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "alpha3",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "englishName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ecb6265e260dad847efad2bcdc6263ae",
    "id": null,
    "metadata": {},
    "name": "AccountRouteQuery",
    "operationKind": "query",
    "text": "query AccountRouteQuery(\n  $brandFamily: BrandFamilySlug!\n) {\n  currentUserEmail\n  ...IsLoggedIn_query\n  ...StandardLayout_query_2PeJLC\n  ...AccountPage_query_2PeJLC\n}\n\nfragment AccountAccessSection_query on Query {\n  currentUserEmail\n  currentUserSignonStatus\n}\n\nfragment AccountPage_query_2PeJLC on Query {\n  currentUserProfiles(condition: {brandFamilySlug: $brandFamily}) {\n    nodes {\n      firstName\n      lastName\n      ...PersonalInformation_profile\n    }\n  }\n  ...AccountAccessSection_query\n  ...PersonalInformation_query\n}\n\nfragment IsLoggedIn_query on Query {\n  currentUserSignonStatus\n}\n\nfragment Navigation_query_2PeJLC on Query {\n  currentUserEmail\n  currentUserSignonStatus\n  currentUserProfiles(condition: {brandFamilySlug: $brandFamily}) {\n    nodes {\n      firstName\n      lastName\n    }\n  }\n}\n\nfragment PersonalInformation_profile on CurrentUserProfile {\n  firstName\n  lastName\n  legalDocumentFirstName\n  legalDocumentLastName\n  legalDocumentGender\n  legalDocumentBirthdate\n  legalDocumentCitizenship\n}\n\nfragment PersonalInformation_query on Query {\n  countries {\n    nodes {\n      alpha3\n      englishName\n      id\n    }\n  }\n}\n\nfragment StandardLayout_query_2PeJLC on Query {\n  currentUserSignonStatus\n  currentUserEmail\n  ...Navigation_query_2PeJLC\n}\n"
  }
};
})());

var include = RescriptRelay.MakeLoadQuery({
      query: node,
      convertVariables: convertVariables
    });

var load = include.load;

var queryRefToObservable = include.queryRefToObservable;

var queryRefToPromise = include.queryRefToPromise;

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
exports.load = load;
exports.queryRefToObservable = queryRefToObservable;
exports.queryRefToPromise = queryRefToPromise;
/* node Not a pure module */
