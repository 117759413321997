// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Txt$Thin = require("../../../text/Txt.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var List = require("@mui/joy/List").default;
var Stack = require("@mui/joy/Stack").default;
var ListItem = require("@mui/joy/ListItem").default;
var JsxRuntime = require("react/jsx-runtime");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var ListItemButton = require("@mui/joy/ListItemButton").default;
var InsecureJWTStorage$Util = require("util/src/InsecureJWTStorage.bs.js");
var ListItemContent = require("@mui/joy/ListItemContent").default;
var Logout = require("@mui/icons-material/Logout").default;
var ListItemDecorator = require("@mui/joy/ListItemDecorator").default;
var AccountCircle = require("@mui/icons-material/AccountCircle").default;
var DirectionsBoat = require("@mui/icons-material/DirectionsBoat").default;

function Sidebar(props) {
  var logout = function (param) {
    InsecureJWTStorage$Util.$$delete(undefined);
    ((window.location.reload()));
  };
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var match = url.path;
  var tmp;
  tmp = match && match.hd === "bookings" && !match.tl ? true : false;
  var match$1 = url.path;
  var tmp$1;
  tmp$1 = match$1 && match$1.hd === "account" && !match$1.tl ? true : false;
  return JsxRuntime.jsx(Stack, {
              direction: "column",
              justifyContent: "flex-start",
              sx: {
                display: {
                  xs: "none",
                  md: "flex"
                },
                width: "11rem",
                flexShrink: 0,
                overflowY: "auto",
                borderRight: 1,
                borderColor: "neutral.200",
                backgroundColor: "background.surface",
                p: 1
              },
              children: Caml_option.some(JsxRuntime.jsxs(List, {
                        children: [
                          JsxRuntime.jsxs(List, {
                                children: [
                                  JsxRuntime.jsx(ListItem, {
                                        children: Caml_option.some(JsxRuntime.jsxs(ListItemButton, {
                                                  children: [
                                                    JsxRuntime.jsx(ListItemDecorator, {
                                                          children: Caml_option.some(JsxRuntime.jsx(DirectionsBoat, {}))
                                                        }),
                                                    JsxRuntime.jsx(ListItemContent, {
                                                          children: Caml_option.some(Txt$Thin.Nav.bookings.value)
                                                        })
                                                  ],
                                                  onClick: (function (param) {
                                                      RescriptReactRouter.push("/bookings");
                                                    }),
                                                  selected: tmp,
                                                  sx: {
                                                    borderRadius: "sm"
                                                  }
                                                }))
                                      }),
                                  JsxRuntime.jsx(ListItem, {
                                        children: Caml_option.some(JsxRuntime.jsxs(ListItemButton, {
                                                  children: [
                                                    JsxRuntime.jsx(ListItemDecorator, {
                                                          children: Caml_option.some(JsxRuntime.jsx(AccountCircle, {}))
                                                        }),
                                                    JsxRuntime.jsx(ListItemContent, {
                                                          children: Caml_option.some(Txt$Thin.Nav.account.value)
                                                        })
                                                  ],
                                                  onClick: (function (param) {
                                                      RescriptReactRouter.push("/account");
                                                    }),
                                                  selected: tmp$1,
                                                  sx: {
                                                    borderRadius: "sm"
                                                  }
                                                }))
                                      })
                                ],
                                size: "md",
                                sx: {
                                  gap: 1,
                                  flexGrow: 1
                                }
                              }),
                          JsxRuntime.jsx(ListItem, {
                                children: Caml_option.some(JsxRuntime.jsxs(ListItemButton, {
                                          children: [
                                            JsxRuntime.jsx(ListItemDecorator, {
                                                  children: Caml_option.some(JsxRuntime.jsx(Logout, {}))
                                                }),
                                            JsxRuntime.jsx(ListItemContent, {
                                                  children: Caml_option.some(Txt$Thin.Nav.logout.value)
                                                })
                                          ],
                                          onClick: logout,
                                          sx: {
                                            borderRadius: "sm"
                                          }
                                        })),
                                sx: {
                                  flexShrink: 0
                                }
                              })
                        ],
                        sx: {
                          display: "flex",
                          flexDirection: "column",
                          height: "100%",
                          overflowY: "visible"
                        }
                      }))
            });
}

var make = Sidebar;

exports.make = make;
/* Txt-Thin Not a pure module */
