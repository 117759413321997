// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Txt$Thin = require("../../../text/Txt.bs.js");
var SysCtx$Thin = require("../../../utils/SysCtx.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var InsecureJWTStorage$Util = require("util/src/InsecureJWTStorage.bs.js");
var ChangePasswordSubmit_ChangePasswordMutation_graphql$Thin = require("../../../__generated__/ChangePasswordSubmit_ChangePasswordMutation_graphql.bs.js");

var convertVariables = ChangePasswordSubmit_ChangePasswordMutation_graphql$Thin.Internal.convertVariables;

var convertResponse = ChangePasswordSubmit_ChangePasswordMutation_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = ChangePasswordSubmit_ChangePasswordMutation_graphql$Thin.Internal.convertWrapRawResponse;

function commitMutation(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8) {
  return RescriptRelay_Mutation.commitMutation(convertVariables, ChangePasswordSubmit_ChangePasswordMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse, param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8);
}

function use(param) {
  return RescriptRelay_Mutation.useMutation(convertVariables, ChangePasswordSubmit_ChangePasswordMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse, param);
}

var ChangePasswordMutation = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation(param) {
  var match = use(undefined);
  var mutate = match[0];
  var match$1 = React.useState(function () {
        return "";
      });
  var currentPassword = match$1[0];
  var match$2 = React.useState(function () {
        return "";
      });
  var newPassword = match$2[0];
  var match$3 = React.useState(function () {
        return "";
      });
  var match$4 = React.useState(function () {
        
      });
  var setError = match$4[1];
  var match$5 = React.useState(function () {
        return false;
      });
  var setSuccess = match$5[1];
  var onSubmit = function (e) {
    e.preventDefault();
    Curry._1(setError, (function (param) {
            
          }));
    Curry._8(mutate, {
          input: {
            allegedCurrentPass: currentPassword,
            newPass: newPassword
          }
        }, undefined, undefined, (function (store, resp) {
            var match = resp.changePassword.boolean;
            if (match !== undefined && match) {
              store.invalidateStore();
              setTimeout((function (param) {
                      RescriptReactRouter.push("" + SysCtx$Thin.vars.loginLink + "?" + SysCtx$Thin.vars.loginRedirectParam + "=" + SysCtx$Thin.vars.accountLink + "");
                    }), 3000);
              return ;
            }
            
          }), (function (resp, err) {
            if (err === undefined) {
              var match = resp.changePassword.boolean;
              if (match !== undefined && match) {
                InsecureJWTStorage$Util.$$delete(undefined);
                Curry._1(setSuccess, (function (param) {
                        return true;
                      }));
                setTimeout((function (param) {
                        RescriptReactRouter.push("" + SysCtx$Thin.vars.loginLink + "?" + SysCtx$Thin.vars.loginRedirectParam + "=" + SysCtx$Thin.vars.accountLink + "");
                      }), 3000);
                return ;
              }
              Curry._1(setError, (function (param) {
                      return Txt$Thin.Account.passwordModalError.value;
                    }));
              return ;
            }
            Curry._1(setError, (function (param) {
                    return Txt$Thin.Account.passwordModalError.value;
                  }));
          }), (function (param) {
            Curry._1(setError, (function (param) {
                    return Txt$Thin.Account.heading.value;
                  }));
          }), undefined, undefined);
  };
  return {
          isMutating: match[1],
          currentPassword: currentPassword,
          setCurrentPassword: match$1[1],
          newPassword: newPassword,
          setNewPassword: match$2[1],
          confirmPassword: match$3[0],
          setConfirmPassword: match$3[1],
          error: match$4[0],
          setError: setError,
          success: match$5[0],
          onSubmit: onSubmit
        };
}

exports.ChangePasswordMutation = ChangePasswordMutation;
exports.useMutation = useMutation;
/* react Not a pure module */
