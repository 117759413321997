// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Js_string = require("rescript/lib/js/js_string.js");
var Theme$Thin = require("./components/wrappers/Theme.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var SysCtx$Thin = require("./utils/SysCtx.bs.js");
var ClaimRoute$Thin = require("./routes/ClaimRoute.bs.js");
var LoginRoute$Thin = require("./routes/LoginRoute.bs.js");
var VerifyRoute$Thin = require("./routes/VerifyRoute.bs.js");
var AccountRoute$Thin = require("./routes/AccountRoute.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var BookingsRoute$Thin = require("./routes/BookingsRoute.bs.js");
var CustomScalars$Thin = require("./utils/CustomScalars.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var ResetPasswordRoute$Thin = require("./routes/ResetPasswordRoute.bs.js");
var BookingDetailsRoute$Thin = require("./routes/BookingDetailsRoute.bs.js");

function ThinApp$RedirectToLogin(props) {
  React.useEffect((function () {
          RescriptReactRouter.push(SysCtx$Thin.vars.loginLink);
        }), []);
  return JsxRuntime.jsx("div", {});
}

var RedirectToLogin = {
  make: ThinApp$RedirectToLogin
};

function ThinApp(props) {
  var urlInitial = RescriptReactRouter.useUrl(undefined, undefined);
  var url = React.useDeferredValue(urlInitial);
  var getSubdomain = function (url) {
    if (!Js_string.includes("garpulon.io", url)) {
      return "garpcocruise";
    }
    var parts = Js_string.split(".", url);
    var len = parts.length;
    if (len !== 3) {
      if (len !== 4) {
        return "garpcocruise";
      }
      var match = parts[0];
      if (match !== "www") {
        return "garpcocruise";
      }
      var subdomain = parts[1];
      var match$1 = parts[2];
      if (match$1 !== "garpulon") {
        return "garpcocruise";
      }
      var match$2 = parts[3];
      if (match$2 === "io") {
        return subdomain;
      } else {
        return "garpcocruise";
      }
    }
    var subdomain$1 = parts[0];
    var match$3 = parts[1];
    if (match$3 !== "garpulon") {
      return "garpcocruise";
    }
    var match$4 = parts[2];
    if (match$4 === "io") {
      return subdomain$1;
    } else {
      return "garpcocruise";
    }
  };
  var brandFamily = CustomScalars$Thin.BrandFamilySlug.parse(getSubdomain(window.location.hostname));
  console.log(window.location.hostname);
  console.log(url.path);
  var match = url.path;
  var tmp;
  if (match) {
    switch (match.hd) {
      case "account" :
          tmp = match.tl ? "Hello World" : JsxRuntime.jsx(AccountRoute$Thin.make, {
                  brandFamily: brandFamily
                });
          break;
      case "bookings" :
          var match$1 = match.tl;
          tmp = match$1 ? (
              match$1.tl ? "Hello World" : JsxRuntime.jsx(BookingDetailsRoute$Thin.make, {
                      bookingId: match$1.hd,
                      brandFamily: brandFamily
                    })
            ) : JsxRuntime.jsx(BookingsRoute$Thin.make, {
                  brandFamily: brandFamily
                });
          break;
      case "claim" :
          tmp = match.tl ? "Hello World" : JsxRuntime.jsx(ClaimRoute$Thin.make, {
                  brandFamily: brandFamily
                });
          break;
      case "login" :
          tmp = match.tl ? "Hello World" : JsxRuntime.jsx(LoginRoute$Thin.make, {
                  brandFamily: brandFamily
                });
          break;
      case "reset-password" :
          tmp = match.tl ? "Hello World" : JsxRuntime.jsx(ResetPasswordRoute$Thin.make, {
                  brandFamily: brandFamily
                });
          break;
      case "verify" :
          tmp = match.tl ? "Hello World" : JsxRuntime.jsx(VerifyRoute$Thin.make, {
                  brandFamily: brandFamily
                });
          break;
      default:
        tmp = "Hello World";
    }
  } else {
    tmp = JsxRuntime.jsx(ThinApp$RedirectToLogin, {});
  }
  return JsxRuntime.jsx(React.Suspense, {
              children: Caml_option.some(JsxRuntime.jsx(Theme$Thin.make, {
                        children: tmp
                      })),
              fallback: "Loading"
            });
}

var make = ThinApp;

exports.RedirectToLogin = RedirectToLogin;
exports.make = make;
/* react Not a pure module */
