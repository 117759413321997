// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var LoginPage$Thin = require("../pages/LoginPage.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var StandardLayout$Thin = require("../layouts/StandardLayout.bs.js");
var LoginRouteQuery_graphql$Thin = require("../__generated__/LoginRouteQuery_graphql.bs.js");

var convertVariables = LoginRouteQuery_graphql$Thin.Internal.convertVariables;

var convertResponse = LoginRouteQuery_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = LoginRouteQuery_graphql$Thin.Internal.convertWrapRawResponse;

function use(param, param$1, param$2, param$3, param$4) {
  return RescriptRelay_Query.useQuery(convertVariables, LoginRouteQuery_graphql$Thin.node, convertResponse, param, param$1, param$2, param$3, param$4);
}

function useLoader(param) {
  return RescriptRelay_Query.useLoader(convertVariables, LoginRouteQuery_graphql$Thin.node, (function (prim) {
                return prim;
              }), param);
}

function usePreloaded(param) {
  return RescriptRelay_Query.usePreloaded(LoginRouteQuery_graphql$Thin.node, convertResponse, (function (prim) {
                return prim;
              }), param);
}

function $$fetch(param, param$1, param$2, param$3, param$4, param$5) {
  return RescriptRelay_Query.$$fetch(LoginRouteQuery_graphql$Thin.node, convertResponse, convertVariables, param, param$1, param$2, param$3, param$4, param$5);
}

function fetchPromised(param, param$1, param$2, param$3, param$4) {
  return RescriptRelay_Query.fetchPromised(LoginRouteQuery_graphql$Thin.node, convertResponse, convertVariables, param, param$1, param$2, param$3, param$4);
}

function retain(param, param$1) {
  return RescriptRelay_Query.retain(LoginRouteQuery_graphql$Thin.node, convertVariables, param, param$1);
}

var Query = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function LoginRoute(props) {
  var brandFamily = props.brandFamily;
  var data = use({
        brandFamily: brandFamily
      }, undefined, undefined, undefined, undefined);
  return JsxRuntime.jsx(StandardLayout$Thin.make, {
              fragmentRefs: data.fragmentRefs,
              dash: false,
              children: JsxRuntime.jsx(LoginPage$Thin.make, {
                    fragmentRefs: data.fragmentRefs,
                    brandFamily: brandFamily
                  })
            });
}

var make = LoginRoute;

exports.Query = Query;
exports.make = make;
/* LoginPage-Thin Not a pure module */
