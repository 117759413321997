// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var CustomScalars$Thin = require("../utils/CustomScalars.bs.js");

var Types = {};

var variablesConverter = {"changeEmailInput":{"newEmail":{"c":"CustomScalars.Email"}},"__root":{"input":{"r":"changeEmailInput"}}};

var variablesConverterMap = {
  "CustomScalars.Email": CustomScalars$Thin.Email.serialize
};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, variablesConverterMap, undefined);
}

var wrapResponseConverter = {};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, undefined, null);
}

var responseConverter = {};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, undefined, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: variablesConverterMap,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: undefined,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: undefined,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse
};

function changeEmailErr_decode($$enum) {
  if ($$enum === "EMAIL_CANT_BE_CHANGED_ASK_SUPPORT" || $$enum === "EMAIL_ALREADY_IN_USE") {
    return $$enum;
  }
  
}

var changeEmailErr_fromString = changeEmailErr_decode;

var Utils = {
  changeEmailErr_decode: changeEmailErr_decode,
  changeEmailErr_fromString: changeEmailErr_fromString
};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ChangeEmailPayload",
    "kind": "LinkedField",
    "name": "changeEmail",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "changeEmailErr",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChangeEmailSubmit_ChangeEmailMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChangeEmailSubmit_ChangeEmailMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f973b972a32d185ae42e4867691ac60c",
    "id": null,
    "metadata": {},
    "name": "ChangeEmailSubmit_ChangeEmailMutation",
    "operationKind": "mutation",
    "text": "mutation ChangeEmailSubmit_ChangeEmailMutation(\n  $input: ChangeEmailInput!\n) {\n  changeEmail(input: $input) {\n    changeEmailErr\n  }\n}\n"
  }
};
})());

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* node Not a pure module */
