// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var JsxRuntime = require("react/jsx-runtime");

function JoCoLoGo(props) {
  return JsxRuntime.jsx("svg", {
              children: JsxRuntime.jsxs("g", {
                    children: [
                      JsxRuntime.jsx("path", {
                            d: "M1315 1087 c-117 -46 -244 -191 -305 -347 -34 -88 -75 -103 -128 -49\n-17 17 -38 29 -48 27 -15 -3 -18 -15 -18 -93 -1 -81 -4 -97 -34 -157 -49 -100\n-122 -152 -160 -114 -13 13 -17 35 -17 94 0 67 4 86 33 144 34 69 90 128 121\n128 24 0 45 25 37 45 -3 8 -17 15 -31 15 -121 0 -256 -249 -216 -397 14 -53\n34 -81 70 -94 91 -35 219 97 253 262 l12 58 40 -2 c23 -1 52 2 66 6 24 8 24 7\n-3 -10 -37 -24 -42 -46 -42 -193 0 -117 2 -129 28 -183 39 -78 89 -112 166\n-112 66 0 119 23 169 74 47 47 84 131 86 195 1 45 -1 51 -21 54 -18 3 -23 -3\n-28 -35 -22 -134 -108 -233 -204 -233 -43 0 -73 16 -99 54 -33 49 -42 90 -42\n197 0 283 211 619 388 619 22 0 48 -3 57 -6 43 -17 60 -138 30 -211 -19 -45\n-13 -66 17 -61 30 4 57 75 57 153 1 78 -29 143 -79 167 -40 20 -110 22 -155 5z"
                          }),
                      JsxRuntime.jsx("path", {
                            d: "M530 1053 c-178 -30 -307 -189 -325 -403 -7 -83 18 -163 53 -168 30\n-4 36 16 17 61 -28 68 -13 182 37 280 42 83 143 166 202 167 16 0 17 -5 11\n-47 -12 -84 -75 -320 -116 -441 -98 -283 -214 -423 -309 -372 -29 16 -42 73\n-38 168 3 87 3 87 -22 87 -30 0 -38 -24 -39 -116 -2 -155 74 -235 191 -199\n144 42 293 377 393 879 16 81 16 85 -1 98 -10 7 -20 12 -23 12 -3 -1 -17 -4\n-31 -6z"
                          }),
                      JsxRuntime.jsx("path", {
                            d: "M2177 975 c-85 -32 -185 -128 -250 -243 -16 -30 -40 -58 -53 -63 -30\n-11 -68 5 -89 38 -9 13 -24 23 -33 21 -14 -3 -17 -17 -18 -88 -1 -98 -23 -165\n-74 -230 -64 -81 -119 -85 -141 -12 -30 101 52 282 147 324 44 19 59 50 31 61\n-21 8 -87 -19 -119 -50 -92 -86 -145 -271 -107 -370 26 -69 91 -93 157 -59 73\n38 145 156 161 264 l6 44 58 1 c48 2 55 0 40 -10 -9 -7 -20 -13 -24 -13 -3 0\n-11 -12 -17 -27 -50 -127 -61 -326 -23 -424 58 -151 206 -185 328 -75 54 49\n87 107 104 184 13 63 7 85 -23 80 -19 -2 -24 -11 -29 -48 -18 -127 -105 -220\n-207 -220 -52 0 -94 35 -119 98 -14 36 -18 72 -17 157 1 95 6 123 33 204 45\n133 94 221 168 300 71 77 129 111 191 111 88 0 123 -82 88 -204 -18 -61 -15\n-76 14 -76 38 0 70 137 50 218 -24 102 -123 147 -233 107z"
                          }),
                      JsxRuntime.jsx("path", {
                            d: "M2511 741 c-6 -11 -11 -26 -11 -34 0 -7 -16 -71 -35 -141 -36 -132\n-96 -273 -134 -315 -25 -29 -26 -35 -6 -51 25 -21 60 12 104 101 54 107 107\n277 92 292 -24 24 -9 27 19 4 16 -14 39 -28 50 -32 32 -10 33 -46 5 -121 -55\n-144 -53 -219 7 -250 43 -22 86 -9 131 39 l37 41 -11 -33 c-9 -27 -8 -35 6\n-51 39 -43 101 -34 152 20 l30 32 7 -28 c10 -37 34 -64 58 -64 23 0 23 26 2\n70 -22 43 -14 141 21 270 34 125 35 134 8 138 -28 4 -33 -7 -72 -147 -46 -166\n-98 -261 -143 -261 -47 0 -47 123 -1 276 33 112 35 149 8 149 -16 0 -26 -16\n-48 -80 -15 -43 -34 -114 -41 -156 -19 -112 -81 -192 -121 -159 -22 19 -18 49\n20 166 44 130 39 159 -31 200 -44 26 -51 39 -56 104 -3 40 -30 53 -47 21z"
                          }),
                      JsxRuntime.jsx("path", {
                            d: "M3216 747 c-36 -26 4 -84 42 -61 24 16 27 45 6 61 -10 7 -21 13 -24\n13 -3 0 -14 -6 -24 -13z"
                          }),
                      JsxRuntime.jsx("path", {
                            d: "M3730 699 c-90 -36 -180 -201 -180 -329 1 -189 182 -198 267 -13 28\n60 27 87 -3 91 -17 2 -25 -6 -39 -44 -23 -60 -80 -124 -111 -124 -72 0 -72\n164 2 281 26 41 79 89 100 89 44 0 -15 -123 -74 -154 -38 -20 -54 -40 -46 -60\n9 -24 34 -19 86 19 61 44 99 103 105 159 5 38 2 48 -20 70 -28 28 -47 31 -87\n15z"
                          }),
                      JsxRuntime.jsx("path", {
                            d: "M3396 648 c-79 -249 -150 -406 -199 -442 -28 -21 -29 -21 -43 -2 -8\n11 -14 35 -14 53 0 46 27 171 57 266 26 85 25 107 -6 107 -16 0 -26 -17 -51\n-95 -75 -235 -71 -382 9 -400 58 -12 117 38 172 147 50 98 71 161 70 203 l-1\n40 26 -60 c72 -168 80 -208 45 -239 -26 -24 -53 -17 -69 17 -10 20 -20 27 -35\n25 -57 -8 9 -118 71 -118 45 0 89 34 103 79 16 54 5 114 -38 202 -27 56 -37\n92 -42 149 -5 68 -8 75 -29 78 -12 2 -24 -3 -26 -10z"
                          }),
                      JsxRuntime.jsx("path", {
                            d: "M780 243 c-323 -50 -411 -78 -368 -119 10 -9 36 -6 123 16 61 15 160\n33 220 40 112 13 143 26 132 54 -6 16 -45 19 -107 9z"
                          }),
                      JsxRuntime.jsx("path", {
                            d: "M1413 224 c-3 -8 -1 -20 4 -25 5 -5 72 -16 149 -25 76 -8 145 -17\n152 -19 31 -11 60 33 32 50 -13 8 -234 35 -287 35 -30 0 -45 -5 -50 -16z"
                          }),
                      JsxRuntime.jsx("path", {
                            d: "M3695 151 c-57 -26 -202 -58 -325 -72 -189 -21 -517 -5 -935 46 -136\n17 -165 12 -145 -24 9 -18 116 -34 490 -73 156 -16 563 -16 665 1 195 31 330\n79 330 116 0 27 -29 29 -80 6z"
                          })
                    ],
                    fill: "#4b4d59",
                    stroke: "none",
                    transform: "translate(0.000000,111.000000) scale(0.100000,-0.100000)"
                  }),
              preserveAspectRatio: "xMidYMid meet",
              version: "1.0",
              viewBox: "0 0 384.000000 111.000000",
              xmlns: "http://www.w3.org/2000/svg"
            });
}

var make = JoCoLoGo;

exports.make = make;
/* react/jsx-runtime Not a pure module */
