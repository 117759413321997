// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Txt$Thin = require("../../../text/Txt.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Drawer$Thin = require("../../common/molecules/Drawer.bs.js");
var Grid = require("@mui/joy/Grid").default;
var Stack = require("@mui/joy/Stack").default;
var Divider = require("@mui/joy/Divider").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var BDInvoiceDetails$Thin = require("./BDInvoiceDetails.bs.js");
var DialogContent = require("@mui/joy/DialogContent").default;
var BDDepositSchedule$Thin = require("./BDDepositSchedule.bs.js");

function BDBillPayDrawer(props) {
  var match = React.useState(function () {
        return "invoice";
      });
  var setTab = match[1];
  var tab = match[0];
  return JsxRuntime.jsxs(Drawer$Thin.make, {
              open_: props.open_,
              title: Txt$Thin.BookingDetails.billingAndPaymentsTitle.value,
              onClose: props.onClose,
              children: [
                JsxRuntime.jsxs(Grid, {
                      children: [
                        JsxRuntime.jsx(Grid, {
                              children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                        direction: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        onClick: (function (param) {
                                            Curry._1(setTab, (function (param) {
                                                    return "invoice";
                                                  }));
                                          }),
                                        children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                                  sx: {
                                                    width: "fit-content",
                                                    height: "100"
                                                  },
                                                  children: [
                                                    JsxRuntime.jsx(Typography, {
                                                          level: "body-sm",
                                                          sx: {
                                                            my: 2,
                                                            color: tab === "invoice" ? "text.primary" : "neutral.500"
                                                          },
                                                          children: Caml_option.some(Txt$Thin.BookingDetails.invoiceTitle.value)
                                                        }),
                                                    JsxRuntime.jsx(Stack, {
                                                          sx: {
                                                            height: "3px",
                                                            width: "100%",
                                                            backgroundColor: tab === "invoice" ? "text.secondary" : "transparent",
                                                            borderTopRightRadius: "100px",
                                                            borderTopLeftRadius: "100px"
                                                          }
                                                        })
                                                  ]
                                                }))
                                      })),
                              sx: {
                                "&:hover": {
                                  backgroundColor: "background.level2",
                                  cursor: "pointer"
                                }
                              },
                              xs: 6
                            }),
                        JsxRuntime.jsx(Grid, {
                              children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                        direction: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        onClick: (function (param) {
                                            Curry._1(setTab, (function (param) {
                                                    return "deposit";
                                                  }));
                                          }),
                                        children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                                  sx: {
                                                    width: "fit-content",
                                                    height: "100"
                                                  },
                                                  children: [
                                                    JsxRuntime.jsx(Typography, {
                                                          level: "body-sm",
                                                          sx: {
                                                            my: 2,
                                                            color: tab === "deposit" ? "text.primary" : "neutral.500"
                                                          },
                                                          children: Caml_option.some(Txt$Thin.BookingDetails.depositScheduleTitle.value)
                                                        }),
                                                    JsxRuntime.jsx(Stack, {
                                                          sx: {
                                                            height: "3px",
                                                            width: "100%",
                                                            backgroundColor: tab === "deposit" ? "text.secondary" : "transparent",
                                                            borderTopRightRadius: "100px",
                                                            borderTopLeftRadius: "100px"
                                                          }
                                                        })
                                                  ]
                                                }))
                                      })),
                              sx: {
                                "&:hover": {
                                  backgroundColor: "background.level2",
                                  cursor: "pointer"
                                }
                              },
                              xs: 6
                            })
                      ],
                      container: true,
                      sx: {
                        backgroundColor: "background.level1"
                      }
                    }),
                JsxRuntime.jsx(Divider, {}),
                JsxRuntime.jsx(DialogContent, {
                      children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                direction: "column",
                                spacing: 2,
                                sx: {
                                  px: 2,
                                  py: 3
                                },
                                children: Caml_option.some(tab === "deposit" ? JsxRuntime.jsx(BDDepositSchedule$Thin.make, {}) : JsxRuntime.jsx(BDInvoiceDetails$Thin.make, {
                                            fragmentRefs: props.fragmentRefs,
                                            isActive: props.isActive
                                          }))
                              }))
                    })
              ]
            });
}

var make = BDBillPayDrawer;

exports.make = make;
/* react Not a pure module */
