// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml = require("rescript/lib/js/caml.js");
var $$String = require("rescript/lib/js/string.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Caml_format = require("rescript/lib/js/caml_format.js");

function intToHex(num) {
  var _n = num;
  var _acc = "";
  while(true) {
    var acc = _acc;
    var n = _n;
    if (n === 0 && acc === "") {
      return "0";
    }
    if (n === 0) {
      return acc;
    }
    var remainder = n % 16;
    var next = n / 16 | 0;
    var nextChar = $$String.sub("0123456789ABCDEF", remainder, 1);
    _acc = nextChar + acc;
    _n = next;
    continue ;
  };
}

function padHex(value) {
  var hexValue = intToHex(value);
  if (hexValue.length === 1) {
    return "0" + hexValue;
  } else {
    return hexValue;
  }
}

function adjustBrightness(hex, percent) {
  var r = Caml_format.int_of_string("0x" + $$String.sub(hex, 1, 2));
  var g = Caml_format.int_of_string("0x" + $$String.sub(hex, 3, 2));
  var b = Caml_format.int_of_string("0x" + $$String.sub(hex, 5, 2));
  var adjust = function (color) {
    var adjusted = color * (1.0 + percent) | 0;
    return Caml.int_max(0, adjusted < 255 ? adjusted : 255);
  };
  var newR = adjust(r);
  var newG = adjust(g);
  var newB = adjust(b);
  return "#" + padHex(newR) + padHex(newG) + padHex(newB);
}

function generatePalette(primary500) {
  return Js_dict.fromArray([
              [
                "50",
                adjustBrightness(primary500, 0.9)
              ],
              [
                "100",
                adjustBrightness(primary500, 0.8)
              ],
              [
                "200",
                adjustBrightness(primary500, 0.7)
              ],
              [
                "400",
                adjustBrightness(primary500, 0.4)
              ],
              [
                "500",
                primary500
              ],
              [
                "600",
                adjustBrightness(primary500, -0.1)
              ],
              [
                "700",
                adjustBrightness(primary500, -0.2)
              ],
              [
                "800",
                adjustBrightness(primary500, -0.4)
              ],
              [
                "900",
                adjustBrightness(primary500, -0.5)
              ]
            ]);
}

exports.intToHex = intToHex;
exports.padHex = padHex;
exports.adjustBrightness = adjustBrightness;
exports.generatePalette = generatePalette;
/* No side effect */
