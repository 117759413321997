// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var Box = require("@mui/joy/Box").default;
var Stack = require("@mui/joy/Stack").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;

function AuthPanel(props) {
  var subHeadComponent = props.subHeadComponent;
  return JsxRuntime.jsx(Box, {
              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                        direction: "column",
                        alignItems: "center",
                        spacing: {
                          xs: 2,
                          md: 3
                        },
                        children: [
                          JsxRuntime.jsxs(Box, {
                                children: [
                                  JsxRuntime.jsx(Typography, {
                                        level: "h1",
                                        component: "h2",
                                        children: Caml_option.some(props.heading)
                                      }),
                                  subHeadComponent !== undefined ? JsxRuntime.jsx(Box, {
                                          children: Caml_option.some(Caml_option.valFromOption(subHeadComponent)),
                                          sx: {
                                            marginTop: "0.5rem"
                                          }
                                        }) : JsxRuntime.jsx(JsxRuntime.Fragment, {})
                                ],
                                sx: {
                                  width: "100%",
                                  textAlign: "center",
                                  marginBottom: "2rem"
                                }
                              }),
                          props.children
                        ]
                      })),
              sx: {
                maxWidth: "25rem",
                width: "100%"
              }
            });
}

var make = AuthPanel;

exports.make = make;
/* @mui/joy/Box Not a pure module */
