// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Date$Util = require("util/src/Date.bs.js");
var IsLoggedIn$Thin = require("./gates/IsLoggedIn.bs.js");
var BookingsPage$Thin = require("../pages/BookingsPage.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var StandardLayout$Thin = require("../layouts/StandardLayout.bs.js");
var BookingsRouteQuery_graphql$Thin = require("../__generated__/BookingsRouteQuery_graphql.bs.js");

var convertVariables = BookingsRouteQuery_graphql$Thin.Internal.convertVariables;

var convertResponse = BookingsRouteQuery_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = BookingsRouteQuery_graphql$Thin.Internal.convertWrapRawResponse;

function use(param, param$1, param$2, param$3, param$4) {
  return RescriptRelay_Query.useQuery(convertVariables, BookingsRouteQuery_graphql$Thin.node, convertResponse, param, param$1, param$2, param$3, param$4);
}

function useLoader(param) {
  return RescriptRelay_Query.useLoader(convertVariables, BookingsRouteQuery_graphql$Thin.node, (function (prim) {
                return prim;
              }), param);
}

function usePreloaded(param) {
  return RescriptRelay_Query.usePreloaded(BookingsRouteQuery_graphql$Thin.node, convertResponse, (function (prim) {
                return prim;
              }), param);
}

function $$fetch(param, param$1, param$2, param$3, param$4, param$5) {
  return RescriptRelay_Query.$$fetch(BookingsRouteQuery_graphql$Thin.node, convertResponse, convertVariables, param, param$1, param$2, param$3, param$4, param$5);
}

function fetchPromised(param, param$1, param$2, param$3, param$4) {
  return RescriptRelay_Query.fetchPromised(BookingsRouteQuery_graphql$Thin.node, convertResponse, convertVariables, param, param$1, param$2, param$3, param$4);
}

function retain(param, param$1) {
  return RescriptRelay_Query.retain(BookingsRouteQuery_graphql$Thin.node, convertVariables, param, param$1);
}

var Query = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function BookingsRoute(props) {
  var data = use({
        brandFamily: props.brandFamily,
        currentDate: Date$Util.today(undefined)
      }, undefined, undefined, undefined, undefined);
  return JsxRuntime.jsx(IsLoggedIn$Thin.make, {
              fragmentRefs: data.fragmentRefs,
              children: JsxRuntime.jsx(StandardLayout$Thin.make, {
                    fragmentRefs: data.fragmentRefs,
                    children: JsxRuntime.jsx(BookingsPage$Thin.make, {
                          fragmentRefs: data.fragmentRefs
                        })
                  })
            });
}

var make = BookingsRoute;

exports.Query = Query;
exports.make = make;
/* Date-Util Not a pure module */
