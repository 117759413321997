// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Txt$Thin = require("../../../text/Txt.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Menu = require("@mui/joy/Menu").default;
var Dropdown = require("@mui/joy/Dropdown").default;
var MenuItem = require("@mui/joy/MenuItem").default;
var JsxRuntime = require("react/jsx-runtime");
var MenuButton = require("@mui/joy/MenuButton").default;
var Typography = require("@mui/joy/Typography").default;
var Cancel = require("@mui/icons-material/Cancel").default;
var ListItemDecorator = require("@mui/joy/ListItemDecorator").default;
var ArrowUpward = require("@mui/icons-material/ArrowUpward").default;
var ArrowDownward = require("@mui/icons-material/ArrowDownward").default;
var BedroomParentOutlined = require("@mui/icons-material/BedroomParentOutlined").default;

function BDManageResDropdown(props) {
  var match = React.useState(function () {
        return false;
      });
  var setIsOpen = match[1];
  return JsxRuntime.jsxs(Dropdown, {
              children: [
                JsxRuntime.jsx(MenuButton, {
                      children: Caml_option.some(Txt$Thin.BookingDetails.manageReservation.value),
                      size: "md"
                    }),
                JsxRuntime.jsxs(Menu, {
                      children: [
                        JsxRuntime.jsxs(MenuItem, {
                              children: [
                                JsxRuntime.jsx(ListItemDecorator, {
                                      children: Caml_option.some(JsxRuntime.jsx(ArrowUpward, {}))
                                    }),
                                Txt$Thin.BookingDetails.upgradeBooking.value
                              ],
                              disabled: true
                            }),
                        JsxRuntime.jsxs(MenuItem, {
                              children: [
                                JsxRuntime.jsx(ListItemDecorator, {
                                      children: Caml_option.some(JsxRuntime.jsx(ArrowDownward, {}))
                                    }),
                                Txt$Thin.BookingDetails.downgradeBooking.value
                              ],
                              disabled: true
                            }),
                        JsxRuntime.jsxs(MenuItem, {
                              children: [
                                JsxRuntime.jsx(ListItemDecorator, {
                                      children: Caml_option.some(JsxRuntime.jsx(BedroomParentOutlined, {}))
                                    }),
                                Txt$Thin.BookingDetails.changeCabin.value
                              ],
                              disabled: true
                            }),
                        JsxRuntime.jsxs(MenuItem, {
                              children: [
                                JsxRuntime.jsx(ListItemDecorator, {
                                      children: Caml_option.some(JsxRuntime.jsx(Cancel, {
                                                color: "danger"
                                              }))
                                    }),
                                JsxRuntime.jsx(Typography, {
                                      color: "danger",
                                      level: "inherit",
                                      children: Caml_option.some(Txt$Thin.BookingDetails.cancelBooking.value)
                                    })
                              ],
                              disabled: true
                            })
                      ]
                    })
              ],
              open: match[0],
              onOpenChange: (function (param, b) {
                  Curry._1(setIsOpen, b);
                })
            });
}

var make = BDManageResDropdown;

exports.make = make;
/* react Not a pure module */
