// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */
'use strict';

var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");

var Types = {};

var fragmentConverter = {"__root":{"":{"f":""}}};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, undefined, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: undefined,
  convertFragment: convertFragment
};

function custSignonStatus_decode($$enum) {
  if ($$enum === "PENDING_2FA" || $$enum === "VERIFY_EMAIL" || $$enum === "ANONYMOUS" || $$enum === "CLEAR_JWT" || $$enum === "SIGNED_ON" || $$enum === "INVITED") {
    return $$enum;
  }
  
}

var custSignonStatus_fromString = custSignonStatus_decode;

var Utils = {
  custSignonStatus_decode: custSignonStatus_decode,
  custSignonStatus_fromString: custSignonStatus_fromString
};

var node = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "brandFamily"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "StandardLayout_query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentUserSignonStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentUserEmail",
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "brandFamily",
          "variableName": "brandFamily"
        }
      ],
      "kind": "FragmentSpread",
      "name": "Navigation_query"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

exports.Types = Types;
exports.Internal = Internal;
exports.Utils = Utils;
exports.node = node;
/* RescriptRelay Not a pure module */
