// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var InvitePass$Thin = require("./InvitePass.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;
var AddPassDetails$Thin = require("./AddPassDetails.bs.js");
var DialogContent = require("@mui/joy/DialogContent").default;
var AddConnectedAccount$Thin = require("./AddConnectedAccount.bs.js");
var Check = require("@mui/icons-material/Check").default;

function AddPassDesktop$ActionCard(props) {
  var queryFrag = props.queryFrag;
  var setEmail = props.setEmail;
  var email = props.email;
  var onClick = props.onClick;
  var isSelected = props.isSelected;
  var tab = props.tab;
  return JsxRuntime.jsxs(Sheet, {
              sx: {
                border: "1px solid",
                borderColor: isSelected ? "neutral.400" : "divider",
                borderRadius: "sm",
                p: 2
              },
              children: [
                JsxRuntime.jsx(Stack, {
                      direction: "column",
                      spacing: 2,
                      children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                                direction: "column",
                                spacing: 1,
                                sx: {
                                  cursor: "pointer"
                                },
                                onClick: (function (param) {
                                    Curry._1(onClick, undefined);
                                  }),
                                children: [
                                  JsxRuntime.jsxs(Stack, {
                                        direction: "row",
                                        justifyContent: "space-between",
                                        alignItems: "flex-start",
                                        children: [
                                          JsxRuntime.jsx(Typography, {
                                                level: "body-md",
                                                fontWeight: "bold",
                                                children: Caml_option.some(props.title)
                                              }),
                                          JsxRuntime.jsx(Check, {
                                                fontSize: "2rem",
                                                sx: {
                                                  color: "background.surface",
                                                  padding: "2px",
                                                  border: isSelected ? "0px solid" : "1px solid",
                                                  borderColor: isSelected ? "transparent" : "neutral.400",
                                                  borderRadius: "100%",
                                                  backgroundColor: isSelected ? "primary.500" : "background.surface"
                                                }
                                              })
                                        ]
                                      }),
                                  JsxRuntime.jsx(Typography, {
                                        level: "body-md",
                                        sx: {
                                          color: "text.tertiary"
                                        },
                                        children: Caml_option.some(props.description)
                                      })
                                ]
                              }))
                    }),
                JsxRuntime.jsx(Stack, {
                      sx: {
                        maxHeight: isSelected ? "100%" : "0px",
                        overflowY: isSelected ? "auto" : "hidden"
                      },
                      children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                children: Caml_option.some(tab === "opt1" ? JsxRuntime.jsx(AddPassDetails$Thin.make, {
                                            firstName: props.firstName,
                                            setFirstName: props.setFirstName,
                                            lastName: props.lastName,
                                            setLastName: props.setLastName,
                                            email: email,
                                            setEmail: setEmail,
                                            cell: props.cell,
                                            setCell: props.setCell,
                                            secPhone: props.secPhone,
                                            setSecPhone: props.setSecPhone,
                                            dob: props.dob,
                                            setDob: props.setDob,
                                            country: props.country,
                                            setCountry: props.setCountry,
                                            gender: props.gender,
                                            setGender: props.setGender,
                                            postalCode: props.postalCode,
                                            setPostalCode: props.setPostalCode,
                                            residence: props.residence,
                                            setResidence: props.setResidence,
                                            loyalty: props.loyalty,
                                            setLoyalty: props.setLoyalty,
                                            countryFrag: queryFrag
                                          }) : (
                                        tab === "opt2" ? JsxRuntime.jsx(InvitePass$Thin.make, {
                                                email: email,
                                                setEmail: setEmail
                                              }) : (
                                            tab === "opt3" ? JsxRuntime.jsx(AddConnectedAccount$Thin.make, {
                                                    connectedAcctFrag: queryFrag,
                                                    connectedAcct: props.connectedAcct,
                                                    setConnectedAcct: props.setConnectedAcct
                                                  }) : null
                                          )
                                      ))
                              }))
                    })
              ]
            });
}

var ActionCard = {
  make: AddPassDesktop$ActionCard
};

function AddPassDesktop(props) {
  var queryFrag = props.queryFrag;
  var areConnectedAccts = props.areConnectedAccts;
  var setConnectedAcct = props.setConnectedAcct;
  var connectedAcct = props.connectedAcct;
  var setLoyalty = props.setLoyalty;
  var loyalty = props.loyalty;
  var setResidence = props.setResidence;
  var residence = props.residence;
  var setPostalCode = props.setPostalCode;
  var postalCode = props.postalCode;
  var setGender = props.setGender;
  var gender = props.gender;
  var setCountry = props.setCountry;
  var country = props.country;
  var setDob = props.setDob;
  var dob = props.dob;
  var setSecPhone = props.setSecPhone;
  var secPhone = props.secPhone;
  var setCell = props.setCell;
  var cell = props.cell;
  var setEmail = props.setEmail;
  var email = props.email;
  var setLastName = props.setLastName;
  var lastName = props.lastName;
  var setFirstName = props.setFirstName;
  var firstName = props.firstName;
  var clearAll = props.clearAll;
  var getDescription = props.getDescription;
  var getTitle = props.getTitle;
  var setTab = props.setTab;
  var tab = props.tab;
  return JsxRuntime.jsx(DialogContent, {
              children: Caml_option.some(JsxRuntime.jsx(Stack, {
                        direction: "column",
                        spacing: 2,
                        sx: {
                          p: 2
                        },
                        children: Caml_option.some(Belt_Array.mapWithIndex([
                                  "opt1",
                                  "opt2",
                                  "opt3"
                                ], (function (i, opt) {
                                    if (opt === "opt3" && !areConnectedAccts) {
                                      return null;
                                    } else {
                                      return JsxRuntime.jsx(AddPassDesktop$ActionCard, {
                                                  title: Curry._1(getTitle, opt),
                                                  tab: opt,
                                                  description: Curry._1(getDescription, opt),
                                                  isSelected: tab === opt,
                                                  onClick: (function (param) {
                                                      if (tab === opt) {
                                                        Curry._1(setTab, (function (param) {
                                                                return "all";
                                                              }));
                                                        return Curry._1(clearAll, undefined);
                                                      } else {
                                                        Curry._1(setTab, (function (param) {
                                                                return opt;
                                                              }));
                                                        return Curry._1(clearAll, undefined);
                                                      }
                                                    }),
                                                  firstName: firstName,
                                                  setFirstName: setFirstName,
                                                  lastName: lastName,
                                                  setLastName: setLastName,
                                                  email: email,
                                                  setEmail: setEmail,
                                                  cell: cell,
                                                  setCell: setCell,
                                                  secPhone: secPhone,
                                                  setSecPhone: setSecPhone,
                                                  dob: dob,
                                                  setDob: setDob,
                                                  country: country,
                                                  setCountry: setCountry,
                                                  gender: gender,
                                                  setGender: setGender,
                                                  postalCode: postalCode,
                                                  setPostalCode: setPostalCode,
                                                  residence: residence,
                                                  setResidence: setResidence,
                                                  loyalty: loyalty,
                                                  setLoyalty: setLoyalty,
                                                  connectedAcct: connectedAcct,
                                                  setConnectedAcct: setConnectedAcct,
                                                  queryFrag: queryFrag
                                                }, String(i));
                                    }
                                  })))
                      }))
            });
}

var make = AddPassDesktop;

exports.ActionCard = ActionCard;
exports.make = make;
/* @mui/joy/Sheet Not a pure module */
