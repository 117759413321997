// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Link$Thin = require("../components/common/atoms/Link.bs.js");
var Merge$Util = require("util/src/Merge.bs.js");
var JsxRuntime = require("react/jsx-runtime");

var account_parseSteps = [];

var account = {
  desc: "Account link in navbar and side bar",
  value: "Account",
  parseSteps: account_parseSteps
};

var bookings_parseSteps = [];

var bookings = {
  desc: "Bookings link in navbar and side bar",
  value: "Bookings",
  parseSteps: bookings_parseSteps
};

var login_parseSteps = [];

var login = {
  desc: "Login link in navbar and side bar",
  value: "Login",
  parseSteps: login_parseSteps
};

var logout_parseSteps = [];

var logout = {
  desc: "Logout link in navbar and side bar",
  value: "Logout",
  parseSteps: logout_parseSteps
};

var Nav = {
  account: account,
  bookings: bookings,
  login: login,
  logout: logout
};

var heading_parseSteps = [];

var heading = {
  desc: "Heading text on login page",
  value: "Welcome back",
  parseSteps: heading_parseSteps
};

var subHeading_parseSteps = [
  (function (param) {
      return Merge$Util.Parse.htmlStyleTag("SignUpLink", (function (value, mustache) {
                    return {
                            TAG: /* Hydrateable */1,
                            _0: (function (ctx) {
                                return JsxRuntime.jsx(Link$Thin.make, {
                                            to: "" + ctx.signUpLink + "",
                                            children: Curry._2(mustache, value, ctx)
                                          });
                              })
                          };
                  }), param);
    }),
  Merge$Util.Parse.mustache
];

var subHeading = {
  desc: "Subheading text on login page",
  value: "First time here? <SignUpLink>Sign up!</SignUpLink>",
  parseSteps: subHeading_parseSteps
};

var emailPlaceholder_parseSteps = [];

var emailPlaceholder = {
  desc: "Email placeholder text on login page",
  value: "Your email",
  parseSteps: emailPlaceholder_parseSteps
};

var passwordPlaceholder_parseSteps = [];

var passwordPlaceholder = {
  desc: "Password placeholder text on login page",
  value: "Your password",
  parseSteps: passwordPlaceholder_parseSteps
};

var login_parseSteps$1 = [];

var login$1 = {
  desc: "Login button text on login page",
  value: "Login",
  parseSteps: login_parseSteps$1
};

var forgotPassword_parseSteps = [];

var forgotPassword = {
  desc: "Forgot password text on login page",
  value: "Forgot password?",
  parseSteps: forgotPassword_parseSteps
};

var noUserError_parseSteps = [];

var noUserError = {
  desc: "Error message when user does not exist",
  value: "No user found with that email and password.",
  parseSteps: noUserError_parseSteps
};

var Login = {
  heading: heading,
  subHeading: subHeading,
  emailPlaceholder: emailPlaceholder,
  passwordPlaceholder: passwordPlaceholder,
  login: login$1,
  forgotPassword: forgotPassword,
  noUserError: noUserError
};

var heading_parseSteps$1 = [];

var heading$1 = {
  desc: "Heading text on claim page",
  value: "Claim your invitation",
  parseSteps: heading_parseSteps$1
};

var subheading_parseSteps = [];

var subheading = {
  desc: "Subheading text on claim page",
  value: "You've been invited to join the team! Please set your password below.",
  parseSteps: subheading_parseSteps
};

var passwordPlaceholder_parseSteps$1 = [];

var passwordPlaceholder$1 = {
  desc: "Password placeholder text on claim page",
  value: "Your password",
  parseSteps: passwordPlaceholder_parseSteps$1
};

var confirmPasswordPlaceholder_parseSteps = [];

var confirmPasswordPlaceholder = {
  desc: "Confirm password placeholder text on claim page",
  value: "Confirm password",
  parseSteps: confirmPasswordPlaceholder_parseSteps
};

var claim_parseSteps = [];

var claim = {
  desc: "Claim button text on claim page",
  value: "Claim your invitation",
  parseSteps: claim_parseSteps
};

var passwordSetError_parseSteps = [];

var passwordSetError = {
  desc: "Error message when password set fails",
  value: "Something went wrong setting your password. Please try again.",
  parseSteps: passwordSetError_parseSteps
};

var expired_parseSteps = [];

var expired = {
  desc: "Error message when invitation has expired",
  value: "Your invitation has expired!",
  parseSteps: expired_parseSteps
};

var success_parseSteps = [
  (function (param) {
      return Merge$Util.Parse.htmlStyleTag("LoginLink", (function (value, mustache) {
                    return {
                            TAG: /* Hydrateable */1,
                            _0: (function (ctx) {
                                return JsxRuntime.jsx(Link$Thin.make, {
                                            to: "" + ctx.loginLink + "",
                                            children: Curry._2(mustache, value, ctx)
                                          });
                              })
                          };
                  }), param);
    }),
  Merge$Util.Parse.mustache
];

var success = {
  desc: "Success message when invitation is claimed",
  value: "You're good to go! Please <LoginLink>login</LoginLink>.",
  parseSteps: success_parseSteps
};

var Claim = {
  heading: heading$1,
  subheading: subheading,
  passwordPlaceholder: passwordPlaceholder$1,
  confirmPasswordPlaceholder: confirmPasswordPlaceholder,
  claim: claim,
  passwordSetError: passwordSetError,
  expired: expired,
  success: success
};

var heading_parseSteps$2 = [];

var heading$2 = {
  desc: "Heading text on verify page",
  value: "Verify your email",
  parseSteps: heading_parseSteps$2
};

var loading_parseSteps = [];

var loading = {
  desc: "Loading text on verify page",
  value: "We're verifying your email! Hold tight!",
  parseSteps: loading_parseSteps
};

var expired_parseSteps$1 = [];

var expired$1 = {
  desc: "Error message when verification link has expired",
  value: "Your verification link has expired! Please try again!",
  parseSteps: expired_parseSteps$1
};

var success_parseSteps$1 = [];

var success$1 = {
  desc: "Success message when verification is successful",
  value: "Your email has been verified! Redirecting to your account page...",
  parseSteps: success_parseSteps$1
};

var verifyError_parseSteps = [];

var verifyError = {
  desc: "Error message when verification fails",
  value: "Something went wrong verifying your email. Please try again.",
  parseSteps: verifyError_parseSteps
};

var Verify = {
  heading: heading$2,
  loading: loading,
  expired: expired$1,
  success: success$1,
  verifyError: verifyError
};

var heading_parseSteps$3 = [];

var heading$3 = {
  desc: "Heading text on reset password page",
  value: "Reset your password",
  parseSteps: heading_parseSteps$3
};

var passwordPlaceholder_parseSteps$2 = [];

var passwordPlaceholder$2 = {
  desc: "Password placeholder text on reset password page",
  value: "Your new password",
  parseSteps: passwordPlaceholder_parseSteps$2
};

var confirmPasswordPlaceholder_parseSteps$1 = [];

var confirmPasswordPlaceholder$1 = {
  desc: "Confirm password placeholder text on reset password page",
  value: "Confirm new password",
  parseSteps: confirmPasswordPlaceholder_parseSteps$1
};

var emailPlaceholder_parseSteps$1 = [];

var emailPlaceholder$1 = {
  desc: "Email placeholder text on reset password page",
  value: "Your email",
  parseSteps: emailPlaceholder_parseSteps$1
};

var reset_parseSteps = [];

var reset = {
  desc: "Reset button text on reset password page",
  value: "Reset password",
  parseSteps: reset_parseSteps
};

var expired_parseSteps$2 = [];

var expired$2 = {
  desc: "Error message when reset password link has expired",
  value: "Your reset password link has expired! Please try again!",
  parseSteps: expired_parseSteps$2
};

var passwordUpdated_parseSteps = [
  (function (param) {
      return Merge$Util.Parse.htmlStyleTag("LoginLink", (function (value, mustache) {
                    return {
                            TAG: /* Hydrateable */1,
                            _0: (function (ctx) {
                                return JsxRuntime.jsx(Link$Thin.make, {
                                            to: "" + ctx.loginLink + "",
                                            children: Curry._2(mustache, value, ctx)
                                          });
                              })
                          };
                  }), param);
    }),
  Merge$Util.Parse.mustache
];

var passwordUpdated = {
  desc: "Success message when password is updated",
  value: "Your password has been updated! Please <LoginLink>login</LoginLink>.",
  parseSteps: passwordUpdated_parseSteps
};

var sendResetEmail_parseSteps = [];

var sendResetEmail = {
  desc: "Send reset password email button text on reset password page",
  value: "Send a reset link",
  parseSteps: sendResetEmail_parseSteps
};

var emailSent_parseSteps = [];

var emailSent = {
  desc: "Success message when reset password email is sent",
  value: "If your account exists, you will soon receive an email with a reset link! If it doesn't arrive in a few minutes, ",
  parseSteps: emailSent_parseSteps
};

var resendEmail_parseSteps = [];

var resendEmail = {
  desc: "Text on resend link after reset password email is sent",
  value: "click here to resend.",
  parseSteps: resendEmail_parseSteps
};

var resetError_parseSteps = [];

var resetError = {
  desc: "Error message when reset password fails",
  value: "Something went wrong resetting your password. Please try again.",
  parseSteps: resetError_parseSteps
};

var resetLinkError_parseSteps = [];

var resetLinkError = {
  desc: "Error message when reset password link email fails",
  value: "A reset password email could not be sent. Please try again.",
  parseSteps: resetLinkError_parseSteps
};

var ResetPassword = {
  heading: heading$3,
  passwordPlaceholder: passwordPlaceholder$2,
  confirmPasswordPlaceholder: confirmPasswordPlaceholder$1,
  emailPlaceholder: emailPlaceholder$1,
  reset: reset,
  expired: expired$2,
  passwordUpdated: passwordUpdated,
  sendResetEmail: sendResetEmail,
  emailSent: emailSent,
  resendEmail: resendEmail,
  resetError: resetError,
  resetLinkError: resetLinkError
};

var heading_parseSteps$4 = [];

var heading$4 = {
  desc: "Heading text on account page",
  value: "My account",
  parseSteps: heading_parseSteps$4
};

var subheading_parseSteps$1 = [];

var subheading$1 = {
  desc: "Subheading text on account page",
  value: "Manage your account information below.",
  parseSteps: subheading_parseSteps$1
};

var noProfileFound_parseSteps = [];

var noProfileFound = {
  desc: "Error message when no profile is found",
  value: "No profile found.",
  parseSteps: noProfileFound_parseSteps
};

var accessHeading_parseSteps = [];

var accessHeading = {
  desc: "Heading text on account access section of account page",
  value: "Account access",
  parseSteps: accessHeading_parseSteps
};

var noEmail_parseSteps = [];

var noEmail = {
  desc: "Error message when no email is found",
  value: "No email address associated with this account.",
  parseSteps: noEmail_parseSteps
};

var emailLabel_parseSteps = [];

var emailLabel = {
  desc: "Label text for email address on account page",
  value: "Email",
  parseSteps: emailLabel_parseSteps
};

var changeEmail_parseSteps = [];

var changeEmail = {
  desc: "Change email button text on account page",
  value: "Change email?",
  parseSteps: changeEmail_parseSteps
};

var emailModalHeading_parseSteps = [];

var emailModalHeading = {
  desc: "Heading text on change email modal",
  value: "Change email",
  parseSteps: emailModalHeading_parseSteps
};

var emailModalDescription_parseSteps = [];

var emailModalDescription = {
  desc: "Description text on change email modal",
  value: "Change the email address associated with your account.",
  parseSteps: emailModalDescription_parseSteps
};

var emailVerify_parseSteps = [
  (function (param) {
      return Merge$Util.Parse.htmlStyleTag("b", (function (value, mustache) {
                    return {
                            TAG: /* Hydrateable */1,
                            _0: (function (ctx) {
                                return JsxRuntime.jsx("span", {
                                            children: Curry._2(mustache, value, ctx),
                                            style: {
                                              fontWeight: "bold"
                                            }
                                          });
                              })
                          };
                  }), param);
    }),
  Merge$Util.Parse.mustache
];

var emailVerify = {
  desc: "Text on change email modal when email is changed",
  value: "Please check inbox for <b>{{userEmail}}</b> to verify your new email address!",
  parseSteps: emailVerify_parseSteps
};

var emailModalEmailPlaceholder_parseSteps = [];

var emailModalEmailPlaceholder = {
  desc: "Email placeholder text on change email modal",
  value: "Your new email address",
  parseSteps: emailModalEmailPlaceholder_parseSteps
};

var emailModalChangeButton_parseSteps = [];

var emailModalChangeButton = {
  desc: "Change email button text on change email modal",
  value: "Change email",
  parseSteps: emailModalChangeButton_parseSteps
};

var emailModalError_parseSteps = [];

var emailModalError = {
  desc: "Error message when change email fails",
  value: "Something went wrong changing your email. Please try again.",
  parseSteps: emailModalError_parseSteps
};

var changePassword_parseSteps = [];

var changePassword = {
  desc: "Change password button text on account page",
  value: "Change password?",
  parseSteps: changePassword_parseSteps
};

var passwordLabel_parseSteps = [];

var passwordLabel = {
  desc: "Label text for password on account page",
  value: "Password",
  parseSteps: passwordLabel_parseSteps
};

var passwordModalHeading_parseSteps = [];

var passwordModalHeading = {
  desc: "Heading text on change password modal",
  value: "Change password",
  parseSteps: passwordModalHeading_parseSteps
};

var passwordModalDescription_parseSteps = [];

var passwordModalDescription = {
  desc: "Description text on change password modal",
  value: "Change your account password.",
  parseSteps: passwordModalDescription_parseSteps
};

var passwordModalCurrentPasswordPlaceholder_parseSteps = [];

var passwordModalCurrentPasswordPlaceholder = {
  desc: "Current password placeholder text on change password modal",
  value: "Your current password",
  parseSteps: passwordModalCurrentPasswordPlaceholder_parseSteps
};

var passwordModalNewPasswordPlaceholder_parseSteps = [];

var passwordModalNewPasswordPlaceholder = {
  desc: "New password placeholder text on change password modal",
  value: "Your new password",
  parseSteps: passwordModalNewPasswordPlaceholder_parseSteps
};

var passwordModalConfirmPasswordPlaceholder_parseSteps = [];

var passwordModalConfirmPasswordPlaceholder = {
  desc: "Confirm password placeholder text on change password modal",
  value: "Confirm new password",
  parseSteps: passwordModalConfirmPasswordPlaceholder_parseSteps
};

var passwordModalChangeButton_parseSteps = [];

var passwordModalChangeButton = {
  desc: "Change password button text on change password modal",
  value: "Change password",
  parseSteps: passwordModalChangeButton_parseSteps
};

var passwordModalError_parseSteps = [];

var passwordModalError = {
  desc: "Error message when change password fails",
  value: "Something went wrong changing your password. Please try again.",
  parseSteps: passwordModalError_parseSteps
};

var passwordModalSuccess_parseSteps = [
  (function (param) {
      return Merge$Util.Parse.htmlStyleTag("LoginLink", (function (value, mustache) {
                    return {
                            TAG: /* Hydrateable */1,
                            _0: (function (ctx) {
                                return JsxRuntime.jsx(Link$Thin.make, {
                                            to: "" + ctx.loginLink + "",
                                            children: Curry._2(mustache, value, ctx)
                                          });
                              })
                          };
                  }), param);
    }),
  Merge$Util.Parse.mustache
];

var passwordModalSuccess = {
  desc: "Success message when password is changed",
  value: "Your password has been changed! You will now be redirected to the <LoginLink>login page</LoginLink>.",
  parseSteps: passwordModalSuccess_parseSteps
};

var personalInformationHeading_parseSteps = [];

var personalInformationHeading = {
  desc: "Heading text on personal information section of account page",
  value: "Personal information",
  parseSteps: personalInformationHeading_parseSteps
};

var preferredFirstNameLabel_parseSteps = [];

var preferredFirstNameLabel = {
  desc: "Label text for preferred first name on account page",
  value: "Preferred first name",
  parseSteps: preferredFirstNameLabel_parseSteps
};

var preferredFirstNamePlaceholder_parseSteps = [];

var preferredFirstNamePlaceholder = {
  desc: "Preferred first name placeholder text on account page",
  value: "First name",
  parseSteps: preferredFirstNamePlaceholder_parseSteps
};

var preferredFirstNameHelpText_parseSteps = [];

var preferredFirstNameHelpText = {
  desc: "Help text for preferred first name on account page",
  value: "Your preferred first name",
  parseSteps: preferredFirstNameHelpText_parseSteps
};

var preferredLastNameLabel_parseSteps = [];

var preferredLastNameLabel = {
  desc: "Label text for preferred last name on account page",
  value: "Preferred last name",
  parseSteps: preferredLastNameLabel_parseSteps
};

var preferredLastNamePlaceholder_parseSteps = [];

var preferredLastNamePlaceholder = {
  desc: "Preferred last name placeholder text on account page",
  value: "Last name",
  parseSteps: preferredLastNamePlaceholder_parseSteps
};

var preferredLastNameHelpText_parseSteps = [];

var preferredLastNameHelpText = {
  desc: "Help text for preferred last name on account page",
  value: "Your preferred last name",
  parseSteps: preferredLastNameHelpText_parseSteps
};

var legalFirstNameLabel_parseSteps = [];

var legalFirstNameLabel = {
  desc: "Label text for legal first name on account page",
  value: "Legal first name",
  parseSteps: legalFirstNameLabel_parseSteps
};

var legalFirstNamePlaceholder_parseSteps = [];

var legalFirstNamePlaceholder = {
  desc: "Legal first name placeholder text on account page",
  value: "First name",
  parseSteps: legalFirstNamePlaceholder_parseSteps
};

var legalFirstNameHelpText_parseSteps = [
  (function (param) {
      return Merge$Util.Parse.htmlStyleTag("MailtoLink", (function (value, mustache) {
                    return {
                            TAG: /* Hydrateable */1,
                            _0: (function (ctx) {
                                return JsxRuntime.jsx(Link$Thin.make, {
                                            to: "mailto:" + ctx.mailtoEmail + "?subject=" + encodeURIComponent(ctx.changeRequestSubject) + "",
                                            children: Curry._2(mustache, value, ctx)
                                          });
                              })
                          };
                  }), param);
    }),
  Merge$Util.Parse.mustache
];

var legalFirstNameHelpText = {
  desc: "Help text for legal first name on account page",
  value: "Need to update this? <MailtoLink>Contact us!</MailtoLink>",
  parseSteps: legalFirstNameHelpText_parseSteps
};

var legalLastNameLabel_parseSteps = [];

var legalLastNameLabel = {
  desc: "Label text for legal last name on account page",
  value: "Legal last name",
  parseSteps: legalLastNameLabel_parseSteps
};

var legalLastNamePlaceholder_parseSteps = [];

var legalLastNamePlaceholder = {
  desc: "Legal last name placeholder text on account page",
  value: "Last name",
  parseSteps: legalLastNamePlaceholder_parseSteps
};

var legalLastNameHelpText_parseSteps = [
  (function (param) {
      return Merge$Util.Parse.htmlStyleTag("MailtoLink", (function (value, mustache) {
                    return {
                            TAG: /* Hydrateable */1,
                            _0: (function (ctx) {
                                return JsxRuntime.jsx(Link$Thin.make, {
                                            to: "mailto:" + ctx.mailtoEmail + "?subject=" + encodeURIComponent(ctx.changeRequestSubject) + "",
                                            children: Curry._2(mustache, value, ctx)
                                          });
                              })
                          };
                  }), param);
    }),
  Merge$Util.Parse.mustache
];

var legalLastNameHelpText = {
  desc: "Help text for legal last name on account page",
  value: "Need to update this? <MailtoLink>Contact us!</MailtoLink>",
  parseSteps: legalLastNameHelpText_parseSteps
};

var birthdateLabel_parseSteps = [];

var birthdateLabel = {
  desc: "Label text for birthdate on account page",
  value: "Birthdate",
  parseSteps: birthdateLabel_parseSteps
};

var birthdateHelpText_parseSteps = [
  (function (param) {
      return Merge$Util.Parse.htmlStyleTag("MailtoLink", (function (value, mustache) {
                    return {
                            TAG: /* Hydrateable */1,
                            _0: (function (ctx) {
                                return JsxRuntime.jsx(Link$Thin.make, {
                                            to: "mailto:" + ctx.mailtoEmail + "?subject=" + encodeURIComponent(ctx.changeRequestSubject) + "",
                                            children: Curry._2(mustache, value, ctx)
                                          });
                              })
                          };
                  }), param);
    }),
  Merge$Util.Parse.mustache
];

var birthdateHelpText = {
  desc: "Help text for birthdate on account page",
  value: "Need to update this? <MailtoLink>Contact us!</MailtoLink>",
  parseSteps: birthdateHelpText_parseSteps
};

var citizenshipLabel_parseSteps = [];

var citizenshipLabel = {
  desc: "Label text for citizenship on account page",
  value: "Citizenship",
  parseSteps: citizenshipLabel_parseSteps
};

var citizenshipHelpText_parseSteps = [];

var citizenshipHelpText = {
  desc: "Help text for citizenship on account page",
  value: "",
  parseSteps: citizenshipHelpText_parseSteps
};

var genderLabel_parseSteps = [];

var genderLabel = {
  desc: "Label text for gender on account page",
  value: "Gender",
  parseSteps: genderLabel_parseSteps
};

var genderHelpText_parseSteps = [];

var genderHelpText = {
  desc: "Help text for gender on account page",
  value: "",
  parseSteps: genderHelpText_parseSteps
};

var modifyProfileButton_parseSteps = [];

var modifyProfileButton = {
  desc: "Modify profile button text on account page",
  value: "Modify your profile",
  parseSteps: modifyProfileButton_parseSteps
};

var saveButton_parseSteps = [];

var saveButton = {
  desc: "Save button text on account page",
  value: "Save",
  parseSteps: saveButton_parseSteps
};

var cancelButton_parseSteps = [];

var cancelButton = {
  desc: "Cancel button text on account page",
  value: "Cancel",
  parseSteps: cancelButton_parseSteps
};

var Account = {
  heading: heading$4,
  subheading: subheading$1,
  noProfileFound: noProfileFound,
  accessHeading: accessHeading,
  noEmail: noEmail,
  emailLabel: emailLabel,
  changeEmail: changeEmail,
  emailModalHeading: emailModalHeading,
  emailModalDescription: emailModalDescription,
  emailVerify: emailVerify,
  emailModalEmailPlaceholder: emailModalEmailPlaceholder,
  emailModalChangeButton: emailModalChangeButton,
  emailModalError: emailModalError,
  changePassword: changePassword,
  passwordLabel: passwordLabel,
  passwordModalHeading: passwordModalHeading,
  passwordModalDescription: passwordModalDescription,
  passwordModalCurrentPasswordPlaceholder: passwordModalCurrentPasswordPlaceholder,
  passwordModalNewPasswordPlaceholder: passwordModalNewPasswordPlaceholder,
  passwordModalConfirmPasswordPlaceholder: passwordModalConfirmPasswordPlaceholder,
  passwordModalChangeButton: passwordModalChangeButton,
  passwordModalError: passwordModalError,
  passwordModalSuccess: passwordModalSuccess,
  personalInformationHeading: personalInformationHeading,
  preferredFirstNameLabel: preferredFirstNameLabel,
  preferredFirstNamePlaceholder: preferredFirstNamePlaceholder,
  preferredFirstNameHelpText: preferredFirstNameHelpText,
  preferredLastNameLabel: preferredLastNameLabel,
  preferredLastNamePlaceholder: preferredLastNamePlaceholder,
  preferredLastNameHelpText: preferredLastNameHelpText,
  legalFirstNameLabel: legalFirstNameLabel,
  legalFirstNamePlaceholder: legalFirstNamePlaceholder,
  legalFirstNameHelpText: legalFirstNameHelpText,
  legalLastNameLabel: legalLastNameLabel,
  legalLastNamePlaceholder: legalLastNamePlaceholder,
  legalLastNameHelpText: legalLastNameHelpText,
  birthdateLabel: birthdateLabel,
  birthdateHelpText: birthdateHelpText,
  citizenshipLabel: citizenshipLabel,
  citizenshipHelpText: citizenshipHelpText,
  genderLabel: genderLabel,
  genderHelpText: genderHelpText,
  modifyProfileButton: modifyProfileButton,
  saveButton: saveButton,
  cancelButton: cancelButton
};

var upcomingHeading_parseSteps = [];

var upcomingHeading = {
  desc: "Heading text on upcoming bookings section of bookings page",
  value: "Upcoming bookings",
  parseSteps: upcomingHeading_parseSteps
};

var noUpcomingText_parseSteps = [];

var noUpcomingText = {
  desc: "Text when there are no upcoming bookings",
  value: "No upcoming bookings",
  parseSteps: noUpcomingText_parseSteps
};

var pastHeading_parseSteps = [];

var pastHeading = {
  desc: "Heading text on past bookings section of bookings page",
  value: "Booking history",
  parseSteps: pastHeading_parseSteps
};

var Bookings = {
  upcomingHeading: upcomingHeading,
  noUpcomingText: noUpcomingText,
  pastHeading: pastHeading
};

var heroLinksViewMore_parseSteps = [];

var heroLinksViewMore = {
  desc: "View more links on booking details hero",
  value: "View more",
  parseSteps: heroLinksViewMore_parseSteps
};

var heroLinksViewFewer_parseSteps = [];

var heroLinksViewFewer = {
  desc: "View fewer links on booking details hero",
  value: "View Fewer",
  parseSteps: heroLinksViewFewer_parseSteps
};

var stateroomDetailsHeading_parseSteps = [];

var stateroomDetailsHeading = {
  desc: "Heading text on stateroom details section of booking details page",
  value: "Stateroom Details",
  parseSteps: stateroomDetailsHeading_parseSteps
};

var cabinDeckChipText_parseSteps = [
  (function (param) {
      return Merge$Util.Parse.htmlStyleTag("DeckNumber", (function (value, mustache) {
                    return {
                            TAG: /* Hydrateable */1,
                            _0: (function (ctx) {
                                return JsxRuntime.jsx("span", {
                                            children: Curry._2(mustache, value, ctx),
                                            style: {
                                              fontWeight: "bold"
                                            }
                                          });
                              })
                          };
                  }), param);
    }),
  Merge$Util.Parse.mustache
];

var cabinDeckChipText = {
  desc: "Text for cabin deck chip on booking details page",
  value: "Deck <DeckNumber>{{cabinDeckNumber}}</DeckNumber>",
  parseSteps: cabinDeckChipText_parseSteps
};

var cabinNumberChipText_parseSteps = [
  (function (param) {
      return Merge$Util.Parse.htmlStyleTag("CabinNumber", (function (value, mustache) {
                    return {
                            TAG: /* Hydrateable */1,
                            _0: (function (ctx) {
                                return JsxRuntime.jsx("span", {
                                            children: Curry._2(mustache, value, ctx),
                                            style: {
                                              fontWeight: "bold"
                                            }
                                          });
                              })
                          };
                  }), param);
    }),
  Merge$Util.Parse.mustache
];

var cabinNumberChipText = {
  desc: "Text for cabin number chip on booking details page",
  value: "Cabin <CabinNumber>{{cabinNumber}}</CabinNumber>",
  parseSteps: cabinNumberChipText_parseSteps
};

var berthsChipText_parseSteps = [
  (function (param) {
      return Merge$Util.Parse.htmlStyleTag("NumBerths", (function (value, mustache) {
                    return {
                            TAG: /* Hydrateable */1,
                            _0: (function (ctx) {
                                return JsxRuntime.jsx("span", {
                                            children: Curry._2(mustache, value, ctx),
                                            style: {
                                              fontWeight: "bold"
                                            }
                                          });
                              })
                          };
                  }), param);
    }),
  Merge$Util.Parse.mustache
];

var berthsChipText = {
  desc: "Text for berth number chip on booking details page",
  value: "<NumBerths>{{numBerths}}</NumBerths> Berth{{#berthsPlural}}s{{/berthsPlural}}",
  parseSteps: berthsChipText_parseSteps
};

var billingContactHeading_parseSteps = [];

var billingContactHeading = {
  desc: "Heading text on billing contact section of booking details page",
  value: "Billing Contact",
  parseSteps: billingContactHeading_parseSteps
};

var manageReservation_parseSteps = [];

var manageReservation = {
  desc: "Dropdown button text for manage reservation actions",
  value: "Manage Reservation",
  parseSteps: manageReservation_parseSteps
};

var upgradeBooking_parseSteps = [];

var upgradeBooking = {
  desc: "Dropdown button text for upgrade booking action",
  value: "Upgrade",
  parseSteps: upgradeBooking_parseSteps
};

var downgradeBooking_parseSteps = [];

var downgradeBooking = {
  desc: "Dropdown button text for downgrade booking action",
  value: "Downgrade",
  parseSteps: downgradeBooking_parseSteps
};

var changeCabin_parseSteps = [];

var changeCabin = {
  desc: "Dropdown button text for change cabin action",
  value: "Change Cabin",
  parseSteps: changeCabin_parseSteps
};

var cancelBooking_parseSteps = [];

var cancelBooking = {
  desc: "Dropdown button text for cancel booking action",
  value: "Cancel",
  parseSteps: cancelBooking_parseSteps
};

var passengerListHeading_parseSteps = [];

var passengerListHeading = {
  desc: "Heading text on passenger list section of booking details page",
  value: "Passenger List",
  parseSteps: passengerListHeading_parseSteps
};

var addBerth_parseSteps = [];

var addBerth = {
  desc: "Text in passenger list heading for action to add berth",
  value: "Add Berth",
  parseSteps: addBerth_parseSteps
};

var maxOccReached_parseSteps = [];

var maxOccReached = {
  desc: "Text in passenger list Add Berth tooltip when max occupancy has been reached",
  value: "You've reached the maximum occupancy for this cabin. Other cabin categories may have a higher occupancy limit.",
  parseSteps: maxOccReached_parseSteps
};

var addBerthConfirmText_parseSteps = [Merge$Util.Parse.mustache];

var addBerthConfirmText = {
  desc: "Text for confirmation modal when adding a berth to a booking",
  value: "Are you sure you want to add a berth to booking {{bookingId}}?",
  parseSteps: addBerthConfirmText_parseSteps
};

var confirmAddBerth_parseSteps = [];

var confirmAddBerth = {
  desc: "Text for confirm button for add berth confirmation modal",
  value: "Add Berth",
  parseSteps: confirmAddBerth_parseSteps
};

var cancelAddBerth_parseSteps = [];

var cancelAddBerth = {
  desc: "Text for cancel button for add berth confirmation modal",
  value: "Cancel",
  parseSteps: cancelAddBerth_parseSteps
};

var billingSummaryHeading_parseSteps = [];

var billingSummaryHeading = {
  desc: "Heading text on billing summary section of booking details page",
  value: "Billing Summary",
  parseSteps: billingSummaryHeading_parseSteps
};

var noBillingSummary_parseSteps = [];

var noBillingSummary = {
  desc: "Text when there is no billing summary",
  value: "No billing summary available",
  parseSteps: noBillingSummary_parseSteps
};

var viewBillingDetails_parseSteps = [];

var viewBillingDetails = {
  desc: "Text for view billing details button",
  value: "View Details",
  parseSteps: viewBillingDetails_parseSteps
};

var totalBilled_parseSteps = [];

var totalBilled = {
  desc: "Text for total billed on billing summary section of booking details page",
  value: "Total Billed",
  parseSteps: totalBilled_parseSteps
};

var totalPaid_parseSteps = [];

var totalPaid = {
  desc: "Text for total paid on billing summary section of booking details page",
  value: "Total Paid",
  parseSteps: totalPaid_parseSteps
};

var remainingBalance_parseSteps = [];

var remainingBalance = {
  desc: "Text for remaining balance on billing summary and invoice drawer sections of booking details page",
  value: "Remaining Balance",
  parseSteps: remainingBalance_parseSteps
};

var pendingPayment_parseSteps = [];

var pendingPayment = {
  desc: "Text for pending payment on billing summary and invoice drawer sections of booking details page",
  value: "Pending Payment",
  parseSteps: pendingPayment_parseSteps
};

var balanceAfterPending_parseSteps = [];

var balanceAfterPending = {
  desc: "Text for total after pending on billing summary and invoice drawer sections of booking details page",
  value: "Balance After Pending",
  parseSteps: balanceAfterPending_parseSteps
};

var makeAPayment_parseSteps = [];

var makeAPayment = {
  desc: "Text for make a payment button on billing summary section of booking details page",
  value: "Make a Payment",
  parseSteps: makeAPayment_parseSteps
};

var billingAndPaymentsTitle_parseSteps = [];

var billingAndPaymentsTitle = {
  desc: "Title text for payment details / invoice section of booking details page",
  value: "Billing & Payments",
  parseSteps: billingAndPaymentsTitle_parseSteps
};

var depositScheduleTitle_parseSteps = [];

var depositScheduleTitle = {
  desc: "Title text for payment details / invoice section of booking details page",
  value: "Deposit Schedule",
  parseSteps: depositScheduleTitle_parseSteps
};

var invoiceTitle_parseSteps = [];

var invoiceTitle = {
  desc: "Title text for payment details / invoice section of booking details page",
  value: "Invoice",
  parseSteps: invoiceTitle_parseSteps
};

var bookingChargesHeading_parseSteps = [];

var bookingChargesHeading = {
  desc: "Heading text on booking charges section of booking details page",
  value: "Booking Charges",
  parseSteps: bookingChargesHeading_parseSteps
};

var paymentsHeading_parseSteps = [];

var paymentsHeading = {
  desc: "Heading text on payment section of booking details page",
  value: "Payments",
  parseSteps: paymentsHeading_parseSteps
};

var noBillingLines_parseSteps = [];

var noBillingLines = {
  desc: "Text when there are no billing lines",
  value: "No charges have been added yet",
  parseSteps: noBillingLines_parseSteps
};

var noBillingLinesInactive_parseSteps = [];

var noBillingLinesInactive = {
  desc: "Text when there are no billing lines on an inactive booking",
  value: "No charges added",
  parseSteps: noBillingLinesInactive_parseSteps
};

var noPaymentLines_parseSteps = [];

var noPaymentLines = {
  desc: "Text when there are no payemnt lines",
  value: "No payments have been made yet",
  parseSteps: noPaymentLines_parseSteps
};

var noPaymentLinesInactive_parseSteps = [];

var noPaymentLinesInactive = {
  desc: "Text when there are no payment lines on an inactive booking",
  value: "No payments made",
  parseSteps: noPaymentLinesInactive_parseSteps
};

var descriptionTableHeading_parseSteps = [];

var descriptionTableHeading = {
  desc: "Heading text on description column in invoice tables on booking details page",
  value: "Description",
  parseSteps: descriptionTableHeading_parseSteps
};

var amountTableHeading_parseSteps = [];

var amountTableHeading = {
  desc: "Heading text on amount column in invoice tables on booking details page",
  value: "Amount",
  parseSteps: amountTableHeading_parseSteps
};

var dateTableHeading_parseSteps = [];

var dateTableHeading = {
  desc: "Heading text on date column in invoice tables on booking details page",
  value: "Date",
  parseSteps: dateTableHeading_parseSteps
};

var payerTableHeading_parseSteps = [];

var payerTableHeading = {
  desc: "Heading text on payer column in invoice tables on booking details page",
  value: "Payer",
  parseSteps: payerTableHeading_parseSteps
};

var paymentMethodTableHeading_parseSteps = [];

var paymentMethodTableHeading = {
  desc: "Heading text on payment method column in invoice tables on booking details page",
  value: "Payment Method",
  parseSteps: paymentMethodTableHeading_parseSteps
};

var totalBilledTableFooter_parseSteps = [];

var totalBilledTableFooter = {
  desc: "Footer text on for total in billing invoice table on booking details page",
  value: "Total Billed",
  parseSteps: totalBilledTableFooter_parseSteps
};

var pendingBilledTableFooter_parseSteps = [];

var pendingBilledTableFooter = {
  desc: "Footer text on for pending total in billing invoice table on booking details page",
  value: "Pending Billed",
  parseSteps: pendingBilledTableFooter_parseSteps
};

var totalPaidTableFooter_parseSteps = [];

var totalPaidTableFooter = {
  desc: "Footer text on for total paid in payment invoice table on booking details page",
  value: "Total Paid",
  parseSteps: totalPaidTableFooter_parseSteps
};

var pendingPaidTableFooter_parseSteps = [];

var pendingPaidTableFooter = {
  desc: "Footer text on for pending payments in payment invoice table on booking details page",
  value: "Pending",
  parseSteps: pendingPaidTableFooter_parseSteps
};

var plTBA_parseSteps = [];

var plTBA = {
  desc: "Text in passenger list when that passenger is to be announced",
  value: "TBA",
  parseSteps: plTBA_parseSteps
};

var plStatus_parseSteps = [];

var plStatus = {
  desc: "Status heading text on passenger list table on booking details page",
  value: "Status",
  parseSteps: plStatus_parseSteps
};

var plName_parseSteps = [];

var plName = {
  desc: "Name heading text on passenger list table on booking details page",
  value: "Name",
  parseSteps: plName_parseSteps
};

var plBirthdate_parseSteps = [];

var plBirthdate = {
  desc: "Birthdate heading text on passenger list table on booking details page",
  value: "Birthdate",
  parseSteps: plBirthdate_parseSteps
};

var plEmail_parseSteps = [];

var plEmail = {
  desc: "Email heading text on passenger list table on booking details page",
  value: "Email",
  parseSteps: plEmail_parseSteps
};

var plConfirmed_parseSteps = [];

var plConfirmed = {
  desc: "Confirmed text within passenger list table on booking details page",
  value: "Confirmed",
  parseSteps: plConfirmed_parseSteps
};

var plInvited_parseSteps = [];

var plInvited = {
  desc: "Invited text within passenger list table on booking details page",
  value: "Invited",
  parseSteps: plInvited_parseSteps
};

var plIncomplete_parseSteps = [];

var plIncomplete = {
  desc: "Incomplete text within passenger list table on booking details page",
  value: "Incomplete",
  parseSteps: plIncomplete_parseSteps
};

var plUnknown_parseSteps = [];

var plUnknown = {
  desc: "Unknown text within passenger list table on booking details page",
  value: "Unknown",
  parseSteps: plUnknown_parseSteps
};

var providePassengerDetails_parseSteps = [];

var providePassengerDetails = {
  desc: "Title in passenger list item menu for action to provide passenger details",
  value: "Provide Passenger Details",
  parseSteps: providePassengerDetails_parseSteps
};

var providePassengerDetailsDesc_parseSteps = [];

var providePassengerDetailsDesc = {
  desc: "Description in passenger list item menu for action to provide passenger details",
  value: "Enter passenger travel document information.",
  parseSteps: providePassengerDetailsDesc_parseSteps
};

var invitePassenger_parseSteps = [];

var invitePassenger = {
  desc: "Title in passenger list item menu for action to invite passenger",
  value: "Invite a Passenger",
  parseSteps: invitePassenger_parseSteps
};

var invitePassengerDesc_parseSteps = [];

var invitePassengerDesc = {
  desc: "Description in passenger list item menu for action to invite passenger",
  value: "Invite a passenger via email.",
  parseSteps: invitePassengerDesc_parseSteps
};

var selectFromConnectedAccounts_parseSteps = [];

var selectFromConnectedAccounts = {
  desc: "Title in passenger list item menu for action to select from connected accounts",
  value: "Select from Connected Accounts",
  parseSteps: selectFromConnectedAccounts_parseSteps
};

var selectFromConnectedAccountsDesc_parseSteps = [];

var selectFromConnectedAccountsDesc = {
  desc: "Description in passenger list item menu for action to select from connected accounts",
  value: "Selected a passenger from your connected accounts.",
  parseSteps: selectFromConnectedAccountsDesc_parseSteps
};

var editDetails_parseSteps = [];

var editDetails = {
  desc: "Text in passenger list item menu for action to edit passenger details",
  value: "Edit Details",
  parseSteps: editDetails_parseSteps
};

var removeMyself_parseSteps = [];

var removeMyself = {
  desc: "Text in passenger list item menu for action to remove myself from booking",
  value: "Remove Myself",
  parseSteps: removeMyself_parseSteps
};

var replacePassenger_parseSteps = [];

var replacePassenger = {
  desc: "Text in passenger list item menu for action to replace passenger",
  value: "Replace Passenger",
  parseSteps: replacePassenger_parseSteps
};

var dropBerth_parseSteps = [];

var dropBerth = {
  desc: "Text in passenger list item menu for action to drop berth",
  value: "Drop Berth",
  parseSteps: dropBerth_parseSteps
};

var resendInvitation_parseSteps = [];

var resendInvitation = {
  desc: "Text in passenger list item menu for action to resend invitation",
  value: "Resend invitation",
  parseSteps: resendInvitation_parseSteps
};

var copyInvitationLink_parseSteps = [];

var copyInvitationLink = {
  desc: "Text in passenger list item menu for action to copy invitation link",
  value: "Copy invitation link",
  parseSteps: copyInvitationLink_parseSteps
};

var revokeInvitation_parseSteps = [];

var revokeInvitation = {
  desc: "Text in passenger list item menu for action to revoke invitation",
  value: "Revoke invitation",
  parseSteps: revokeInvitation_parseSteps
};

var BookingDetails = {
  heroLinksViewMore: heroLinksViewMore,
  heroLinksViewFewer: heroLinksViewFewer,
  stateroomDetailsHeading: stateroomDetailsHeading,
  cabinDeckChipText: cabinDeckChipText,
  cabinNumberChipText: cabinNumberChipText,
  berthsChipText: berthsChipText,
  billingContactHeading: billingContactHeading,
  manageReservation: manageReservation,
  upgradeBooking: upgradeBooking,
  downgradeBooking: downgradeBooking,
  changeCabin: changeCabin,
  cancelBooking: cancelBooking,
  passengerListHeading: passengerListHeading,
  addBerth: addBerth,
  maxOccReached: maxOccReached,
  addBerthConfirmText: addBerthConfirmText,
  confirmAddBerth: confirmAddBerth,
  cancelAddBerth: cancelAddBerth,
  billingSummaryHeading: billingSummaryHeading,
  noBillingSummary: noBillingSummary,
  viewBillingDetails: viewBillingDetails,
  totalBilled: totalBilled,
  totalPaid: totalPaid,
  remainingBalance: remainingBalance,
  pendingPayment: pendingPayment,
  balanceAfterPending: balanceAfterPending,
  makeAPayment: makeAPayment,
  billingAndPaymentsTitle: billingAndPaymentsTitle,
  depositScheduleTitle: depositScheduleTitle,
  invoiceTitle: invoiceTitle,
  bookingChargesHeading: bookingChargesHeading,
  paymentsHeading: paymentsHeading,
  noBillingLines: noBillingLines,
  noBillingLinesInactive: noBillingLinesInactive,
  noPaymentLines: noPaymentLines,
  noPaymentLinesInactive: noPaymentLinesInactive,
  descriptionTableHeading: descriptionTableHeading,
  amountTableHeading: amountTableHeading,
  dateTableHeading: dateTableHeading,
  payerTableHeading: payerTableHeading,
  paymentMethodTableHeading: paymentMethodTableHeading,
  totalBilledTableFooter: totalBilledTableFooter,
  pendingBilledTableFooter: pendingBilledTableFooter,
  totalPaidTableFooter: totalPaidTableFooter,
  pendingPaidTableFooter: pendingPaidTableFooter,
  plTBA: plTBA,
  plStatus: plStatus,
  plName: plName,
  plBirthdate: plBirthdate,
  plEmail: plEmail,
  plConfirmed: plConfirmed,
  plInvited: plInvited,
  plIncomplete: plIncomplete,
  plUnknown: plUnknown,
  providePassengerDetails: providePassengerDetails,
  providePassengerDetailsDesc: providePassengerDetailsDesc,
  invitePassenger: invitePassenger,
  invitePassengerDesc: invitePassengerDesc,
  selectFromConnectedAccounts: selectFromConnectedAccounts,
  selectFromConnectedAccountsDesc: selectFromConnectedAccountsDesc,
  editDetails: editDetails,
  removeMyself: removeMyself,
  replacePassenger: replacePassenger,
  dropBerth: dropBerth,
  resendInvitation: resendInvitation,
  copyInvitationLink: copyInvitationLink,
  revokeInvitation: revokeInvitation
};

var copied_parseSteps = [];

var copied = {
  desc: "Text when something is copied to clipboard",
  value: "Copied to clipboard!",
  parseSteps: copied_parseSteps
};

var copyError_parseSteps = [];

var copyError = {
  desc: "Error message when clipboard copy fails",
  value: "Error copying to clipboard",
  parseSteps: copyError_parseSteps
};

var Clipboard = {
  copied: copied,
  copyError: copyError
};

var genericFormSubmit_parseSteps = [];

var genericFormSubmit = {
  desc: "Error message when something goes wrong",
  value: "Something went wrong. Please try again.",
  parseSteps: genericFormSubmit_parseSteps
};

var emailInUse_parseSteps = [];

var emailInUse = {
  desc: "Error message when email is already in use",
  value: "This email address is already in use.",
  parseSteps: emailInUse_parseSteps
};

var emailCantBeChanged_parseSteps = [];

var emailCantBeChanged = {
  desc: "Error message when email can't be changed",
  value: "This email address can't be changed. Please contact support.",
  parseSteps: emailCantBeChanged_parseSteps
};

var $$Error = {
  genericFormSubmit: genericFormSubmit,
  emailInUse: emailInUse,
  emailCantBeChanged: emailCantBeChanged
};

exports.Nav = Nav;
exports.Login = Login;
exports.Claim = Claim;
exports.Verify = Verify;
exports.ResetPassword = ResetPassword;
exports.Account = Account;
exports.Bookings = Bookings;
exports.BookingDetails = BookingDetails;
exports.Clipboard = Clipboard;
exports.$$Error = $$Error;
/* Link-Thin Not a pure module */
