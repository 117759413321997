// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


var vars = {
  loginLink: "/login",
  loginRedirectParam: "to",
  signUpLink: "/signup",
  bookingsLink: "/bookings",
  accountLink: "/account",
  expiryKey: "expiry",
  tokenKey: "token",
  dateFormat: "MM/dd/yyyy",
  mailtoEmail: "booking@jococruise.com",
  changeRequestSubject: "Update to personal information",
  currency: "USD",
  locale: "en-US"
};

exports.vars = vars;
/* No side effect */
