// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var IsLoggedIn$Thin = require("./gates/IsLoggedIn.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var StandardLayout$Thin = require("../layouts/StandardLayout.bs.js");
var BookingDetailsPage$Thin = require("../pages/BookingDetailsPage.bs.js");
var BookingDetailsRouteQuery_graphql$Thin = require("../__generated__/BookingDetailsRouteQuery_graphql.bs.js");

var convertVariables = BookingDetailsRouteQuery_graphql$Thin.Internal.convertVariables;

var convertResponse = BookingDetailsRouteQuery_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = BookingDetailsRouteQuery_graphql$Thin.Internal.convertWrapRawResponse;

function use(param, param$1, param$2, param$3, param$4) {
  return RescriptRelay_Query.useQuery(convertVariables, BookingDetailsRouteQuery_graphql$Thin.node, convertResponse, param, param$1, param$2, param$3, param$4);
}

function useLoader(param) {
  return RescriptRelay_Query.useLoader(convertVariables, BookingDetailsRouteQuery_graphql$Thin.node, (function (prim) {
                return prim;
              }), param);
}

function usePreloaded(param) {
  return RescriptRelay_Query.usePreloaded(BookingDetailsRouteQuery_graphql$Thin.node, convertResponse, (function (prim) {
                return prim;
              }), param);
}

function $$fetch(param, param$1, param$2, param$3, param$4, param$5) {
  return RescriptRelay_Query.$$fetch(BookingDetailsRouteQuery_graphql$Thin.node, convertResponse, convertVariables, param, param$1, param$2, param$3, param$4, param$5);
}

function fetchPromised(param, param$1, param$2, param$3, param$4) {
  return RescriptRelay_Query.fetchPromised(BookingDetailsRouteQuery_graphql$Thin.node, convertResponse, convertVariables, param, param$1, param$2, param$3, param$4);
}

function retain(param, param$1) {
  return RescriptRelay_Query.retain(BookingDetailsRouteQuery_graphql$Thin.node, convertVariables, param, param$1);
}

var Query = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function BookingDetailsRoute(props) {
  var data = use({
        bookingId: props.bookingId,
        brandFamily: props.brandFamily
      }, undefined, undefined, undefined, undefined);
  return JsxRuntime.jsx(IsLoggedIn$Thin.make, {
              fragmentRefs: data.fragmentRefs,
              children: JsxRuntime.jsx(StandardLayout$Thin.make, {
                    fragmentRefs: data.fragmentRefs,
                    children: JsxRuntime.jsx(BookingDetailsPage$Thin.make, {
                          fragmentRefs: data.fragmentRefs
                        })
                  })
            });
}

var make = BookingDetailsRoute;

exports.Query = Query;
exports.make = make;
/* IsLoggedIn-Thin Not a pure module */
