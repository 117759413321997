// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Txt$Thin = require("../../../text/Txt.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Stack = require("@mui/joy/Stack").default;
var JsxRuntime = require("react/jsx-runtime");
var Typography = require("@mui/joy/Typography").default;

function BDDepositSchedule(props) {
  return JsxRuntime.jsx(Stack, {
              direction: "column",
              justifyContent: "space-between",
              spacing: 2,
              sx: {
                px: 2,
                py: 3,
                height: {
                  xs: "auto",
                  md: "100%"
                }
              },
              children: Caml_option.some(JsxRuntime.jsx(Stack, {
                        direction: "column",
                        spacing: 2,
                        children: Caml_option.some(JsxRuntime.jsx(Stack, {
                                  children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                            level: "h4",
                                            component: "h4",
                                            children: Caml_option.some(Txt$Thin.BookingDetails.depositScheduleTitle.value)
                                          }))
                                }))
                      }))
            });
}

var make = BDDepositSchedule;

exports.make = make;
/* Txt-Thin Not a pure module */
