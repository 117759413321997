// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Txt$Thin = require("../../../text/Txt.bs.js");
var CustomScalars$Thin = require("../../../utils/CustomScalars.bs.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var ChangeEmailSubmit_ChangeEmailMutation_graphql$Thin = require("../../../__generated__/ChangeEmailSubmit_ChangeEmailMutation_graphql.bs.js");

var convertVariables = ChangeEmailSubmit_ChangeEmailMutation_graphql$Thin.Internal.convertVariables;

var convertResponse = ChangeEmailSubmit_ChangeEmailMutation_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = ChangeEmailSubmit_ChangeEmailMutation_graphql$Thin.Internal.convertWrapRawResponse;

function commitMutation(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8) {
  return RescriptRelay_Mutation.commitMutation(convertVariables, ChangeEmailSubmit_ChangeEmailMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse, param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8);
}

function use(param) {
  return RescriptRelay_Mutation.useMutation(convertVariables, ChangeEmailSubmit_ChangeEmailMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse, param);
}

var ChangeEmailMutation_changeEmailErr_decode = ChangeEmailSubmit_ChangeEmailMutation_graphql$Thin.Utils.changeEmailErr_decode;

var ChangeEmailMutation_changeEmailErr_fromString = ChangeEmailSubmit_ChangeEmailMutation_graphql$Thin.Utils.changeEmailErr_fromString;

var ChangeEmailMutation = {
  changeEmailErr_decode: ChangeEmailMutation_changeEmailErr_decode,
  changeEmailErr_fromString: ChangeEmailMutation_changeEmailErr_fromString,
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation(refresh) {
  var match = use(undefined);
  var mutate = match[0];
  var match$1 = React.useState(function () {
        return "";
      });
  var newEmail = match$1[0];
  var match$2 = React.useState(function () {
        
      });
  var setError = match$2[1];
  var match$3 = React.useState(function () {
        return false;
      });
  var setSuccess = match$3[1];
  var onSubmit = function (e) {
    e.preventDefault();
    var newEmail$1 = CustomScalars$Thin.Email.parse(newEmail);
    Curry._1(setError, (function (param) {
            
          }));
    Curry._8(mutate, {
          input: {
            newEmail: newEmail$1
          }
        }, undefined, undefined, undefined, (function (resp, err) {
            if (err === undefined) {
              var match = resp.changeEmail;
              if (match !== undefined) {
                var err$1 = match.changeEmailErr;
                if (err$1 !== undefined) {
                  var error = err$1 === "EMAIL_ALREADY_IN_USE" ? Txt$Thin.$$Error.emailInUse.value : (
                      err$1 === "EMAIL_CANT_BE_CHANGED_ASK_SUPPORT" ? Txt$Thin.$$Error.emailCantBeChanged.value : Txt$Thin.Account.emailModalError.value
                    );
                  Curry._1(setError, (function (param) {
                          return error;
                        }));
                  return ;
                }
                
              }
              Curry._1(refresh, undefined);
              Curry._1(setSuccess, (function (param) {
                      return true;
                    }));
              return ;
            }
            Curry._1(setError, (function (param) {
                    return Txt$Thin.$$Error.genericFormSubmit.value;
                  }));
          }), (function (param) {
            Curry._1(setError, (function (param) {
                    return Txt$Thin.$$Error.genericFormSubmit.value;
                  }));
          }), undefined, undefined);
  };
  return {
          newEmail: newEmail,
          setNewEmail: match$1[1],
          error: match$2[0],
          setError: setError,
          success: match$3[0],
          onSubmit: onSubmit,
          isMutating: match[1]
        };
}

exports.ChangeEmailMutation = ChangeEmailMutation;
exports.useMutation = useMutation;
/* react Not a pure module */
