// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Txt$Thin = require("../../../text/Txt.bs.js");
var Common$Thin = require("../../../utils/Common.bs.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var RequestTokenSubmit_RequestPasswordResetMutation_graphql$Thin = require("../../../__generated__/RequestTokenSubmit_RequestPasswordResetMutation_graphql.bs.js");

var convertVariables = RequestTokenSubmit_RequestPasswordResetMutation_graphql$Thin.Internal.convertVariables;

var convertResponse = RequestTokenSubmit_RequestPasswordResetMutation_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = RequestTokenSubmit_RequestPasswordResetMutation_graphql$Thin.Internal.convertWrapRawResponse;

function commitMutation(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8) {
  return RescriptRelay_Mutation.commitMutation(convertVariables, RequestTokenSubmit_RequestPasswordResetMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse, param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8);
}

function use(param) {
  return RescriptRelay_Mutation.useMutation(convertVariables, RequestTokenSubmit_RequestPasswordResetMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse, param);
}

var RequestPasswordResetMutation = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation(param) {
  var match = use(undefined);
  var mutate = match[0];
  var match$1 = React.useState(function () {
        return "";
      });
  var email = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setTokenSent = match$2[1];
  var match$3 = React.useState(function () {
        
      });
  var setTokenError = match$3[1];
  var requestToken = function (param) {
    if (email === "" || !Common$Thin.Email.isValid(email)) {
      return ;
    } else {
      Curry._1(setTokenError, (function (param) {
              
            }));
      Curry._8(mutate, {
            input: {
              allegedEmail: email
            }
          }, undefined, undefined, undefined, (function (param, err) {
              if (err === undefined) {
                return Curry._1(setTokenSent, (function (param) {
                              return true;
                            }));
              } else {
                return Curry._1(setTokenError, (function (param) {
                              return Txt$Thin.ResetPassword.resetLinkError.value;
                            }));
              }
            }), (function (err) {
              Curry._1(setTokenError, (function (param) {
                      return Txt$Thin.$$Error.genericFormSubmit.value;
                    }));
            }), undefined, undefined);
      return ;
    }
  };
  var onSubmit = function (e) {
    e.preventDefault();
    requestToken(undefined);
  };
  var onClick = function (e) {
    e.preventDefault();
    requestToken(undefined);
  };
  return {
          isMutating: match[1],
          email: email,
          setEmail: match$1[1],
          onSubmit: onSubmit,
          onClick: onClick,
          tokenSent: match$2[0],
          tokenError: match$3[0]
        };
}

exports.RequestPasswordResetMutation = RequestPasswordResetMutation;
exports.useMutation = useMutation;
/* react Not a pure module */
