// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_List = require("rescript/lib/js/belt_List.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var SysCtx$Thin = require("../../utils/SysCtx.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var IsLoggedIn_query_graphql$Thin = require("../../__generated__/IsLoggedIn_query_graphql.bs.js");

var convertFragment = IsLoggedIn_query_graphql$Thin.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(IsLoggedIn_query_graphql$Thin.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, IsLoggedIn_query_graphql$Thin.node, convertFragment);
}

var QueryFragment_custSignonStatus_decode = IsLoggedIn_query_graphql$Thin.Utils.custSignonStatus_decode;

var QueryFragment_custSignonStatus_fromString = IsLoggedIn_query_graphql$Thin.Utils.custSignonStatus_fromString;

var QueryFragment = {
  custSignonStatus_decode: QueryFragment_custSignonStatus_decode,
  custSignonStatus_fromString: QueryFragment_custSignonStatus_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

function IsLoggedIn(props) {
  var query = RescriptRelay_Fragment.useFragment(IsLoggedIn_query_graphql$Thin.node, convertFragment, props.fragmentRefs);
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var params = url.path;
  var redirect = params ? encodeURI(Belt_List.reduce(params, "" + SysCtx$Thin.vars.loginLink + "?" + SysCtx$Thin.vars.loginRedirectParam + "=", (function (a, b) {
                return a + "/" + b.trim();
              }))) : SysCtx$Thin.vars.loginLink;
  React.useEffect((function () {
          var match = query.currentUserSignonStatus;
          if (match === "PENDING_2FA" || match === "VERIFY_EMAIL" || match === "SIGNED_ON") {
            
          } else {
            RescriptReactRouter.push(redirect);
          }
        }), [query.currentUserSignonStatus]);
  var match = query.currentUserSignonStatus;
  if (match === "PENDING_2FA" || match === "VERIFY_EMAIL" || match === "SIGNED_ON") {
    return props.children;
  } else {
    return null;
  }
}

var make = IsLoggedIn;

exports.QueryFragment = QueryFragment;
exports.make = make;
/* react Not a pure module */
