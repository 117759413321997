// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Txt$Thin = require("../../../text/Txt.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thin = require("../../../utils/Common.bs.js");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var InsecureJWTStorage$Util = require("util/src/InsecureJWTStorage.bs.js");
var LoginSubmit_LoginMutation_graphql$Thin = require("../../../__generated__/LoginSubmit_LoginMutation_graphql.bs.js");

var convertVariables = LoginSubmit_LoginMutation_graphql$Thin.Internal.convertVariables;

var convertResponse = LoginSubmit_LoginMutation_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = LoginSubmit_LoginMutation_graphql$Thin.Internal.convertWrapRawResponse;

function commitMutation(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8) {
  return RescriptRelay_Mutation.commitMutation(convertVariables, LoginSubmit_LoginMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse, param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8);
}

function use(param) {
  return RescriptRelay_Mutation.useMutation(convertVariables, LoginSubmit_LoginMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse, param);
}

var LoginMutation = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation(onBrandFamily, refetch) {
  var match = use(undefined);
  var mutate = match[0];
  var match$1 = React.useState(function () {
        return "";
      });
  var email = match$1[0];
  var match$2 = React.useState(function () {
        return "";
      });
  var password = match$2[0];
  var match$3 = React.useState(function () {
        
      });
  var setError = match$3[1];
  var onSubmit = function (e) {
    e.preventDefault();
    if (email === "" || password === "" || !Common$Thin.Email.isValid(email)) {
      return ;
    } else {
      Curry._1(setError, (function (param) {
              
            }));
      Curry._8(mutate, {
            input: {
              onBrandFamily: onBrandFamily,
              allegedEmail: email,
              allegedPass: password
            }
          }, undefined, undefined, (function (store, param) {
              store.invalidateStore();
            }), (function (resp, err) {
              if (err !== undefined) {
                return Curry._1(setError, (function (param) {
                              return Txt$Thin.$$Error.genericFormSubmit.value;
                            }));
              }
              var match = resp.login;
              if (match !== undefined) {
                var jwt = match.jwt;
                if (jwt !== undefined) {
                  InsecureJWTStorage$Util.set(Caml_option.valFromOption(jwt));
                  Curry._1(refetch, undefined);
                  return ;
                }
                
              }
              Curry._1(setError, (function (param) {
                      return Txt$Thin.Login.noUserError.value;
                    }));
            }), (function (err) {
              Curry._1(setError, (function (param) {
                      return Txt$Thin.$$Error.genericFormSubmit.value;
                    }));
            }), undefined, undefined);
      return ;
    }
  };
  return {
          isMutating: match[1],
          email: email,
          setEmail: match$1[1],
          password: password,
          setPassword: match$2[1],
          onSubmit: onSubmit,
          error: match$3[0]
        };
}

exports.LoginMutation = LoginMutation;
exports.useMutation = useMutation;
/* react Not a pure module */
