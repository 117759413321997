// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var RescriptRelay_Mutation = require("rescript-relay/src/RescriptRelay_Mutation.bs.js");
var AddBerthSubmit_AddBerthMutation_graphql$Thin = require("../../../__generated__/AddBerthSubmit_AddBerthMutation_graphql.bs.js");

var convertVariables = AddBerthSubmit_AddBerthMutation_graphql$Thin.Internal.convertVariables;

var convertResponse = AddBerthSubmit_AddBerthMutation_graphql$Thin.Internal.convertResponse;

var convertWrapRawResponse = AddBerthSubmit_AddBerthMutation_graphql$Thin.Internal.convertWrapRawResponse;

function commitMutation(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8) {
  return RescriptRelay_Mutation.commitMutation(convertVariables, AddBerthSubmit_AddBerthMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse, param, param$1, param$2, param$3, param$4, param$5, param$6, param$7, param$8);
}

function use(param) {
  return RescriptRelay_Mutation.useMutation(convertVariables, AddBerthSubmit_AddBerthMutation_graphql$Thin.node, convertResponse, convertWrapRawResponse, param);
}

var AddBerthMutation = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  commitMutation: commitMutation,
  use: use
};

function useMutation(toBookingId, atActiveBookingVersionNumber, onClose, refetch) {
  var match = use(undefined);
  var mutate = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setError = match$1[1];
  var onSubmit = function (e) {
    e.preventDefault();
    Curry._1(setError, (function (param) {
            
          }));
    Curry._8(mutate, {
          input: {
            toBookingId: toBookingId,
            atActiveBookingVersionNumber: atActiveBookingVersionNumber
          }
        }, undefined, undefined, undefined, (function (res, err) {
            if (err !== undefined) {
              console.log(err);
              return Curry._1(setError, (function (param) {
                            return "An error occurred adding a berth to your booking";
                          }));
            }
            var s = res.addBerth.string;
            if (s !== undefined) {
              return Curry._1(setError, (function (param) {
                            return s;
                          }));
            } else {
              Curry._1(onClose, undefined);
              Curry._1(refetch, undefined);
              return ;
            }
          }), (function (err) {
            console.log(err);
            Curry._1(setError, (function (param) {
                    return "An error occurred adding a berth to your booking";
                  }));
          }), undefined, undefined);
  };
  return {
          isMutating: match[1],
          error: match$1[0],
          onSubmit: onSubmit
        };
}

exports.AddBerthMutation = AddBerthMutation;
exports.useMutation = useMutation;
/* react Not a pure module */
