// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Txt$Thin = require("../text/Txt.bs.js");
var Link$Thin = require("../components/common/atoms/Link.bs.js");
var Alert$Thin = require("../components/common/atoms/Alert.bs.js");
var Merge$Util = require("util/src/Merge.bs.js");
var Button$Thin = require("../components/common/atoms/Button.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Common$Thin = require("../utils/Common.bs.js");
var SysCtx$Thin = require("../utils/SysCtx.bs.js");
var Box = require("@mui/joy/Box").default;
var JoCoLoGo$Thin = require("../assets/JoCoLoGo.bs.js");
var Stack = require("@mui/joy/Stack").default;
var AuthPanel$Thin = require("../components/common/molecules/AuthPanel.bs.js");
var PageFrame$Thin = require("../components/wrappers/PageFrame.bs.js");
var TextInput$Thin = require("../components/common/molecules/TextInput.bs.js");
var LoginSubmit$Thin = require("../components/login/mutations/LoginSubmit.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var PasswordInput$Thin = require("../components/common/molecules/PasswordInput.bs.js");
var Typography = require("@mui/joy/Typography").default;
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var LoginPageQuery_graphql$Thin = require("../__generated__/LoginPageQuery_graphql.bs.js");
var LoginPage_query_graphql$Thin = require("../__generated__/LoginPage_query_graphql.bs.js");

var convertFragment = LoginPage_query_graphql$Thin.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(LoginPage_query_graphql$Thin.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, LoginPage_query_graphql$Thin.node, convertFragment);
}

var makeRefetchVariables = LoginPageQuery_graphql$Thin.Types.makeRefetchVariables;

var convertRefetchVariables = LoginPageQuery_graphql$Thin.Internal.convertVariables;

function useRefetchable(fRef) {
  return RescriptRelay_Fragment.useRefetchableFragment(LoginPage_query_graphql$Thin.node, convertFragment, convertRefetchVariables, fRef);
}

var QueryFragment_custSignonStatus_decode = LoginPage_query_graphql$Thin.Utils.custSignonStatus_decode;

var QueryFragment_custSignonStatus_fromString = LoginPage_query_graphql$Thin.Utils.custSignonStatus_fromString;

var QueryFragment = {
  custSignonStatus_decode: QueryFragment_custSignonStatus_decode,
  custSignonStatus_fromString: QueryFragment_custSignonStatus_fromString,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  makeRefetchVariables: makeRefetchVariables,
  convertRefetchVariables: convertRefetchVariables,
  useRefetchable: useRefetchable
};

function LoginPage(props) {
  var match = useRefetchable(props.fragmentRefs);
  var refetch = match[1];
  var query = match[0];
  var refetch$1 = function (param) {
    return Curry._4(refetch, Curry._1(makeRefetchVariables, undefined), /* StoreAndNetwork */2, undefined, undefined);
  };
  var match$1 = LoginSubmit$Thin.useMutation(props.brandFamily, refetch$1);
  var error = match$1.error;
  var setPassword = match$1.setPassword;
  var password = match$1.password;
  var setEmail = match$1.setEmail;
  var email = match$1.email;
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var searchParams = new URLSearchParams(url.search);
  var val = searchParams.get(SysCtx$Thin.vars.loginRedirectParam);
  var redirect = !(val == null) && val !== SysCtx$Thin.vars.loginLink ? val : SysCtx$Thin.vars.bookingsLink;
  React.useEffect((function () {
          var match = query.currentUserSignonStatus;
          if (match === "SIGNED_ON") {
            RescriptReactRouter.push(redirect);
          }
          
        }), [query.currentUserSignonStatus]);
  return JsxRuntime.jsxs(PageFrame$Thin.make, {
              children: [
                JsxRuntime.jsx(Box, {
                      children: Caml_option.some(JsxRuntime.jsx(JoCoLoGo$Thin.make, {})),
                      sx: {
                        width: "10rem",
                        mb: "1rem"
                      }
                    }),
                JsxRuntime.jsx(AuthPanel$Thin.make, {
                      heading: Txt$Thin.Login.heading.value,
                      subHeadComponent: Caml_option.some(JsxRuntime.jsx(Typography, {
                                level: "body-sm",
                                textColor: "text.tertiary",
                                children: Caml_option.some(Merge$Util.render(Txt$Thin.Login.subHeading, SysCtx$Thin.vars))
                              })),
                      children: JsxRuntime.jsxs(Stack, {
                            direction: "column",
                            component: "form",
                            spacing: 2,
                            sx: {
                              width: "100%"
                            },
                            onSubmit: match$1.onSubmit,
                            disabled: email === "" || password === "" || !Common$Thin.Email.isValid(email),
                            children: [
                              JsxRuntime.jsx(TextInput$Thin.make, {
                                    type_: "email",
                                    fullWidth: true,
                                    size: "lg",
                                    placeholder: Txt$Thin.Login.emailPlaceholder.value,
                                    error: !Common$Thin.Email.isValid(email) && email !== "",
                                    value: email,
                                    onChange: (function (e) {
                                        var val = e.currentTarget.value;
                                        Curry._1(setEmail, (function (param) {
                                                return val;
                                              }));
                                      }),
                                    autoComplete: "on"
                                  }),
                              JsxRuntime.jsx(PasswordInput$Thin.make, {
                                    fullWidth: true,
                                    size: "lg",
                                    placeholder: Txt$Thin.Login.passwordPlaceholder.value,
                                    value: password,
                                    onChange: (function (e) {
                                        var val = e.currentTarget.value;
                                        Curry._1(setPassword, (function (param) {
                                                return val;
                                              }));
                                      }),
                                    autoComplete: "current-password"
                                  }),
                              JsxRuntime.jsx(Box, {
                                    children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                              level: "body-sm",
                                              children: Caml_option.some(JsxRuntime.jsx(Link$Thin.make, {
                                                        to: "/reset-password",
                                                        children: Txt$Thin.Login.forgotPassword.value
                                                      }))
                                            })),
                                    sx: {
                                      alignSelf: "flex-start",
                                      paddingTop: 0
                                    }
                                  }),
                              JsxRuntime.jsx(Button$Thin.make, {
                                    fullWidth: true,
                                    loading: match$1.isMutating,
                                    type_: "submit",
                                    size: "lg",
                                    disabled: email === "" || password === "" || !Common$Thin.Email.isValid(email),
                                    children: Caml_option.some(Txt$Thin.Login.login.value)
                                  }),
                              error !== undefined ? JsxRuntime.jsx(Alert$Thin.make, {
                                      message: error,
                                      type_: "error"
                                    }) : JsxRuntime.jsx(JsxRuntime.Fragment, {})
                            ]
                          })
                    })
              ],
              auth: true
            });
}

var make = LoginPage;

exports.QueryFragment = QueryFragment;
exports.make = make;
/* react Not a pure module */
