// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Txt$Thin = require("../../text/Txt.bs.js");
var Date$Util = require("util/src/Date.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Button$Thin = require("../common/atoms/Button.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var SysCtx$Thin = require("../../utils/SysCtx.bs.js");
var Chip = require("@mui/joy/Chip").default;
var Sheet = require("@mui/joy/Sheet").default;
var Stack = require("@mui/joy/Stack").default;
var Divider = require("@mui/joy/Divider").default;
var JsxRuntime = require("react/jsx-runtime");
var CustomScalars$Thin = require("../../utils/CustomScalars.bs.js");
var Typography = require("@mui/joy/Typography").default;
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");
var AspectRatio = require("@mui/joy/AspectRatio").default;
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var OpenInNew = require("@mui/icons-material/OpenInNew").default;
var Person2Outlined = require("@mui/icons-material/Person2Outlined").default;
var UpcomingBookingsSection_query_graphql$Thin = require("../../__generated__/UpcomingBookingsSection_query_graphql.bs.js");
var UpcomingBookingsSection_listitem_graphql$Thin = require("../../__generated__/UpcomingBookingsSection_listitem_graphql.bs.js");

var convertFragment = UpcomingBookingsSection_query_graphql$Thin.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(UpcomingBookingsSection_query_graphql$Thin.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, UpcomingBookingsSection_query_graphql$Thin.node, convertFragment);
}

var QueryFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

var convertFragment$1 = UpcomingBookingsSection_listitem_graphql$Thin.Internal.convertFragment;

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(UpcomingBookingsSection_listitem_graphql$Thin.node, convertFragment$1, fRef);
}

function useOpt$1(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, UpcomingBookingsSection_listitem_graphql$Thin.node, convertFragment$1);
}

var ListitemFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$1,
  use: use$1,
  useOpt: useOpt$1
};

function UpcomingBookingsSection$FeaturedVoyage(props) {
  var query = RescriptRelay_Fragment.useFragment(UpcomingBookingsSection_listitem_graphql$Thin.node, convertFragment$1, props.fragmentRefs);
  var voyageSlug = CustomScalars$Thin.VoyageSlug.serialize(query.voyageSlug);
  var bookingId = query.bookingId;
  var daysRemaining = Date$Util.daysUntil(query.voyStartDate);
  return JsxRuntime.jsx(Sheet, {
              onClick: (function (param) {
                  RescriptReactRouter.push(SysCtx$Thin.vars.bookingsLink + "/" + bookingId);
                }),
              sx: {
                zIndex: 1,
                backgroundColor: "background.surface",
                alignSelf: {
                  xs: "center",
                  md: "flex-start"
                },
                borderRadius: "md",
                border: 1,
                borderColor: "neutral.200",
                p: {
                  xs: 2,
                  md: 3
                },
                width: "100%",
                boxSizing: "border-box",
                "&:hover": {
                  boxShadow: bookingId !== undefined ? "md" : "sm"
                },
                cursor: bookingId !== undefined ? "pointer" : "default"
              },
              children: Caml_option.some(JsxRuntime.jsxs(Stack, {
                        direction: {
                          xs: "column",
                          md: "row"
                        },
                        spacing: {
                          xs: 2,
                          md: 3
                        },
                        sx: {
                          width: "100%"
                        },
                        children: [
                          JsxRuntime.jsxs(AspectRatio, {
                                children: [
                                  JsxRuntime.jsx("img", {
                                        draggable: false,
                                        style: {
                                          height: "100%",
                                          width: "100%"
                                        },
                                        alt: voyageSlug,
                                        loading: "lazy",
                                        src: "https://i.ibb.co/6WW5vVC/shiptest.jpg"
                                      }),
                                  JsxRuntime.jsx(Stack, {
                                        justifyContent: "center",
                                        alignItems: "center",
                                        sx: {
                                          position: "absolute",
                                          top: 0,
                                          left: 0,
                                          width: "100%",
                                          height: "100%",
                                          zIndex: 1,
                                          backgroundColor: "rgba(0,0,0,0.4)"
                                        },
                                        children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                  level: "h2",
                                                  component: "h2",
                                                  sx: {
                                                    color: "common.white",
                                                    fontWeight: "bold",
                                                    zIndex: 2
                                                  },
                                                  children: Caml_option.some(voyageSlug)
                                                }))
                                      }),
                                  JsxRuntime.jsx(Chip, {
                                        children: Caml_option.some("" + String(daysRemaining) + " day" + (
                                              daysRemaining === 1 ? "" : "s"
                                            ) + " remaining"),
                                        size: "sm",
                                        sx: {
                                          position: "absolute",
                                          top: {
                                            xs: "10px",
                                            md: "16px"
                                          },
                                          left: {
                                            xs: "10px",
                                            md: "16px"
                                          },
                                          borderRadius: "xs",
                                          zIndex: 5
                                        }
                                      })
                                ],
                                ratio: "1.5",
                                sx: {
                                  borderRadius: "sm",
                                  width: {
                                    xs: "100%",
                                    md: "22rem"
                                  },
                                  height: "100%",
                                  position: "relative"
                                }
                              }),
                          JsxRuntime.jsxs(Stack, {
                                spacing: {
                                  md: 3
                                },
                                sx: {
                                  display: "flex",
                                  flex: 1,
                                  flexDirection: {
                                    xs: "column-reverse",
                                    md: "column"
                                  }
                                },
                                children: [
                                  JsxRuntime.jsxs(Stack, {
                                        direction: "column",
                                        spacing: 3,
                                        children: [
                                          JsxRuntime.jsxs("div", {
                                                children: [
                                                  JsxRuntime.jsx(Typography, {
                                                        level: "body-md",
                                                        textColor: "text.tertiary",
                                                        children: Caml_option.some(Date$Util.prettyFormatDateRange(query.voyStartDate, query.voyEndDate))
                                                      }),
                                                  JsxRuntime.jsx(Typography, {
                                                        level: "h3",
                                                        component: "h2",
                                                        children: Caml_option.some(bookingId)
                                                      })
                                                ]
                                              }),
                                          JsxRuntime.jsx(Stack, {
                                                direction: {
                                                  xs: "column",
                                                  md: "row"
                                                },
                                                spacing: 1,
                                                children: Caml_option.some(JsxRuntime.jsx(Chip, {
                                                          children: Caml_option.some(JsxRuntime.jsx(Typography, {
                                                                    level: "body-xs",
                                                                    fontWeight: "sm",
                                                                    children: Caml_option.some("" + String(query.numBerths) + " Guest" + (
                                                                          query.numBerths === 1 ? "" : "s"
                                                                        ) + "")
                                                                  })),
                                                          size: "md",
                                                          startDecorator: Caml_option.some(JsxRuntime.jsx(Person2Outlined, {})),
                                                          sx: {
                                                            backgroundColor: "neutral.100",
                                                            color: "text.primary",
                                                            borderRadius: "xs"
                                                          }
                                                        }))
                                              })
                                        ]
                                      }),
                                  JsxRuntime.jsxs(Stack, {
                                        direction: "column",
                                        spacing: 2,
                                        sx: {
                                          width: "100%",
                                          display: {
                                            xs: "none",
                                            md: "flex"
                                          }
                                        },
                                        children: [
                                          JsxRuntime.jsx(Divider, {}),
                                          JsxRuntime.jsx(Stack, {
                                                direction: "row",
                                                sx: {
                                                  width: "100%",
                                                  flexWrap: "wrap",
                                                  zIndex: 5
                                                },
                                                children: Caml_option.some(Belt_Array.mapWithIndex([
                                                          [
                                                            "Boarding Guide",
                                                            "/"
                                                          ],
                                                          [
                                                            "Shore Excursions",
                                                            "/"
                                                          ],
                                                          [
                                                            "Route Map",
                                                            "/"
                                                          ]
                                                        ], (function (i, param) {
                                                            return JsxRuntime.jsx(Button$Thin.make, {
                                                                        onClick: (function (e) {
                                                                            e.stopPropagation();
                                                                          }),
                                                                        endDecorator: Caml_option.some(JsxRuntime.jsx(OpenInNew, {})),
                                                                        component: "a",
                                                                        variant: "plain",
                                                                        color: "neutral",
                                                                        href: param[1],
                                                                        children: Caml_option.some(param[0])
                                                                      }, String(i));
                                                          })))
                                              })
                                        ]
                                      })
                                ]
                              })
                        ]
                      }))
            });
}

var FeaturedVoyage = {
  make: UpcomingBookingsSection$FeaturedVoyage
};

function UpcomingBookingsSection(props) {
  var query = RescriptRelay_Fragment.useFragment(UpcomingBookingsSection_query_graphql$Thin.node, convertFragment, props.fragmentRefs);
  var match = query.upcoming;
  return JsxRuntime.jsx(JsxRuntime.Fragment, {
              children: Caml_option.some(match !== undefined ? JsxRuntime.jsxs(Stack, {
                          spacing: 1,
                          sx: {
                            width: "100%"
                          },
                          children: [
                            JsxRuntime.jsx(Typography, {
                                  level: "h3",
                                  component: "h1",
                                  children: Caml_option.some(Txt$Thin.Bookings.upcomingHeading.value)
                                }),
                            Belt_Array.map(match.nodes, (function (node) {
                                    return JsxRuntime.jsx(UpcomingBookingsSection$FeaturedVoyage, {
                                                fragmentRefs: node.fragmentRefs
                                              }, node.bookingId);
                                  }))
                          ]
                        }) : JsxRuntime.jsx(Typography, {
                          level: "h3",
                          component: "h1",
                          children: Caml_option.some(Txt$Thin.Bookings.noUpcomingText.value)
                        }))
            });
}

var make = UpcomingBookingsSection;

exports.QueryFragment = QueryFragment;
exports.ListitemFragment = ListitemFragment;
exports.FeaturedVoyage = FeaturedVoyage;
exports.make = make;
/* Txt-Thin Not a pure module */
